import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SearchFunction} from '../../../components/better-select/better-select.component';
import {of} from 'rxjs';
import {ViewItem} from '../../../view-item';
import {BoardCardLayoutCell} from '../boardCard';

@Component({
    selector: 'app-cell-editor',
    templateUrl: './cell-editor.component.html'
})
export class CellEditorComponent implements OnChanges {
    @Input() cell: BoardCardLayoutCell;
    cellValuePickFormGroup: FormGroup = new FormGroup({
        cellValue1: new FormControl({id: '', text: ''}),
        cellValue2: new FormControl({id: '', text: ''}),
        cellValue3: new FormControl({id: '', text: ''})
    });
    @Input() values: ViewItem[];

    cellValuePick: SearchFunction;

    constructor() {
        this.cellValuePick = this.cellValuePickFoo.bind(this);
    }
    resetCell(cell: string) {
        this.cellValuePickFormGroup.get(cell).setValue({id: '', text: ''});
    }
    cellValuePickFoo: SearchFunction = (term) => {
        const filtered = this.values.filter(item => item.text.toLowerCase().includes(term.toLowerCase()));
        const data = {
            results: filtered,
            pagination: {more: false}
        };
        return of(data);
    }

    manageCellValue1() {
        this.cell.value.value1 = this.cellValuePickFormGroup.get('cellValue1').value;
    }

    manageCellValue2() {
        this.cell.value.value2 = this.cellValuePickFormGroup.get('cellValue2').value;
    }

    manageCellValue3() {
        this.cell.value.value3 = this.cellValuePickFormGroup.get('cellValue3').value;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.cellValuePickFormGroup.get('cellValue1').setValue(this.cell.value.value1);
        this.cellValuePickFormGroup.get('cellValue2').setValue(this.cell.value.value2);
        this.cellValuePickFormGroup.get('cellValue3').setValue(this.cell.value.value3);
    }

}
