import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-share-form-header',
    templateUrl: './share-form-header.component.html',
    styleUrls: ['./share-form-header.component.css']
})
export class ShareFormHeaderComponent {
    @Input() url: string;
    copied = false;

    copyToClipboard(value): void {
        const textarea = document.createElement('textarea');
        textarea.value = value;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        this.copied = true;
        setTimeout(() => {
            this.copied = false;
        }, 2000);
    }
}
