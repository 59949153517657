import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProjectSchemaViewService {
    private toggleHeightSubject = new Subject<boolean>();
    toggleFullHeight = this.toggleHeightSubject.asObservable();

    emitChange(toggle: boolean) {
        this.toggleHeightSubject.next(toggle);
    }

    constructor() {
    }
}
