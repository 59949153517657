import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ConfigEnabled} from '../config-enabled';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-data-defaults',
    templateUrl: './data-defaults.component.html',
    styleUrls: ['./data-defaults.component.css']
})
export class DataDefaultsComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() enabled: ConfigEnabled;
    features = window.getToken().features;

    publicShareLink = [];
    comments = [];
    attachments = [];
    linkedIssue = [];
    subtask = [];
    statusTransition = [];
    priorityEdit = [];
    worklog = [];
    changelog = [];
    summaryEdit = [];
    descriptionEdit = [];
    internalComments = [];
    publicAttachments = [];
    expiration = [];
    password = [];
    selectedUsers = [];
    webLinks = [];

    ngOnInit(): void {
        const opt = this.enabled.dataOptions;

        this.publicShareLink = [
            ['off', 'Empty', null, opt.publicShareLinkEnabled.off],
            ['enable', 'Selected', 'All newly created shares will have \'Use public link\' option selected',
                opt.publicShareLinkEnabled.enable]
        ];

        this.comments = [
            ['off', 'Empty', null, opt.comments.off],
            ['show', 'View', 'All newly created shares will have \'View comments\' option selected', opt.comments.show],
            ['add', 'View & Add', 'All newly created shares will have \'View\' & \'Add comments\' options selected',
                opt.comments.add]
        ];

        this.attachments = [
            ['off', 'Empty', null, opt.attachments.off],
            ['show', 'View', 'All newly created shares will have \'View attachments\' option selected', opt.attachments.show],
            ['add', 'View & Add', 'All newly created shares will have \'View\' & \'Add attachments\' options selected',
                opt.attachments.add]
        ];

        this.linkedIssue = [
            ['off', 'Empty', null, opt.linkedIssue.off],
            ['list', 'List',
                'All newly created shares will have \'List linked issues\' option selected', opt.linkedIssue.show],
            ['share', 'List & Share',
                'All newly created shares will have \'List\' & \'Share linked issues content\' options selected',
                opt.linkedIssue.add]
        ];

        this.subtask = [
            ['off', 'Empty', null, opt.subtask.off],
            ['list', 'List',
                'All newly created shares will have \'List subtasks\' option selected', opt.subtask.show],
            ['share', 'List & Share',
                'All newly created shares will have \'List\' & \'Share subtasks content\' options selected',
                opt.subtask.add]
        ];

        this.statusTransition = [
            ['off', 'Empty', null, opt.statusTransition.off],
            ['on', 'Selected', 'All newly created shares will have \'Enable issue transition\' option selected', opt.statusTransition.on]
        ];

        this.priorityEdit = [
            ['off', 'Empty', null, opt.priorityEdit.off],
            ['on', 'Selected', 'All newly created shares will have \'Allow priority change\' option selected', opt.priorityEdit.on]
        ];

        this.worklog = [
            ['off', 'Empty', null, opt.worklog.off],
            ['on', 'Selected', 'All newly created shares will have \'Show worklog\' option selected',
                opt.worklog.on]];

        this.webLinks = [
            ['off', 'Empty', null, opt.webLinks.off],
            ['on', 'Selected', 'All newly created shares will have \'Show web links\' option selected',
                opt.webLinks.on]];

        this.changelog = [
            ['off', 'Empty', null, opt.changelog.off],
            ['on', 'Selected', 'All newly created shares will have \'View changelog\' option selected',
                opt.changelog.on]];

        this.summaryEdit = [
            ['off', 'Empty', null, opt.summaryEdit.off],
            ['on', 'Selected', 'All newly created shares will have \'Edit summary\' option selected', opt.summaryEdit.on]
        ];

        this.descriptionEdit = [
            ['off', 'Empty', null, opt.descriptionEdit.off],
            ['on', 'Selected', 'All newly created shares will have \'Edit description\' option selected', opt.descriptionEdit.on]
        ];


        this.internalComments = [
            ['off', 'Empty', null, opt.internalComments.off],
            ['show', 'View', 'All newly created shares will have \'View internal comments\' option selected', opt.internalComments.show],
            ['add', 'View & Add', 'All newly created shares will have \'View\' & \'Add internal comments\' options selected',
                opt.internalComments.add]
        ];

        this.publicAttachments = [
            ['off', 'Empty', null, opt.publicAttachments.off],
            ['add', 'View & Add', 'All newly created shares will have \'View\' & \'Add public attachments\' options selected',
                opt.publicAttachments.add]
        ];

        this.expiration = [
            ['off', 'Empty', null, true],
            ['on', 'Selected', 'All newly created shares will have \'Link expiration date and time\' option selected ' +
            'on the \'Security\' tab', true]
        ];

        this.password = [
            ['off', 'Empty', null, true],
            ['on', 'Selected', 'All newly created shares will have \'Protect link with password\' option selected ' +
            'on the \'Security\' tab', true]
        ];

        this.selectedUsers = [
            ['off', 'Empty', null, true],
            ['enable', 'Enable', 'All newly created s hares will have \'Share only with the following users\' option selected ' +
            'on the \'Selected users\' tab', true],
            ['notify', 'Enable & Notify', 'All newly created shares will have \'Share only with the following users\' ' +
            '& \'Notify newly added users\' options selected on the \'Selected users\' tab', true]
        ];
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }
}
