import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-share-content-configuration',
  templateUrl: './share-content-configuration.component.html',
  styleUrls: ['./share-content-configuration.component.css']
})
export class ShareContentConfigurationComponent implements OnInit {
    @Input() form: FormGroup;
  constructor() { }

    showCustomHeader: boolean;
    showCustomFooter: boolean;

  ngOnInit(): void {
      if (this.form.get('showCustomFooter').value) {
          this.showCustomFooter = true;
      }
      if (this.form.get('showCustomHeader').value) {
          this.showCustomHeader = true;
      }
      this.form.valueChanges
          .subscribe(values => {
              this.showCustomFooter = this.form.get('showCustomFooter').value;
              this.showCustomHeader = this.form.get('showCustomHeader').value;
          });
  }

}
