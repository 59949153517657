import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-integrations',
    templateUrl: './integrations.component.html',
    styleUrls: ['./integrations.component.css']
})
export class IntegrationsComponent {
    features = window.getToken().features;

    @Input() form: FormGroup;

    constructor() {
    }
}
