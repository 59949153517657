import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import Utils from '../../../utils/utils';

@Component({
    selector: 'app-share-editor-date-field',
    templateUrl: './share-editor-date-field.component.html',
    styleUrls: ['./share-editor-date-field.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ShareEditorDateFieldComponent),
            multi: true
        }
    ]
})
export class ShareEditorDateFieldComponent implements ControlValueAccessor, OnInit {
    @Input() label: string;

    @Input() info: string;
    @Input() warningMessages: { [key: string]: string };
    @Input() formControlName: string;
    @Input() required = false;
    @Input() disabled = false;
    form: FormGroup;

    value = '';

    warningMessage = '';
    onChange = (value: string) => {};
    onTouched = () => {};

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            selectedDate: ''
        });

        const control = this.getControl();
        if (control) {
            control.statusChanges.subscribe(() => {
                this.updateWarningMessage(control);
            });
            this.updateWarningMessage(control);
        }
    }

    ngOnInit(): void {
        this.form.get('selectedDate').valueChanges.subscribe(value => {
            this.onChange(value);
        });
    }

    writeValue(value: string): void {
        this.form.get('selectedDate').setValue(value, { emitEvent: false });

    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    handleInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        this.value = input.value;
        this.onChange(this.value);
    }

    private getControl(): AbstractControl {
        return this.form.get('selectedDate');
    }

    shouldDisplayWarning(): boolean {
        return !!this.warningMessage;
    }

    private updateWarningMessage(control: AbstractControl): void {
        this.warningMessage = Utils.updateWarnMessage(control, this.warningMessages);
    }
}
