import {Component, Input, forwardRef, Output, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
    selector: 'app-share-editor-checkbox',
    templateUrl: './share-editor-checkbox.component.html',
    styleUrls: ['./share-editor-checkbox.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ShareEditorCheckboxComponent),
            multi: true
        }
    ]
})
export class ShareEditorCheckboxComponent implements ControlValueAccessor {
    @Input() label: string;
    @Input() name: string;
    @Input() info: string;
    @Output() toggle = new EventEmitter<boolean>();

    value = false;
    disabled = false;
    onChange = (value: boolean) => {};
    onTouched = () => {};

    writeValue(value: boolean): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    handleInput(event: Event) {
        const checkbox = event.target as HTMLInputElement;
        this.value = checkbox.checked;
        this.onChange(this.value);
        this.toggle.emit(this.value);
    }
}

