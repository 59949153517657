import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from './app.service';

@Injectable({
    providedIn: 'root'
})
export class GlobalViewAccessGuard implements CanActivate {

    constructor(private app: AppService,
                private router: Router) {
    }

    canActivate(next: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const userIsAdmin = next.queryParams.user_is_admin === 'true';
        if (!userIsAdmin) {
            this.router.navigate(['browse', 'list', 'user'], {queryParams: next.queryParams});
        }
        return true;
    }
}
