import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    Validators
} from '@angular/forms';
import CustomValidators from '../../../../../utils/custom-validators';

export interface FooterLink {
    link: string;
    linkText: string;
    editing?: boolean;
}

@Component({
    selector: 'app-custom-footer-link',
    templateUrl: './custom-footer-link.component.html',
    styleUrls: ['./custom-footer-link.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomFooterLinkComponent),
            multi: true,
        },
    ],
})
export class CustomFooterLinkComponent implements ControlValueAccessor, OnInit {
    footerLinks: FooterLink[] = [];
    linkNameError = false;
    linkUrlError = false;
    footerLinkForm = new FormGroup({
        linkFooter: new FormControl('',
            {validators: [Validators.required, Validators.pattern(CustomValidators.URL_REGEXP)]}),
        linkTextFooter: new FormControl('',
            {validators: [Validators.required, Validators.maxLength(80), Validators.pattern(/^[0-9a-zA-Z.,!? \-]*$/)]})
    });
    isAddLinkButtonEnabled = true;
    @Input() link: FooterLink = {
        link: '',
        linkText: '',
        editing: false
    };
    constructor() {
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return control.invalid && control.touched;
    }

    ngOnInit() {
        this.footerLinkForm.valueChanges.subscribe(() => {
            this.updateAddLinkButton();
        });
        this.updateAddLinkButton();
        if (this.link) {
            this.footerLinkForm.setValue({
                linkFooter: this.link.link,
                linkTextFooter: this.link.linkText,
            });
        }
    }

    private onChange: (value: any) => void = () => {
    }

    private onTouched: () => void = () => {
    }

    writeValue(value: any) {
        if (value && Array.isArray(value)) {
            this.footerLinks = value;
        } else {
            this.footerLinks = [];
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.footerLinkForm.disable();
        } else {
            this.footerLinkForm.enable();
        }
    }

    addFooterLink() {
        if (this.footerLinkForm.valid && !this.linkNameError && !this.linkUrlError) {
            const newFooterLink: FooterLink = {
                link: this.footerLinkForm.get('linkFooter').value,
                linkText: this.footerLinkForm.get('linkTextFooter').value
            };

            this.footerLinks.push(newFooterLink);
            this.updateCustomFooterLink();
            this.footerLinkForm.reset();
            this.updateAddLinkButton();
        }
    }

    editLink(link: FooterLink) {
        const text = this.footerLinkForm.get('linkTextFooter');
        const url = this.footerLinkForm.get('linkFooter');
        if (text?.value && !text.errors) {
            link.linkText = text.value;
        }
        if (url?.value && !url.errors) {
            link.link = url.value;
        }
        link.editing = false;
    }

    cancelEdit(link: FooterLink) {
        link.editing = false;
    }

    updateCustomFooterLink() {
        this.onChange(this.footerLinks);
        this.onTouched();
    }

    updateAddLinkButton() {
        this.isAddLinkButtonEnabled = !this.footerLinkForm.valid || this.linkNameError || this.linkUrlError;
    }
}
