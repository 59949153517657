import {Directive, HostBinding, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';

@Directive({
    selector: '[appGlobalSettingsLink]'
})
export class GlobalSettingsLinkDirective implements OnInit {

    @HostBinding('href') href;

    ngOnInit() {
        this.href = environment.pluginSettingsUrl;
    }
}
