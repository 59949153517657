export enum PeriodEnum {
    HOUR = 'HOUR',
    DAY = 'DAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
}

export interface Period {
    unit: PeriodEnum;
    count: number;
}

export const PERIODS_FOR_SELECT = Object.keys(PeriodEnum).map(i => {
    return {text: capitalize(i), id: i};
});


export function addToDate(date: Date, count: number, period: PeriodEnum): Date {
    if (typeof count === 'string') {
        count = parseInt(count, 10);
    }
    const result = new Date(date.valueOf());

    switch (period) {
        case PeriodEnum.HOUR:
            result.setHours(date.getHours() + count);
            break;
        case PeriodEnum.DAY:
            result.setDate(date.getDate() + count);
            break;
        case PeriodEnum.WEEK:
            result.setDate(date.getDate() + (count * 7));
            break;
        case PeriodEnum.MONTH:
            result.setMonth(date.getMonth() + count);
            break;
        case PeriodEnum.YEAR:
            result.setFullYear(date.getFullYear() + count);
            break;
    }
    return result;
}

export function capitalize(str: string) {
    if (str.length > 0) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    return str;
}
