import {Component, OnInit} from '@angular/core';
import {ApiKeyViewService} from './api-key-view.service';
import {JiraService} from '../jira.service';
import {ApiKeyConfigView} from '../base/apikey.config';
import {CustomData} from './data';

@Component({
    selector: 'app-api-key-form-parent',
    templateUrl: './api-key-form-parent.component.html',
    styleUrls: ['./api-key-form-parent.component.css']
})
export class ApiKeyFormParentComponent implements OnInit {
    activeView: 'configuration' | 'usage' | 'history' | 'configView' = 'history';
    selectedConfig: ApiKeyConfigView | null = null;
    fullHeight = true;
    isNew = false;
    constructor(private jira: JiraService, private akv: ApiKeyViewService) {
    }

    ngOnInit(): void {
        this.jira.getCustomData().subscribe((customData: CustomData) => {
            this.isNew = customData.isNew || false;
            this.setActiveView('configuration');
        });
        this.akv.toggleFullHeight.subscribe(it => {
            this.fullHeight = it;
        });
    }

    setActiveView(view: 'configuration' | 'usage' | 'history' | 'configView', data?: ApiKeyConfigView) {
        this.activeView = view;
        if (data) {
            this.selectedConfig = data;
        }
    }

    handleBack() {
        this.setActiveView('history');
        this.selectedConfig = null;
    }
}
