import {environment} from './environments/environment';

if (environment.production) {

    window.onerror = function onError(event: Event | string, source?: string, lineno?: number, colno?: number, error?: Error) {
        console.log('[JES] window.onerror', ...arguments);

        if (!error) {
            error = {} as any;
        }
        if (!error.stack) {
            error.stack = '';
        }
        if (!error.message) {
            error.message = '';
        }

        const xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/report-error', true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send(JSON.stringify({
            event: event.toString(),
            source,
            lineno,
            colno,
            stack: error.stack.toString(),
            message: error.message.toString(),
            url: window.location.toString()
        }));
        return false;
    };

}

