import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {BoardCardLayoutCell} from '../boardCard';

@Component({
    selector: 'app-card-layout-cell',
    templateUrl: './card-layout-cell.component.html',
    styleUrls: ['./card-layout-cell.component.css']
})
export class CardLayoutCellComponent {
    @Input() cell: BoardCardLayoutCell;
    @Input() currentCell: BoardCardLayoutCell;

}
