import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HeaderLink} from '../custom-header-link/custom-header-link.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-custom-header-link-list',
    templateUrl: './custom-header-link-list.component.html',
    styleUrls: ['./custom-header-link-list.component.css']
})
export class CustomHeaderLinkListComponent {
    @Input() headerLinks: HeaderLink[];
    @Output() linkRemoved = new EventEmitter<number>();

    onRemoveLink(index: number) {
        this.linkRemoved.emit(index);
    }

    onEditLink(link: HeaderLink) {
        link.editing = !link.editing;
    }

    drop(event: CdkDragDrop<HeaderLink[]>): void {
        moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
        );
    }
}
