import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ViewItem} from '../../view-item';
import {SearchFunction} from '../better-select/better-select.component';
import {of} from 'rxjs';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-issue-layout-configuration',
    templateUrl: './issue-layout-configuration.component.html',
    styleUrls : ['issue-layout.configuration.component.css']
})
export class IssueLayoutConfigurationComponent {
    @Input() form: FormGroup;
    @Input() layouts: ViewItem[];
    @Output() resetLayout = new EventEmitter<boolean>();
    cellValuePick: SearchFunction;

    constructor() {
        this.cellValuePick = this.cellValuePickFoo.bind(this);
    }

    reset() {
        this.resetLayout.emit(true);
    }

    cellValuePickFoo: SearchFunction = (term) => {
        const filtered = this.layouts.filter(item => item.text.toLowerCase().includes(term.toLowerCase()));
        const data = {
            results: filtered,
            pagination: {more: false}
        };

        return of(data);
    }

}
