import {Component, HostBinding, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {JiraService} from '../jira.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation.component.html',
    styleUrls: ['./delete-confirmation.component.css'],
})
export class DeleteConfirmationComponent implements OnInit {

    @HostBinding('class') outerClass = 'delete-element';

    view$: Observable<{ title: string, type: string }>;

    constructor(private jira: JiraService) {
    }

    ngOnInit(): void {
        document.body.style.overflow = 'hidden';
        this.view$ =
            this.jira.getCustomData<any>().pipe(
                map(it => {
                    let type: string;
                    if (it.type) {
                        type = it.type;
                    } else {
                        type = 'delete';
                    }
                    return {
                        title: it.title,
                        type
                    };
                })
            );
    }

    delete() {
        this.jira.closeDialog({deleted: true});
    }

    cancel() {
        this.jira.closeDialog({deleted: false});
    }
}
