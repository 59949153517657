import {RecipientsComponent} from './recipients.component';
import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Recipient, RecipientType} from '../recipient';

@Component({
    selector: 'app-recipients-simple',
    templateUrl: './recipients.component.html',
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RecipientsSimpleComponent), multi: true}
    ],
    styleUrls: ['./recipients.component.css']
})
export class RecipientsSimpleComponent extends RecipientsComponent {

    @Input() singleChoice = true;

    transformBeforeOnChange(val: Recipient[]): any {
        return val.map(r => r.email).join(',');
    }

    transformBeforeWriteValue(val: any): Recipient[] {
        return (val as string).split(',').map((e: string) => ({ email: e, type: RecipientType.TO}) as Recipient);
    }
}
