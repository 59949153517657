import {Component} from '@angular/core';

@Component({
    selector: 'app-any-or-array-icon',
    template: '<svg role="presentation" width="24" height="24" focusable="false"' +
        ' viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">' +
        '<g fill-rule="evenodd">' +
        '<path d="M3 6C2.44772 6 2 6.44772 2 7C2 7.55228 2.44772 8 ' +
        '3 8H11C11.5523 8 12 7.55228 12 7C12 6.44772 11.5523 6 11 ' +
        '6H3ZM4 16V12H20V16H4ZM2 12C2 10.8954 2.89543 10 4 10H20C21.1046 ' +
        '10 22 10.8954 22 12V16C22 17.1046 21.1046 18 20 18H4C2.89543 18 ' +
        '2 17.1046 2 16V12Z" fill="currentColor"></path></g></svg>'
})
export class AnyOrArrayIconComponent {
}
