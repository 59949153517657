import {Component, OnInit} from '@angular/core';
import {JiraService} from '../../jira.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {LoaderService} from '../../loader.service';
import {ServerDataSource} from '../../components/app-table.component';
import {ApiKeyConfigView, ApiKeyConfigViewFilterCriteria} from '../../base/apikey.config';
import {merge, Observable, of, zip} from 'rxjs';
import Utils from '../../utils/utils';
import {HttpHeaders} from '@angular/common/http';
import {AppState} from '../../base/item.service';
import {ActivatedRoute} from '@angular/router';
import {AppService} from '../../app.service';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-global-view-api-keys',
    templateUrl: './global-view-api-keys.component.html',
    styleUrls: ['./global-view-api-keys.component.css']
})
export class GlobalViewApiKeysComponent implements OnInit {
    private refreshEventName = 'config-update';

    creatorFilterForm = new FormGroup({
        creatorFilter: new FormControl('All')
    });
    statusFilterForm = new FormGroup({
        statusFilter: new FormControl('Active')
    });

    config$: Observable<AppState>;

    constructor(
        private route: ActivatedRoute,
        private jira: JiraService,
        private loader: LoaderService,
        protected source: ServerDataSource<ApiKeyConfigView, ApiKeyConfigViewFilterCriteria>,
        private app: AppService) {
    }

    users = new Map();
    elements;
    creatorIds: Set<string> = new Set();

    ngOnInit(): void {
        const refresh = this.jira.observeEvent(this.refreshEventName);
        refresh.subscribe(() => {
            this.source.refresh();
        });

        this.config$ = merge(refresh, of(undefined)).pipe(
            switchMap(() => {
                return zip(
                    Utils.require(Utils.combineParams(this.route.parent),
                        'GlobalViewApiKeysComponent', 'ngOnInit', 'Utils.combineParams(this.route.parent)'),
                    Utils.require(this.jira.getAddonProperty('permissions', '{"enabled": false}'),
                        'GlobalViewApiKeysComponent', 'ngOnInit', 'this.jira.getAddonProperty("permissions", \'{"enabled": false}\')')
                );
            }),
            switchMap(([params, permissionSchemeObj]) => {
                return Utils.require(Utils.getAppState(params, permissionSchemeObj.enabled),
                    'GlobalViewApiKeysComponent', 'ngOnInit',
                    `Utils.getAppState(${JSON.stringify(params)}, ${permissionSchemeObj.enabled})`);
            }),
            tap(conf => {
                this.source.reload({
                    endPoint: `/api-key/config`,
                    headers: new HttpHeaders({
                        'X-Operation-Name': 'Fetching schemas',
                    }),
                    filter: {status: this.statusFilterForm.get('statusFilter').value,
                        creator: this.creatorFilterForm.get('creatorFilter').value},
                });
                this.source.get().subscribe(apiKeyConfigViews => {
                    this.elements = apiKeyConfigViews;
                    this.creatorIds = this.source.getCreatorIds();
                });
            })
        );
        const combinedFilters = {
            creator: 'All',
            status: 'Active'
        };
        this.creatorFilterForm.valueChanges.subscribe(it => {
            combinedFilters.creator = it.creatorFilter;
            this.applyFilters(combinedFilters);
        });
        this.statusFilterForm.valueChanges.subscribe(it => {
            combinedFilters.status = it.statusFilter;
            this.applyFilters(combinedFilters);
        });
    }

    applyFilters(filters: any) {
        this.source.updateFilter(filters);
        this.source.refresh();
    }

    createNew() {
        this.jira.showDialog({
                key: 'api-key-edition-popup',
                customData: {
                    isNew: true,
                    editable: true
                }
            }
        );
    }

    edit(el: ApiKeyConfigView) {
        this.jira.showDialog({
                key: 'api-key-edition-popup',
                customData: {
                    id: el.id,
                    isNew: false,
                    editable: true
                }
            }
        );
    }

    view(el: ApiKeyConfigView) {
        this.jira.showDialog({
                key: 'api-key-edition-popup',
                customData: {
                    id: el.id,
                    isNew: false,
                    editable: false
                }
            }
        );
    }

    confirmDeletion(el: ApiKeyConfigView) {
        this.jira.showDialog({
                key: 'confirm-delete',
                customData: {
                    type: 'archive',
                    title: 'Archive selected API Key?',
                }
            }, (result) => {
                if (result.deleted) {
                    this.loader.fullscreen(true);
                    this.app.deleteApiKeyConfig(el.id)
                        .pipe(finalize(() => this.loader.fullscreen(false)))
                        .subscribe(() => {
                            this.jira.emitEvent(this.refreshEventName);
                        });
                }
            }
        );
    }

    getUser(accountId) {
        return this.users.get(accountId);
    }
}
