import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Recipient, RecipientType} from '../recipient';

@Component({
    selector: 'app-recipients',
    templateUrl: './recipients.component.html',
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RecipientsComponent), multi: true}
    ],
    styleUrls: ['./recipients.component.css']
})
export class RecipientsComponent implements ControlValueAccessor, OnInit {

    @Input() disabled: boolean;
    @Input() readOnly: boolean;
    @Input() singleChoice: boolean;
    @Input() errorCouldBeDisplayed: boolean;
    @Input() recipientRequired: boolean;
    @Input() invalid: boolean;
    @Input() maxHeight: number;
    @Input() enableInnerMessage = true;

    @Input() allowedEmails: [];
    @Input() allowedDomains: [];

    recipients: Recipient[] = [];

    focused: RecipientType = RecipientType.TO;

    ccEnabled = false;
    bccEnabled = false;
    isCollapsed = false;

    ngOnInit(): void {
        if (this.disabled) {
            this.setCollapsed(true);
        }
    }

    setCollapsed(val: boolean) {
        this.isCollapsed = val;
    }

    transformBeforeOnChange(val: Recipient[]): any {
        return val;
    }

    transformBeforeWriteValue(val: any): Recipient[] {
        return val;
    }

    customOnChange(val: Recipient[]) {
        this.onChange(this.transformBeforeOnChange(val));
    }

    onChange: any = () => {
    }
    onTouched: any = () => {
    }

    get value() {
        return this.recipients;
    }

    set value(val) {
        this.recipients = val;
        this.customOnChange(val);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
    }

    writeValue(obj: any): void {
        if (obj) {
            this.value = this.transformBeforeWriteValue(obj);
        }
    }

    remove(recipient: Recipient) {
        this.recipients = this.recipients.filter(item => !(item.email === recipient.email && item.type === recipient.type));
        this.customOnChange(this.recipients);
    }

    refresh(recipients: Recipient[]) {
        this.recipients = recipients;
        this.customOnChange(recipients);
    }

    focus(type: RecipientType) {
        this.focused = type;
    }

    isEmpty() {
        return this.recipients.length === 0;
    }

    selected() {
        const result = this.recipients.filter(i => i.type === RecipientType.TO || i.type === RecipientType.CC)
            .map(item => ' ' + item.email);

        const bcc = this.recipients.filter(i => i.type === RecipientType.BCC)
            .map(item => ' ' + item.email);

        if (bcc.length > 0) {
            let index = 0;
            bcc.forEach(i => {
                result.push(index === 0 ? ' Bcc: ' + i : i);
                index++;
            });
        }
        return result;
    }

    isRequiredToRecipientsEmpty(): boolean {
        return this.recipients.filter(item => item.type === RecipientType.TO).length === 0;
    }

    executeExpand() {
        if (!this.disabled) {
            this.ccEnabled = this.recipients.some(item => item.type === RecipientType.CC);
            this.bccEnabled = this.recipients.some(item => item.type === RecipientType.BCC);

            this.setCollapsed(false);
            this.focused = RecipientType.TO;
        }
    }

    isToFocused() {
        return this.isFocused(RecipientType.TO) && !this.disabled;
    }

    isCcFocused() {
        return this.isFocused(RecipientType.CC);
    }

    isBccFocused() {
        return this.isFocused(RecipientType.BCC);
    }

    private isFocused(type: RecipientType) {
        return this.focused === type;
    }

    ccEnabledClicked() {
        this.ccEnabled = true;
        this.focused = RecipientType.CC;
    }

    bccEnabledClicked() {
        this.bccEnabled = true;
        this.focused = RecipientType.BCC;
    }

    type(type: string): RecipientType {
        return RecipientType[type];
    }

    shouldDisplayError(): boolean {
        return this.errorCouldBeDisplayed && this.recipientRequired;
    }
}
