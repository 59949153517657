import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" role="presentation">' +
    '   <g fill="currentColor" fill-rule="evenodd">' +
    '       <path d="M6 14c0-1.105.902-2 2.009-2h7.982c1.11 0 2.009.894 2.009 2.006v4.44c0 3.405-12 3.405-12 0V14z"></path>' +
    '       <circle cx="12" cy="7" r="4"></circle>' +
    '   </g>' +
    '</svg>';

@Directive({
    selector: '[appUserIcon]'
})
export class UserIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
