import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-share-editor-tab-subcategory',
    templateUrl: './share-editor-tab-subcategory.component.html',
    styleUrls: ['./share-editor-tab-subcategory.component.css']
})
export class ShareEditorTabSubcategoryComponent {
    @Input() subtitle: string;
}
