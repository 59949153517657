import {Component, OnInit} from '@angular/core';
import {BoardCardLayoutConfig} from '../boardCard';
import {AppService} from '../../../app.service';
import {JiraService} from '../../../jira.service';
import {map} from 'rxjs/operators';
import {zip} from 'rxjs';

@Component({
    selector: 'app-board-card-layout-list',
    templateUrl: './board-card-layout-list.component.html',
    styleUrls: ['./board-card-layout-list.component.css'],
})
export class BoardCardLayoutListComponent implements OnInit {
    private refreshEventName = 'config-update';
    layoutsList: BoardCardLayoutConfig[];
    defaultLayout: BoardCardLayoutConfig;
    deleteSelected: boolean;
    moreThanOneItemIsSelected: boolean;

    constructor(private app: AppService,
                private jira: JiraService) {
    }

    ngOnInit() {
        zip(this.app.getBoardCardLayoutsList(),
            this.app.getDefaultBoardCardLayout()
            ).pipe(
                map(([layouts, defaultConfig]) => {
                    this.layoutsList = layouts;
                    this.defaultLayout = defaultConfig[0];
        })
        ).subscribe();
    }

    selectAll(on: boolean, elements: any[]) {
        elements.forEach(it => it._delete = on);
        this.layoutsList = elements;
        this.updateDeleteAll(elements);
    }

    updateDeleteAll(elements: any[]) {
        this.moreThanOneItemIsSelected = elements.contains(el => el._delete);
        this.deleteSelected = elements.every(el => el._delete);
    }

    deleteSelectedLayouts(e) {
        const toDelete = this.layoutsList.filter(item => item._delete);
        toDelete.forEach(layout => {
            this.deleteLayout(layout.id);
        });
        this.app.getBoardCardLayoutsList().subscribe(layouts => {
            this.layoutsList = layouts;
        });
        this.moreThanOneItemIsSelected = false;
    }

    deleteLayout(id: string) {
        this.app.deleteBoardCardLayout(id).subscribe(item => {
            this.jira.emitEvent(this.refreshEventName);
            this.app.getBoardCardLayoutsList().subscribe(layouts => {
                this.layoutsList = layouts;
            });
        });
    }

}
