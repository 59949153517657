import {AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {addToDate, Period, PeriodEnum} from '../base/period';
import Utils from './utils';
import {AppService} from '../app.service';
import {Observable, of} from 'rxjs';
import {debounceTime, distinctUntilChanged, first, map, switchMap} from 'rxjs/operators';
import {CustomBoardConfig} from '../share-form/custom-board-config';

export default class CustomValidators {

    static ipSetRegexp = new RegExp('[,;\s]+');
    static ipRegexp = new RegExp('([0-9A-Fa-f]{1,4}:){7}[0-9A-Fa-f]{1,4}|(\\d{1,3}\\.){3}\\d{1,3}');
    static URL_REGEXP = new RegExp(
        '^([a-zA-Z]+:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', // fragment locator
        'i'
    );

    public static minDateNow(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (currVal == null) {
                return null;
            }

            const now = new Date();
            const toValidate = new Date(currVal);

            return now <= toValidate ? null : {
                minDate: {
                    minDate: now,
                    actual: currVal
                }
            };
        };
    }

    public static maxPeriod(max: Period): ValidatorFn {
        max = Object.assign({}, max);
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (currVal == null) {
                return null;
            }

            const {unit: currentUnit, count: currentCount} = currVal as Period;

            const now = new Date();

            const currentDate = addToDate(now, currentCount, currentUnit);
            const maxDate = addToDate(now, max.count, max.unit);

            return currentDate <= maxDate ? null : {
                period: {
                    max: maxDate,
                    actual: currentDate
                }
            };
        };
    }

    public static maxDateFromPeriod(max: { unit: PeriodEnum, count: number }): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (currVal == null) {
                return null;
            }

            let currDate: Date = currVal;
            if (typeof currVal === 'string') {
                currDate = new Date(currVal);
            }

            const now = new Date();
            const actualDate = currDate;
            const maxDate = addToDate(now, max.count, max.unit);

            return actualDate <= maxDate ? null : {
                period: {
                    max: maxDate,
                    actual: actualDate
                }
            };
        };
    }

    public static validateIP(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors => {
            const value = control.value;
            if (Utils.isEmpty(value)) {
                return null;
            }

            return this.areAllIPsCorrect(value) ? null : {ipError: true};
        };
    }

    private static areAllIPsCorrect(ips): boolean {
        const ipStrings = this.ipSetRegexp[Symbol.split](ips);

        let correct = true;

        ipStrings.forEach(ip => {
            const trimmedIp = ip.trim();
            if (trimmedIp !== '0.0.0.0/0' && trimmedIp !== '::/0') {
                if (!this.ipRegexp.test(ip)) {
                    correct = false;
                }
            }
        });
        return correct;
    }

    public static validPeriod(control) {
        let validPeriodCount = Utils.isNumeric(control.value.count);

        if (validPeriodCount) {
            validPeriodCount = parseInt(control.value.count, 0) > 0;
        }

        return !validPeriodCount ? {periodCountRequired: {actual: control.value.count}} : null;
    }

    static integer(control) {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        const value = parseInt(control.value, 10);
        return isNaN(value) ? {integer: {actual: control.value}} : null;
    }

    static unitPositiveNumber(control) {
        if (isEmptyInputValue(control.value)) {
            return null;
        }
        const value = Utils.isEmpty(control.value.count) ? undefined : parseInt(control.value.count, 10);
        return isNaN(value) || value <= 0 ? {unitPositiveNumber: {actual: control.value.count}} : null;
    }

    static invalid(control) {
        return {invalid: true};
    }

    static error(control) {
        return {error: true};
    }

    static email(control) {
        if (Utils.isEmpty(control.value)) {
            return null;
        }
        return Utils.splitEmails(control.value)
            .every(isValidEmail) ? null : {email: {actual: control.value}};
    }

    static emailDomains(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (Utils.isEmpty(control.value)) {
                return null;
            }
            return Utils.splitEmailDomains(control.value)
                .every(isValidEmailDomain) ? null : {emailDomains: {actual: control.value}};
        };
    }

    static customBoardConfigValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const config: CustomBoardConfig = control.value;

            if (!config || typeof config !== 'object') {
                return null;
            }

            if (!config.boardName || config.boardName.trim().length === 0) {
                return { message: 'Board name is required.' };
            }

            if (!config.columnsConfig || config.columnsConfig.length === 0) {
                return { message: 'At least one column configuration is required.' };
            }

            for (const column of config.columnsConfig) {
                if (!column.name || column.name.trim().length === 0) {
                    return { message: 'Column name is required.' };
                }

                if (column.name.length > 30) {
                    return { message: `Column name '${column.name}' exceeds 30 characters.` };
                }

                if (!column.statuses || column.statuses.length === 0) {
                    return { message: 'At least one status is required in each column.' };
                }
            }
            return null;
        };
    }

    public static matchesEmailOrEmailDomain(allowedEmails: [],
                                            allowedDomains: []): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (Utils.isEmpty(currVal)) {
                return null;
            }

            let matches = allowedEmails.some(v => v === currVal);
            if (!matches && currVal.includes('@')) {
                matches = allowedDomains.some(v => v === currVal.split('@')[1]);
            }

            return matches ? null : {
                matchesEmailOrEmailDomain: {
                    actual: currVal
                }
            };
        };
    }

    public static matchesEmailDomain(allowedDomains: []): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const currVal = control.value;
            if (Utils.isEmpty(currVal) || !allowedDomains || Utils.isEmptyArray(allowedDomains)) {
                return null;
            }

            const current: [] = currVal.split(',');
            const matches = current.every(r => allowedDomains.includes(r));

            return matches ? null : {
                matchesEmailDomain: {
                    actual: currVal
                }
            };
        };
    }

    static requiredFileType(types: string[]): ValidatorFn {
        return (control: FormControl) => {
            if (!control.value) {
                return null;
            }
            const file = control.value;
            if (file) {
                const fileType = file.type.toLowerCase();
                if (types.filter(t => t.toLowerCase() === fileType || 'image/' + t.toLowerCase() === fileType).length === 0) {
                    return {
                        wrongFileType: true
                    };
                }
            }
            return null;
        };
    }

    static maximumFileSize(maxSize: number): ValidatorFn {
        return (control: FormControl) => {
            const file = control.value;
            if (file) {
                if (file.size > maxSize) {
                    return {
                        wrongSize: true
                    };
                }
            }
            return null;
        };
    }

    static validateWorkspace(app: AppService): AsyncValidatorFn {
        let lastValue: any;

        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (control.value === lastValue) {
                return of(null);
            }
            lastValue = control.value;

            return control.valueChanges.pipe(
                debounceTime(1000),
                distinctUntilChanged(),
                switchMap(value => Utils.require(app.checkWorkspace(value),
                    'CustomValidators', 'validateWorkspace()', `app.checkWorkspace(${value})`).pipe(
                    map(isValid => isValid ? null : { workspaceErr: true }),
                )),
                first()
            );
        };
    }
}

// tslint:disable-next-line:max-line-length
const emailRegexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

function isValidEmail(email) {
    // tslint:disable-next-line:max-line-length
    return emailRegexp.test(email);
}


function isEmptyInputValue(value) {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
}

function isValidEmailDomain(emailDomain) {
    const regexp = new RegExp(/^([A-Za-z0-9\-]+)\.([A-Za-z0-9\-]{2,})(\.([A-Za-z0-9\-]{2,}))?$/);
    return regexp.test(emailDomain);
}
