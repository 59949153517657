import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="20" height="20" viewBox="0 0 20 24" role="presentation">' +
    '   <g fill="currentColor">' +
    // tslint:disable-next-line:max-line-length
    '       <path fill="none" fill-rule="evenodd" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 0H0l8 9.46V16l4 2V9.46z" transform="translate(1 1)" style="stroke: #5e6c84;"/>' +
    '   </g>' +
    '</svg>';

@Directive({
    selector: '[appJqlFilterIcon]'
})
export class JqlFilterIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
