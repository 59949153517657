import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {AppService} from '../app.service';
import {environment} from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private app: AppService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.server) {
            return next.handle(request);
        }

        if (window.getToken === undefined) {
            return next.handle(request);
        }

        if (request.headers.get('Authorization')) {
            return next.handle(request);
        }

        if (this.checkTokenExpiration(request) === EMPTY) {
            return EMPTY;
        }

        return next.handle(request.clone({
            setHeaders: {
                Authorization: `JWT ${window.getToken().token}`
            }
        }));
    }

    private checkTokenExpiration(ctx?: any) {
        const claims = this.app.claims();
        if (!claims) {
            atlas.log('No claims!', ctx, window.getToken());
            return EMPTY;
        }
        const expireTime = claims.exp * 1000;
        const currentTime = new Date().getTime();

        if (expireTime < currentTime) {
            window.location.replace('/static-web/assets/token-error.html?v=latest');
            return EMPTY;
        }

        return true;
    }
}

