import {DatePipe} from '@angular/common';
import {Pipe, PipeTransform} from '@angular/core';
import {Constants} from '../utils/constants';

@Pipe({
    name: 'niceDate'
})
export class NiceDateTimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return super.transform(value, Constants.DATE_TIME_FMT);
    }
}

@Pipe({
    name: 'shortNiceDate'
})
export class ShortNiceDateTimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return super.transform(value, Constants.SHORT_NICE_DATE_TIME_FMT);
    }
}

export class NiceTimeFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return super.transform(value, Constants.TIME_FMT);
    }
}

export class InputDateFormatPipe extends DatePipe implements PipeTransform {
    transform(value: any, args?: any): any {
        return super.transform(value, Constants.INPUT_DATE_FMT);
    }
}
