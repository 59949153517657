import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {IconsModule} from '../icons/icons.module';
import {GlobalViewSettingsComponent} from './global-view-settings/global-view-settings.component';
import {GlobalViewListComponent} from './global-view-list/global-view-list.component';
import {GlobalViewComponent} from './global-view.component';
import {ProjectSchemaSettingsComponent} from './project-schema-settings/project-schema-settings.component';
import {EmailTemplateComponent} from './global-view-settings/components/email-template/email-template.component';
import {CustomDomainComponent} from './global-view-settings/components/custom-domain/custom-domain.component';
import {GlobalViewApiKeysComponent} from './global-view-api-keys/global-view-api-keys.component';
import {
    PageCustomizationComponent
} from './global-view-settings/components/page-customization/page-customization.component';
import {SamlConfigurationComponent} from './global-view-settings/components/saml-config/saml-configuration.component';
import {AccessComponent} from './global-view-settings/components/access/access.component';
import {CustomEmailComponent} from './global-view-settings/components/custom-email/custom-email.component';
import {
    GlobalPermissionsComponent
} from './global-view-settings/components/global-permissions/global-permissions.component';
import {GlobalActivityComponent} from './global-activity/global-activity.component';
import {IntegrationsComponent} from './global-view-settings/components/integrations/integrations.component';
import {
    SamlManageWorkspaceComponent
} from './global-view-settings/components/saml-manage-workspace/saml-manage-workspace.component';
import {BoardCardLayoutComponent} from './board-card-layout/board-card-layout.component';
import {CardLayoutRowComponent} from './board-card-layout/card-layout-row/card-layout-row.component';
import {CardLayoutCellComponent} from './board-card-layout/card-layout-cell/card-layout-cell.component';
import {RowEditorComponent} from './board-card-layout/row-editor/row-editor.component';
import {CellEditorComponent} from './board-card-layout/cell-editor/cell-editor.component';
import {
    BoardCardLayoutListComponent
} from './board-card-layout/board-card-layout-list/board-card-layout-list.component';
import {ToBoardCardElementComponent} from './board-card-layout/to-board-card-element/to-board-card-element.component';
import {
    BoardCardColorEditorComponent
} from './board-card-layout/board-card-color-editor/board-card-color-editor.component';
import {
    CustomFooterLinkComponent
} from './global-view-settings/components/page-customization/custom-footer-link/custom-footer-link.component';
import {
    CustomFooterLinkListComponent
} from './global-view-settings/components/page-customization/custom-footer-link-list/custom-footer-link-list.component';
import {
    CustomHeaderLinkComponent
} from './global-view-settings/components/page-customization/custom-header-link/custom-header-link.component';
import {
    CustomHeaderLinkListComponent
} from './global-view-settings/components/page-customization/custom-header-link-list/custom-header-link-list.component';
import {IssueLayoutCustomizationComponent} from './issue-layout-customization/issue-layout-customization.component';
import {TextIconComponent} from './issue-layout-customization/field-type-icons/text-icon.component';
import {DateIconComponent} from './issue-layout-customization/field-type-icons/date-icon.component';
import {UserIconComponent} from './issue-layout-customization/field-type-icons/user-icon.component';
import {DatetimeIconComponent} from './issue-layout-customization/field-type-icons/datetime-icon.component';
import {NumberIconComponent} from './issue-layout-customization/field-type-icons/number-icon.component';
import {AnyOrArrayIconComponent} from './issue-layout-customization/field-type-icons/any-or-array-icon.component';
import {PriorityIconComponent} from './issue-layout-customization/field-type-icons/priority-icon.component';
import {SystemFieldIconComponent} from './issue-layout-customization/field-type-icons/system-field-icon.component';
import {OptionIconComponent} from './issue-layout-customization/field-type-icons/option-icon.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ToIconByTypeComponent } from './issue-layout-customization/to-icon-by-type/to-icon-by-type.component';
import {DraggingInstructionSvgComponent} from './issue-layout-customization/field-type-icons/dragging-instruction-svg.component';
import {IssueLayoutListComponent} from './issue-layout-customization/issue-layouts-list/issue-layout-list.component';
import {
    IssueLayoutTypesMappingComponent
} from './issue-layout-customization/issue-layout-types-mapping/issue-layout-types-mapping.component';

@NgModule({
    declarations: [
        CustomDomainComponent,
        GlobalViewSettingsComponent,
        GlobalViewListComponent,
        GlobalViewComponent,
        ProjectSchemaSettingsComponent,
        EmailTemplateComponent,
        GlobalViewApiKeysComponent,
        PageCustomizationComponent,
        SamlConfigurationComponent,
        AccessComponent,
        CustomEmailComponent,
        GlobalPermissionsComponent,
        GlobalActivityComponent,
        SamlManageWorkspaceComponent,
        IntegrationsComponent,
        BoardCardLayoutComponent,
        CardLayoutRowComponent,
        CardLayoutCellComponent,
        RowEditorComponent,
        CellEditorComponent,
        BoardCardLayoutListComponent,
        ToBoardCardElementComponent,
        BoardCardColorEditorComponent,
        CustomFooterLinkComponent,
        CustomFooterLinkListComponent,
        CustomHeaderLinkComponent,
        CustomHeaderLinkListComponent,
        IssueLayoutCustomizationComponent,
        TextIconComponent,
        DateIconComponent,
        UserIconComponent,
        DatetimeIconComponent,
        NumberIconComponent,
        AnyOrArrayIconComponent,
        PriorityIconComponent,
        SystemFieldIconComponent,
        OptionIconComponent,
        ToIconByTypeComponent,
        DraggingInstructionSvgComponent,
        IssueLayoutListComponent,
        IssueLayoutTypesMappingComponent
    ],
    imports: [
        CommonModule,
        BaseModule,
        BrowserModule,
        FormsModule,
        IconsModule,
        ReactiveFormsModule,
        RouterModule,
        DragDropModule
    ],
    exports: [
        CustomDomainComponent,
        CustomFooterLinkComponent,
        CustomHeaderLinkComponent
    ]
})
export class GlobalViewModule {
}
