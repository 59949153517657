import {Component, Input, QueryList, ViewChildren} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import Utils from '../../../../utils/utils';
import {FooterLink} from './custom-footer-link/custom-footer-link.component';
import {ImageUploadComponent} from '../../../../components/image-upload/image-upload.component';
import {HeaderLink} from './custom-header-link/custom-header-link.component';

@Component({
    selector: 'app-page-customization',
    templateUrl: './page-customization.component.html',
    styleUrls: ['./page-customization.component.css']
})
export class PageCustomizationComponent {
    @ViewChildren(ImageUploadComponent) imageUploadComponents: QueryList<ImageUploadComponent>;
    features = window.getToken().features;
    @Input() form: FormGroup;

    get footerLinks(): FooterLink[] {
        return this.form.get('customFooterLinks').value;
    }

    get headerLinks(): HeaderLink[] {
        return this.form.get('customHeaderLinks').value;
    }

    constructor() {
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    removeFooterLink(index: number): void {
        const links = this.footerLinks;
        links.splice(index, 1);
        this.form.get('customFooterLinks').setValue(links);
    }

    removeHeaderLink(index: number): void {
        const links = this.headerLinks;
        links.splice(index, 1);
        this.form.get('customHeaderLinks').setValue(links);
    }
    resetImageUrlInput(newValue: object) {
        this.form.patchValue(newValue);
    }
    resetImageUploadInput(newValue: object, formControlName: string) {
        this.form.patchValue(newValue);
        this.resetImageUploadComponentInput(formControlName);
    }
    private resetImageUploadComponentInput(formControlName: string) {
        const imageUploadComponent = this.imageUploadComponents.find(
            (uploadComponent: ImageUploadComponent) => uploadComponent.formControlName === formControlName
        );
        imageUploadComponent?.resetInput();
    }
}
