import {
    ChangeDetectionStrategy,
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import {PeriodEnum, PERIODS_FOR_SELECT} from '../../base/period';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-period',
    templateUrl: './period.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./period.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PeriodComponent),
            multi: true
        },
    ]
})
export class PeriodComponent implements OnInit, ControlValueAccessor, OnChanges {
    periods = PERIODS_FOR_SELECT;
    disabled = false;
    @Input() disabledControl: boolean;
    form = new FormGroup({
        unit: new FormControl(PeriodEnum.DAY),
        count: new FormControl(1)
    });

    constructor() {
    }

    /**
     * @description
     * The registered callback function called when a change event occurs on the input element.
     */
    onChange = (_: any) => {
    }

    /**
     * @description
     * The registered callback function called when a blur event occurs on the input element.
     */
    onTouched = (event) => {
    }

    ngOnInit() {
        this.form.valueChanges.subscribe(it => {
            this.onChange(it);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.setDisabledState(changes.disabledControl.currentValue);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        this.form.patchValue(obj, {emitEvent: false});
    }

    setDisabledState(isDisabled: boolean) {
        this.disabled = isDisabled;
        if (isDisabled) {
            this.form.disable({onlySelf: false, emitEvent: false});
        } else {
            this.form.enable({onlySelf: false, emitEvent: false});
        }
    }
}
