import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import Utils from '../../../../utils/utils';

@Component({
    selector: 'app-saml-configuration',
    templateUrl: './saml-configuration.component.html',
    styleUrls: ['./saml-configuration.component.css']
})
export class SamlConfigurationComponent implements OnInit {
    static identityProviders = {
        AZURE: {
            relayState: {
                label: 'Default RelayState',
                tooltip: 'Set this Relay State as default for Microsoft Azure\'s initiated SSO'
            },
            identifier: {
                label: 'Identifier (Entity ID)',
                tooltip: 'Microsoft Azure\'s Instance Identifier'
            },
            issuer: {
                label: 'Issuer ID',
                tooltip: 'External Share\'s Issuer ID',
            }
        },
        PING_ONE: {
            relayState: {
                label: 'Target Application Url',
                tooltip: 'Set this value to Target Application URL field. It\'s necessary for Identity Provider Service initiated SSO.'
            },
            identifier: {
                label: 'Issuer ID',
                tooltip: 'PingOne Issuer ID'
            },
            issuer: {
                label: 'Entity ID',
                tooltip: 'External Share Entity ID'
            },
        },
        OKTA: {
            relayState: {
                label: 'Default RelayState',
                tooltip: 'Set this Relay State as default for Identity Provider Service initiated SSO'
            },
            identifier: {
                label: 'Identity Provider Issuer',
                tooltip: 'Okta\'s Instance Identifier'
            },
            issuer: {
                label: 'SP Entity ID',
                tooltip: 'Unique identifier that is the intended audience of the SAML assertion.'
            }
        },
        AUTH0: {
            relayState: {
                label: 'Default RelayState',
                tooltip: 'Set this as a RelayState query parameter (case sensitive!) in Auth0 initiated SSO'
            },
            identifier: {
                label: 'Issuer',
                tooltip: 'Identity Provider\'s Identifier'
            },
            issuer: {
                label: 'Audience',
                tooltip: 'You may use this as an Audience identifier'
            }
        },
        OTHER: {
            relayState: {
                label: 'Default RelayState',
                tooltip: 'Set this Relay State as default for Identity Provider Service initiated SSO'
            },
            identifier: {
                label: 'Identifier',
                tooltip: 'Identity Provider\'s Identifier'
            },
            issuer: {
                label: 'Issuer ID',
                tooltip: 'If needed, this is External Share\'s Issuer ID'
            }
        }
    };
    features = window.getToken().features;

    currentProvider = SamlConfigurationComponent.identityProviders.OTHER;

    @Input() form: FormGroup;
    @Input() defaultRelayState: string;
    @Input() assertionConsumerUrl: string;
    @Input() issuerId: string;

    identityProviderNames = {
        OTHER: 'Other',
        AZURE: 'Microsoft Azure',
        OKTA: 'Okta',
        AUTH0: 'Auth0',
        PING_ONE: 'Ping One'
    };

    constructor() {}

    ngOnInit(): void {
        const provider = this.getIdentityProviderValue();
        if (provider) {
            this.currentProvider = SamlConfigurationComponent.identityProviders[provider];
        } else {
            this.currentProvider = SamlConfigurationComponent.identityProviders.OTHER;
        }
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }

    isWorkspaceValid(): boolean {
        return this.form.get('workspace').value && !this.form.get('workspace').errors;
    }

    updateLabelsAndTooltips(key) {
        this.currentProvider = SamlConfigurationComponent.identityProviders[key];
    }

    private getIdentityProviderValue(): string {
        return this.form.get('identityProvider').value;
    }
}
