import {LinkFilterCriteria} from './link';

export class ShareBulkUpdateRequest {
    filter: ShareBulkUpdateFilter;
    update: ShareBulkUpdateData;
}

export class ShareBulkUpdateFilter {
    type: ShareBulkUpdateFilterType;
    uuids: string[];
    criteria: LinkFilterCriteria;
}

export class ShareBulkUpdateData {
    domains: ShareBulkUpdateOperation[];
    users: ShareBulkUpdateOperation[];
}

export class ShareBulkUpdateOperation {
    operationType: ShareBulkUpdateOperationType;
    value: string;
}

export enum ShareBulkUpdateOperationType {
    ADD = 'add',
    REMOVE = 'remove',
    SET = 'set',
}

export enum ShareBulkUpdateFilterType {
    SELECTED = 'selected',
    FILTER = 'filter',
    FILTER_EXCEPT_SELECTED = 'filter_except_selected',
}

export const ShareBulkUpdateFilterTypeDescription = new Map<ShareBulkUpdateFilterType, string>([
    [ShareBulkUpdateFilterType.SELECTED, 'all selected shares'],
    [ShareBulkUpdateFilterType.FILTER, 'all shares from filter (from all pages)'],
    [ShareBulkUpdateFilterType.FILTER_EXCEPT_SELECTED, 'not selected shares from filter (from all pages)']
]);
