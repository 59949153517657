import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EditFilledIconDirective} from './edit-filled-icon.directive';
import {DeleteIconDirective} from './delete-icon.directive';
import {EmailIconDirective} from './email-icon.directive';
import {CopyIconDirective} from './copy-icon.directive';
import {IconComponent} from './icon.component';
import {InfoIconDirective} from './info-icon.directive';
import {MinusIconDirective} from './minus-icon.directive';
import {CloseIconDirective} from './close-icon.directive';
import {CalendarIconDirective} from './calendar-icon.directive';
import {AttachmentIconDirective} from './attachment-icon.directive';
import {CommentIconDirective} from './comment-icon.directive';
import {LinkIconDirective} from './link-icon.directive';
import {SubtaskIconDirective} from './subtask-icon.directive';
import {StarFilledIconDirective} from './star-filled-icon.directive';
import {LockIconDirective} from './lock-icon.directive';
import {ShortcutIconDirective} from './shortcut-icon.directive';
import {ErrorIconDirective} from './error-icon.directive';
import {SearchIconDirective} from './search-icon.directive';
import {TransitionIconDirective} from './transition-icon.directive';
import {UsersIconDirective} from './users-icon.directive';
import {ExpirationIconDirective} from './expiration-icon.directive';
import {LockFullIconDirective} from './lock-full-icon.directive';
import {WorklogIconDirective} from './worklog-icon.directive';
import {ChangelogIconDirective} from './changelog-icon.directive';
import {WarnIconDirective} from './warn-icon.directive';
import {ViewIconDirective} from './view-icon.directive';
import {CustomizeColumnsIconDirective} from './customize-columns-icon.directive';
import {MultiIconComponent} from './multi-icon.component';
import {LockCircleIconDirective} from './lock-circle-icon.directive';
import {EditIconDirective} from './edit-icon.directive';
import {RefreshIconDirective} from './refresh-icon.directive';
import {ExportIconDirective} from './export-icon.directive';
import {FilterIconDirective} from './filter-icon.directive';
import {UserIconDirective} from './user-icon.directive';
import {IssueIconDirective} from './issue-icon.directive';
import {BoardIconDirective} from './board-icon.directive';
import {RoadmapIconDirective} from './roadmap-icon.directive';
import {JqlFilterIconDirective} from './jql-filter-icon.directive';
import {AddIconDirective} from './add-icon.directive';
import {ListIconDirective} from './list-icon.directive';
import {ArrowDownIconDirective} from './arrow-down-icon.directive';
import {IssueRaiseIconDirective} from './issue-raise-icon.directive';
import {ArrowUpIconDirective} from './arrow-up-icon.directive';
import {DoneIconDirective} from './done-icon.directive';
import {MagnifierIconDirective} from './magnifier-icon.directive';
import {SettingsIconDirective} from './settings-icon.directive';
import {CheckIconDirective} from './check-icon.directive';
import {LabelIconDirective} from './label-icon.directive';
import {QuoteIconDirective} from './quote-icon.directive';
import {CodeIconDirective} from './code-icon.directive';
import {GraphLineIconDirective} from './graph-line-icon.directive';
import {PreferencesIconDirective} from './preferences-icon.directive';
import {DashboardIconDirective} from './dashboard-icon.directive';

@NgModule({
    declarations: [
        IconComponent,
        MultiIconComponent,
        EditIconDirective,
        RefreshIconDirective,
        EditFilledIconDirective,
        DeleteIconDirective,
        EmailIconDirective,
        CopyIconDirective,
        InfoIconDirective,
        MinusIconDirective,
        CloseIconDirective,
        CalendarIconDirective,
        AttachmentIconDirective,
        CommentIconDirective,
        LinkIconDirective,
        SubtaskIconDirective,
        TransitionIconDirective,
        StarFilledIconDirective,
        LockIconDirective,
        ShortcutIconDirective,
        ErrorIconDirective,
        SearchIconDirective,
        UsersIconDirective,
        LockFullIconDirective,
        ExpirationIconDirective,
        WorklogIconDirective,
        ChangelogIconDirective,
        WarnIconDirective,
        ViewIconDirective,
        CustomizeColumnsIconDirective,
        LockCircleIconDirective,
        ExportIconDirective,
        LockCircleIconDirective,
        FilterIconDirective,
        UserIconDirective,
        IssueIconDirective,
        IssueRaiseIconDirective,
        BoardIconDirective,
        RoadmapIconDirective,
        JqlFilterIconDirective,
        AddIconDirective,
        ListIconDirective,
        ArrowDownIconDirective,
        ArrowUpIconDirective,
        DoneIconDirective,
        MagnifierIconDirective,
        SettingsIconDirective,
        CheckIconDirective,
        LabelIconDirective,
        QuoteIconDirective,
        CodeIconDirective,
        GraphLineIconDirective,
        PreferencesIconDirective,
        DashboardIconDirective
    ],
    imports: [
        CommonModule
    ],
    exports: [
        IconComponent,
        MultiIconComponent,
        EditIconDirective,
        RefreshIconDirective,
        EditFilledIconDirective,
        DeleteIconDirective,
        EmailIconDirective,
        CopyIconDirective,
        InfoIconDirective,
        MinusIconDirective,
        CloseIconDirective,
        CalendarIconDirective,
        AttachmentIconDirective,
        CommentIconDirective,
        LinkIconDirective,
        SubtaskIconDirective,
        TransitionIconDirective,
        StarFilledIconDirective,
        LockIconDirective,
        ShortcutIconDirective,
        ErrorIconDirective,
        SearchIconDirective,
        UsersIconDirective,
        LockFullIconDirective,
        ExpirationIconDirective,
        WorklogIconDirective,
        ChangelogIconDirective,
        WarnIconDirective,
        ViewIconDirective,
        CustomizeColumnsIconDirective,
        LockCircleIconDirective,
        ExportIconDirective,
        LockCircleIconDirective,
        FilterIconDirective,
        UserIconDirective,
        IssueIconDirective,
        IssueRaiseIconDirective,
        BoardIconDirective,
        RoadmapIconDirective,
        JqlFilterIconDirective,
        AddIconDirective,
        ListIconDirective,
        ArrowDownIconDirective,
        ArrowUpIconDirective,
        DoneIconDirective,
        MagnifierIconDirective,
        SettingsIconDirective,
        CheckIconDirective,
        LabelIconDirective,
        QuoteIconDirective,
        CodeIconDirective,
        GraphLineIconDirective,
        PreferencesIconDirective,
        DashboardIconDirective
    ]
})
export class IconsModule {
}
