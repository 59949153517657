import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {EmailFormComponent} from './email-form.component';
import {RecipientsComponent} from './recipients/recipients.component';
import {EmailGroupComponent} from './group/email-group.component';
import {EmailComponent} from './email/email.component';
import {IconsModule} from '../icons/icons.module';
import {NiceDateTimeFormatPipe} from '../components/nice-date-time-format-pipe';
import {RecipientsSimpleComponent} from './recipients/recipients-simple.component';

@NgModule({
    declarations: [
        EmailFormComponent,
        RecipientsComponent,
        RecipientsSimpleComponent,
        EmailGroupComponent,
        EmailComponent
    ],
    imports: [
        BrowserModule,
        BaseModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        IconsModule
    ],
    entryComponents: [
        EmailFormComponent
    ],
    exports: [
        EmailFormComponent,
        RecipientsComponent,
        RecipientsSimpleComponent,
    ],
    providers: [NiceDateTimeFormatPipe]
})
export class EmailFormModule {
}
