import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'shorten'})
export class ShortDescriptionPipe implements PipeTransform {
    transform(value: string): string {
        if (value === null) {
            return '';
        }
        let newValue = value.substr(0, 24);

        if (value.length > 24) {
            newValue = newValue + ' (...)';
        }
        return newValue;
    }
}
