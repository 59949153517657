import {Injectable} from '@angular/core';
import {JiraService, Permissions} from './jira.service';
import {Link} from './base/link';
import {ItemService} from './base/item.service';
import {finalize, switchMap, tap} from 'rxjs/operators';
import {LoaderService} from './loader.service';
import {AppService} from './app.service';
import {of} from 'rxjs';
import {ShareBulkUpdateFilterType, ShareBulkUpdateRequest} from './base/bulk-edit';
import {Activity} from './base/activity';

@Injectable({
    providedIn: 'root'
})
export class ShareActionService {

    constructor(
        private jira: JiraService,
        private shareService: ItemService,
        private loader: LoaderService,
        private itemService: ItemService,
        private app: AppService) {
    }

    deleteSingle(link: Link | Activity) {
        return new Promise((resolve, reject) => {
            this.jira.showDialog({
                    key: 'confirm-delete',
                    customData: {
                        title: 'Delete this External Share link?',
                    }
                }, (result) => {
                    if (result.deleted) {
                        this.loader.fullscreen(true);
                        this.shareService.delete([link])
                            .pipe(finalize(() => this.loader.fullscreen(false)))
                            .subscribe(() => {
                                    this.jira.emitEvent('share-update');
                                    resolve(result);
                                }, error => reject(error)
                            );
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }

    edit(link: Link | Activity, permissions: Permissions, readOnly = false) {
        let customData = {
            uuid: link.uuid,
            readOnly
        };
        if (permissions) {
            customData = Object.assign(customData, {permissions});
        }

        return this.editShare(customData);
    }

    bulkEdit(links: Link[], criteria: { query: string, projectId?: string, userId?: string }, permissions?: Permissions) {
        let customData = {
            filter: {
                type: ShareBulkUpdateFilterType.SELECTED,
                uuids: links.map(l => l.uuid),
                criteria
            }
        } as ShareBulkUpdateRequest & { permissions: Permissions };
        if (permissions) {
            customData = Object.assign(customData, {permissions});
        }
        return this.bulkEditShare(customData);
    }

    editShare(customData) {
        return new Promise((resolve, reject) => {
            this.jira.showDialog({
                    key: 'link-edition-popup',
                    customData
                }, result => {
                    resolve(result);
                }
            );
        });
    }

    bulkEditShare(customData: ShareBulkUpdateRequest & { permissions: Permissions }) {
        return new Promise((resolve, reject) => {
            this.jira.showDialog({
                    key: 'bulk-edition-popup',
                    customData
                }, () => {
                    resolve();
                }
            );
        });
    }

    view(link: Link | Activity, permissions: Permissions) {
        return this.edit(link, permissions, true);
    }

    deleteMultiple(links: Link[]) {
        return new Promise((resolve, reject) => {
            this.jira.showDialog({
                    key: 'confirm-delete',
                    customData: {
                        title: 'Delete selected External Share links?',
                    }
                }, (result) => {
                    if (result.deleted) {
                        this.loader.fullscreen(true);
                        this.shareService.delete(links)
                            .pipe(finalize(() => this.loader.fullscreen(false)))
                            .subscribe(() => {
                                    this.jira.emitEvent('share-update');
                                    resolve(result);
                                }, error => reject(error)
                            );
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }

    deleteAllShares() {
        return new Promise((resolve, reject) => {
            this.jira.showDialog({
                    key: 'confirm-delete',
                    customData: {
                        title: 'Delete all External Share links?',
                    }
                }, (result) => {
                    if (result.deleted) {
                        this.loader.fullscreen(true);
                        this.shareService.deleteAll()
                            .pipe(finalize(() => this.loader.fullscreen(false)))
                            .subscribe((count) => {
                                    this.jira.emitEvent('share-update');

                                    AP.flag.create({
                                        title: `External Share | Deleted ${count} shares`,
                                        body: `Operation was successful. ${count} share links were removed.`,
                                        type: 'success',
                                        close: 'auto'
                                    });
                                    resolve(result);
                                },
                                error => reject(error)
                            );
                    } else {
                        resolve(result);
                    }
                }
            );
        });
    }

    update(link: Link) {
        return this.itemService.update(link)
            .pipe(finalize(() => {
                this.jira.emitEvent('share-update');
            }));
    }

    updateOnClose(link: Link) {
        this.loader.fullscreen(true);
        return this.itemService.update(link)
            .pipe(finalize(() => {
                this.jira.emitEvent('share-update');
                this.loader.fullscreen(false);
            }));
    }

    migrateLite() {
        const migration = this.app.migrate().pipe(
            tap(it => {
                if (it.success) {
                    AP.flag.create({
                        title: `External Share | Migration Completed`,
                        body: `Operation was successful. ` + it.message + '.',
                        type: 'success',
                        close: 'auto'
                    });
                }
            }),
            finalize(() => this.loader.fullscreen(false))
        );

        return of(undefined)
            .pipe(tap(() => this.loader.fullscreen(true)))
            .pipe(switchMap(() => migration));
    }
}
