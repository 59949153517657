import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-checkbox',
    templateUrl: 'app-checkbox.component.html',
    styleUrls: ['./app-checkbox.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AppCheckboxComponent),
            multi: true
        }
    ]
})
export class AppCheckboxComponent implements ControlValueAccessor {

    @Input() label: string;
    @Input() name: string;
    @Input() disabled = false;
    @Input() appearDisabled = false;
    isChecked: boolean;
    focused: boolean;

    private onChangeFn: any;
    private onTouchedFn: any;

    writeValue(value: any) {
        this.isChecked = value;
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onChange(event) {
        if (!this.disabled) {
            this.onChangeFn(event.target.checked);
            this.isChecked = event.target.checked;
        }
    }

    onFocus() {
        this.focused = true;
    }

    onBlur() {
        this.focused = false;
    }
}
