import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {JiraService} from '../../jira.service';
import {AppService} from '../../app.service';
import {ViewItem} from '../../view-item';
import {BoardCardConfigApi, BoardCardLayoutCell, BoardCardLayoutConfig, BoardCardLayoutRow} from './boardCard';
import {SearchFunction} from '../../components/better-select/better-select.component';
import {map, switchMap} from 'rxjs/operators';
import {FormControl, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, zip} from 'rxjs';
import Utils from '../../utils/utils';
import {IssueType} from '../../issueType';

const Mapping = {
    FLEXSTART: 'flex-start',
    CENTER: 'center',
    SPACEBETWEEN: 'space-between',
    SPACEEVENLY: 'space-evenly',
    FLEXEND: 'flex-end',
};

@Component({
    selector: 'app-board-card-layout',
    templateUrl: './board-card-layout.component.html',
    styleUrls: ['./board-card-layout.component.css']
})
export class BoardCardLayoutComponent implements OnInit {
    config$: Observable<any>;
    private refreshEventName = 'config-update';
    @ViewChild('elementRef', {static: true}) elementRef?: ElementRef;
    tab = 'cardLayout';
    deleteAll = false;
    inEditMode = false;
    moreThanOneItemIsSelected = false;
    currentItem: any;
    defaultIssueFields: any;
    fieldValuesToSelect: ViewItem[];
    issuePicker: SearchFunction;
    draggingIndex: number;
    allFieldsWithSchema: any[];
    editDefault = false;
    isConfigNameEmpty = false;
    previewFormGroup: FormGroup = new FormGroup({
        issueToPreview: new FormControl({id: '', text: ''})
    });
    isRendered = false;
    isIssueFieldEmpty = false;

    @Input() allIssueTypes: IssueType[];
    allPriorityTypes: Priority[];

    constructor(private jira: JiraService,
                private app: AppService,
                private route: ActivatedRoute,
                private router: Router) {
        this.issuePicker = this.issuePickerFoo.bind(this);
    }

    public layoutConfigInEditor: BoardCardLayoutConfig;


    ngOnInit(): void {
        this.config$ = zip(
            this.jira.issuePicker(''),
            this.jira.getAllIssueFields(),
            this.jira.getIssueTypesForGlobalProjects(),
            this.jira.getAllPriorityTypes(),
            Utils.combineParams(this.route)
        ).pipe(
            switchMap(([issues, allIssueFields, issueTypes, allPriorityTypes, currentLayoutData]) => {
                const fieldsToMap = allIssueFields.filter(it => {
                    return it.schema.type === 'number'
                        || it.schema.type === 'date'
                        || it.schema.type === 'datetime'
                        || it.schema.type === 'string'
                        || it.schema.type === 'issuetype'
                        || it.schema.type === 'priority'
                        || it.schema.type === 'user'
                        || it.schema.type === 'project'
                        || it.schema.type === 'status'
                        || it.id === 'subtasks'
                        || it.id === 'labels'
                        || it.id === 'parent'
                        || it.schema.type === 'option'
                        ;
                });
                this.allFieldsWithSchema = fieldsToMap;
                this.fieldValuesToSelect = fieldsToMap.map(val => {
                    return {
                        id: val.id.toString(),
                        text: val.name
                    };
                });
                this.fieldValuesToSelect.push({
                    id: 'issueKey',
                    text: 'Issue Key'
                });
                const allFields = this.fieldValuesToSelect.map(it => it.id);
                this.allIssueTypes = issueTypes;
                this.allPriorityTypes = allPriorityTypes;
                if (!issues || issues.length === 0) {
                    this.isIssueFieldEmpty = true;
                } else {
                    this.previewFormGroup.get('issueToPreview').setValue({
                        id: issues[0].id,
                        text: issues[0].key
                    });
                }
                if (currentLayoutData.get('type') === 'edit-layout') {
                    this.inEditMode = true;
                    this.editDefault = false;
                    this.editConfig(BigInt(currentLayoutData.get('id')));
                } else if (currentLayoutData.get('type') === 'edit-default') {
                    this.editDefault = true;
                    this.inEditMode = false;
                    this.editDefaultConfig();
                } else if (currentLayoutData.get('type') === 'create') {
                    this.editDefault = false;
                    this.inEditMode = false;
                    this.createNewConfig();
                }

                const issueId = issues && issues[0] ? issues[0].id : null;
                const fieldString = allFields.join(',') + ',parent';

                return this.jira.getFieldValuesFromIssue(issueId, fieldString);
            }),
            map((fieldValuesFromIssue) => {
                this.defaultIssueFields = fieldValuesFromIssue.fields;
                this.defaultIssueFields.issueKey = fieldValuesFromIssue.key;
                this.isRendered = true;
            })
        );

        this.config$.subscribe(it => it);
    }

    closeEditor() {
        this.isConfigNameEmpty = false;
        this.currentItem = {};
        this.layoutConfigInEditor = {
            name: '',
            cardLayout: []
        };
    }

    addRow(layout: BoardCardLayoutConfig) {
        const config = {
            name: layout.name,
            cardLayout: layout.cardLayout.map((it, index) => {
                return {cells: it.cells, justifyContent: it.justifyContent, type: it.type, index};
            }),
            id: layout.id,
            hostId: layout.hostId,
            cardColor: layout.cardColor
        };
        config.cardLayout.push({
            index: this.layoutConfigInEditor.cardLayout.length + 1,
            type: 'row',
            justifyContent: 'SPACEBETWEEN',
            cells: []
        });
        this.layoutConfigInEditor = config;
        if (this.layoutConfigInEditor.cardLayout.length === 1) {
            this.currentItem = this.layoutConfigInEditor.cardLayout[0];
        }
    }

    selectRow(e: any, row: BoardCardLayoutRow) {
        if (e.target.className === 'row') {
            this.currentItem = row;
        }
    }

    deleteRow(row: BoardCardLayoutRow) {
        if (row.index === this.currentItem.index) {
            this.currentItem = {};
        }
        if (this.currentItem.type === 'cell') {
            row.cells.forEach(cell => {
                if (cell === this.currentItem) {
                    this.currentItem = {};
                }
            });
        }
        this.layoutConfigInEditor.cardLayout = this.layoutConfigInEditor.cardLayout.filter(item => item.index !== row.index);
    }

    manageNameInput(e) {
        this.layoutConfigInEditor.name = e.target.value;
        this.isConfigNameEmpty = false;
    }

    setColor(e) {
        this.layoutConfigInEditor.cardColor = e;
    }

    selectCell(item: BoardCardLayoutCell) {
        this.currentItem = item;
    }

    createGlobalConfig(): void {
        if (this.layoutConfigInEditor.name.length > 0) {
            const boardCardConfig: BoardCardConfigApi = {
                name: this.layoutConfigInEditor.name,
                cardLayout: this.layoutConfigInEditor.cardLayout,
                cardColor: this.layoutConfigInEditor.cardColor
            };
            this.app.addBoardCardLayout(boardCardConfig).subscribe(item => {
                this.jira.emitEvent(this.refreshEventName, JSON.stringify(boardCardConfig));
                this.router.navigate(['/browse/global-view/board-card-layout-list'],
                    {skipLocationChange: true, queryParamsHandling: 'merge'});
            });
            this.isConfigNameEmpty = false;
            this.currentItem = {};
            this.layoutConfigInEditor = {
                name: '',
                cardLayout: [],
                cardColor: {
                    type: 'None', value: {
                        lightMode: {},
                        darkMode: {}
                    }
                }
            };
        } else {
            this.isConfigNameEmpty = true;
        }
    }

    updateConfig(): void {
        if (this.layoutConfigInEditor.name.length > 0) {
            const boardCardConfig: BoardCardConfigApi = {
                name: this.layoutConfigInEditor.name,
                id: this.layoutConfigInEditor.id,
                hostId: this.layoutConfigInEditor.hostId,
                cardLayout: this.layoutConfigInEditor.cardLayout,
                cardColor: this.layoutConfigInEditor.cardColor
            };
            this.app.editBoardCardLayout(boardCardConfig).subscribe(item => {
                this.jira.emitEvent(this.refreshEventName, JSON.stringify(boardCardConfig));
                this.router.navigate(['/browse/global-view/board-card-layout-list'],
                    {skipLocationChange: true, queryParamsHandling: 'merge'});
            });
            this.isConfigNameEmpty = false;
        } else {
            this.isConfigNameEmpty = true;
        }
    }

    editDefaultConfig() {
        this.app.getDefaultBoardCardLayout().subscribe(item => {
            const layout = item[0];
            this.jira.emitEvent(this.refreshEventName);
            this.layoutConfigInEditor = {
                id: layout.id,
                hostId: layout.hostId,
                name: layout.name,
                cardLayout: layout.cardLayout.map((it, index) => {
                    return {cells: it.cells, justifyContent: it.justifyContent, type: it.type, index};
                }),
                cardColor: layout.cardColor
            };
            if (this.layoutConfigInEditor.cardLayout[0]) {
                this.currentItem = this.layoutConfigInEditor.cardLayout[0];
            }

        });
    }

    createNewConfig() {
        this.app.getDefaultBoardCardLayoutSchema().subscribe(schema => {
            this.layoutConfigInEditor = {
                name: '',
                cardLayout: schema,
                cardColor: {
                    type: 'None', value: {
                        lightMode: {},
                        darkMode: {}
                    }
                }
            };
            this.currentItem = this.layoutConfigInEditor.cardLayout[0];
        });
    }

    editConfig(id: bigint) {
        this.app.getBoardCardLayoutById(id).subscribe(item => {
            this.jira.emitEvent(this.refreshEventName);
            this.layoutConfigInEditor = {
                id: item.id,
                hostId: item.hostId,
                name: item.name,
                cardLayout: item.cardLayout.map((it, index) => {
                    return {cells: it.cells, justifyContent: it.justifyContent, type: it.type, index};
                }),
                cardColor: item.cardColor
            };
            if (this.layoutConfigInEditor.cardLayout[0]) {
                this.currentItem = this.layoutConfigInEditor.cardLayout[0];
            }
        });
    }

    returnCellValue(id: string) {
        const fieldValueSchemaType = this.allFieldsWithSchema.filter(it => it.id === id)[0];
        let fieldValue;
        if (fieldValueSchemaType && id !== 'subtasks' && id !== 'labels' && id !== 'parent') {
            let v = this.defaultIssueFields[id];
            if (fieldValueSchemaType.schema.type === 'option') {
                if (isObject(v)) {
                    v = (v as any).value || 'NO VALUE';
                }
            }
            fieldValue = {
                value: v,
                type: fieldValueSchemaType.schema.type
            };
        } else if (id === 'issueKey') {
            fieldValue = {
                value: this.defaultIssueFields[id],
                type: id
            };
        } else if (id === 'subtasks') {
            fieldValue = {
                value: this.defaultIssueFields[id],
                type: id
            };
        } else if (id === 'labels') {
            if (this.defaultIssueFields[id]) {
                const value = this.defaultIssueFields[id].length === 0 ? ['NO VALUE'] : this.defaultIssueFields[id];
                fieldValue = {
                    value,
                    type: id
                };
            } else {
                const noValue = ['NO VALUE'];
                fieldValue = {
                    noValue,
                    type: id
                };
            }
        } else if (id === 'parent') {
            const value = this.defaultIssueFields.parent ? this.defaultIssueFields.parent.fields.summary : 'NO VALUE';
            fieldValue = {
                value,
                type: id
            };
        } else {
            fieldValue = this.defaultIssueFields[id];
        }
        return fieldValue;
    }

    onDragStart(index: number): void {
        this.draggingIndex = index;
    }

    onDragEnter(index: number): void {
        if (this.draggingIndex !== index) {
            this._reorderItem(this.draggingIndex, index);
        }
    }

    onDragEnd(): void {
        this.draggingIndex = undefined;
    }

    private _reorderItem(fromIndex: number, toIndex: number): void {
        const rows = this.layoutConfigInEditor.cardLayout;
        const itemToBeReordered = rows.splice(fromIndex, 1)[0];
        rows.splice(toIndex, 0, itemToBeReordered);
        this.draggingIndex = toIndex;
        this.layoutConfigInEditor.cardLayout = rows.map((column, index) => {
            column.index = index;
            return column;
        });
    }

    issuePickerFoo: SearchFunction = (term) => {
        return this.jira.issuePicker(`${term}`).pipe(
            map(it => {
                return {
                    results: it.map(p => ({id: p.id, text: p.key})),
                    pagination: {more: false}
                };
            })
        );
    }

    selectIssueForPreview(e) {
        const allFields = this.fieldValuesToSelect.map(it => it.id);
        this.jira.getFieldValuesFromIssue(e.id, allFields.join(',') + ',parent')
            .subscribe(it => {
            this.isIssueFieldEmpty = false;
            this.defaultIssueFields = it.fields;
            this.defaultIssueFields.issueKey = it.key;
        });
    }

    changeTab(tab: string) {
        this.tab = tab;
    }

    changeEnumToString(value: string) {
        return Mapping[value] || 'space-between';
    }
}

export function isObject(x: any): x is object {
    return x !== null && typeof x === 'object';
}
