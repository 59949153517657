export class Constants {
    static readonly DATE_FMT = 'd MMMM yyyy';
    static readonly SHORT_DATE_FMT = 'd MMM yyyy';
    static readonly TIME_FMT = 'HH:mm';
    static readonly INPUT_DATE_FMT = 'yyyy-MM-dd';
    static readonly DATE_TIME_FMT = `${Constants.DATE_FMT}, ${Constants.TIME_FMT}`;
    static readonly SHORT_NICE_DATE_TIME_FMT = `${Constants.SHORT_DATE_FMT}, ${Constants.TIME_FMT}`;
    static readonly ISO_DATE_TIME_FORMAT = `yyyy-MM-ddTHH:mm:ss.SSS`;
    static readonly COMMA = ',';
    static readonly ACTIVITY_SOURCES = [
        {text: 'All', value: ''},
        {text: 'Issue', value: 'Issue'},
        {text: 'Comment', value: 'Comment'},
        {text: 'Attachment', value: 'Attachment'}
    ];
    static readonly ACTIVITY_ACTIONS = [
        {text: 'All', value: ''},
        {text: 'Added', value: 'Added'},
        {text: 'Deleted', value: 'Deleted'},
        {text: 'Edited', value: 'Edited'},
        {text: 'Viewed', value: 'Viewed'},
        {text: 'Updated', value: 'Updated'},
        {text: 'Status Changed', value: 'Status Changed'}
    ];
    // when modifying this list also modify back-end list - resources/common-email-domains.txt
    // the list is also available under https://{environment-domain}/misc/common-email-domains
    // TODO: https://warsaw-dynamics.atlassian.net/browse/ESFJ-1129
    static readonly COMMON_EMAIL_DOMAINS = [
        'gmail.com',
        'yahoo.com',
        'hotmail.com',
        'aol.com',
        'hotmail.co.uk',
        'hotmail.fr',
        'msn.com',
        'yahoo.fr',
        'wanadoo.fr',
        'orange.fr',
        'comcast.net',
        'yahoo.co.uk',
        'yahoo.com.br',
        'yahoo.co.in',
        'live.com',
        'rediffmail.com',
        'free.fr',
        'gmx.de',
        'web.de',
        'yandex.ru',
        'ymail.com',
        'libero.it',
        'outlook.com',
        'uol.com.br',
        'bol.com.br',
        'mail.ru',
        'cox.net',
        'hotmail.it',
        'sbcglobal.net',
        'sfr.fr',
        'live.fr',
        'verizon.net',
        'live.co.uk',
        'googlemail.com',
        'yahoo.es',
        'ig.com.br',
        'live.nl',
        'bigpond.com',
        'terra.com.br',
        'yahoo.it',
        'neuf.fr',
        'yahoo.de',
        'alice.it',
        'rocketmail.com',
        'att.net',
        'laposte.net',
        'facebook.com',
        'bellsouth.net',
        'yahoo.in',
        'hotmail.es',
        'charter.net',
        'yahoo.ca',
        'yahoo.com.au',
        'rambler.ru',
        'hotmail.de',
        'tiscali.it',
        'shaw.ca',
        'yahoo.co.jp',
        'sky.com',
        'earthlink.net',
        'optonline.net',
        'freenet.de',
        't-online.de',
        'aliceadsl.fr',
        'virgilio.it',
        'home.nl',
        'qq.com',
        'telenet.be',
        'me.com',
        'yahoo.com.ar',
        'tiscali.co.uk',
        'yahoo.com.mx',
        'voila.fr',
        'gmx.net',
        'mail.com',
        'planet.nl',
        'tin.it',
        'live.it',
        'ntlworld.com',
        'arcor.de',
        'yahoo.co.id',
        'frontiernet.net',
        'hetnet.nl',
        'live.com.au',
        'yahoo.com.sg',
        'zonnet.nl',
        'club-internet.fr',
        'juno.com',
        'optusnet.com.au',
        'blueyonder.co.uk',
        'bluewin.ch',
        'skynet.be',
        'sympatico.ca',
        'windstream.net',
        'mac.com',
        'centurytel.net',
        'chello.nl',
        'live.ca',
        'aim.com',
        'bigpond.net.au',
        'protonmail.com',
        'proton.me',
        'zoho.com',
    ];
}
