import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" focusable="false" role="presentation">' +
    // tslint:disable-next-line:max-line-length
    '<path d="M3 18c0 .552.445 1 .993 1h16.014A.994.994 0 0021 18v-1H3v1zm0-7h18V9H3zm0-4h18V6c0-.552-.445-1-.993-1H3.993A.994.994 0 003 6v1zm0 8h18v-2H3z" fill="currentColor"></path>' +
    '</svg>';

@Directive({
    selector: '[appWorklogIcon]'
})
export class WorklogIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
