import {
    AfterContentInit,
    Directive,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import flatpickr from 'flatpickr';

@Directive({
    selector: '[appDateTimePicker]',
    exportAs: 'dateTimePicker',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DateTimePickerDirective),
            multi: true
        }
    ]
})
export class DateTimePickerDirective implements AfterContentInit, OnDestroy, ControlValueAccessor, OnChanges {

    constructor(private viewContainerRef: ViewContainerRef) {}

    private value: any;

    private component: flatpickr.Instance;

    @Input() readOnly: boolean;

    private onChange: any = () => {};
    private onTouched: any = () => {};

    ngOnChanges(changes: SimpleChanges): void {}

    ngAfterContentInit() {
        this.render();
    }

    render() {
        if (this.component === undefined) {
            const altFormat = 'd-m-Y H:i';
            this.component = flatpickr(this.viewContainerRef.element.nativeElement, {
                altFormat,
                altInput: true,
                allowInput: true,
                enableTime: true,
                defaultDate: new Date(),
                dateFormat: 'Z',
                onChange: (selectedDates) => {
                    const selectedDate = selectedDates[0];
                    if (isValidDate(selectedDate)) {
                        this.onChange(selectedDate.toISOString());
                    } else {
                        this.onChange(null);
                    }
                }
            });

            $(this.component._input).on('blur', (event) => {
                if (event.originalEvent.isTrusted) {
                    const date = flatpickr.parseDate(this.component._input.value, altFormat);
                    this.component.setDate(isValidDate(date) ? date : null, true);
                }
            });
        }
        if (this.value) {
            this.component.setDate(this.value);
        }
        if (this.readOnly) {
            this.component._input.setAttribute('disabled', 'disabled');
        }
    }

    ngOnDestroy() {
        this.component.destroy();
        this.component = undefined;
    }

    writeValue(value: any) {
        this.value = value;
        if (this.component && value) {
            this.component.setDate(this.value, true);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.readOnly = isDisabled;
        this.render();
    }

    open() {
        if (this.component) {
            this.component.open();
        }
    }

    clear() {
        if (this.component) {
            this.component.clear();
            this.onChange(null);
        }
    }
}

function isValidDate(date) {
    return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
}
