import {Component, Input} from '@angular/core';
import {Link} from '../base/link';
import {JiraService, Permissions} from '../jira.service';
import {ShareActionService} from '../share-action.service';
import {Activity} from '../base/activity';

@Component({
    selector: 'app-share-action-row',
    templateUrl: './share-action-row.component.html',
    styleUrls: ['./share-action-row.component.css']
})
export class ShareActionRowComponent {

    @Input() share: Link | Activity;
    @Input() permissions: Permissions;

    constructor(private jira: JiraService,
                private shareActions: ShareActionService) {
    }

    edit(link: Link | Activity) {
        this.shareActions.edit(link, this.permissions);
    }

    view(link: Link | Activity) {
        this.shareActions.view(link, this.permissions);
    }

    sendViaMail(link: Link | Activity) {
        let customData = {
            uuid: link.uuid,
        };
        if (this.permissions) {
            const permissions = this.permissions;
            customData = Object.assign(customData, {permissions});
        }
        this.jira.showDialog({
                key: 'send-via-mail',
                customData
            }
        );
    }

    confirmDeletion(link: Link | Activity) {
        this.shareActions.deleteSingle(link);
    }

    getInactiveStatusTooltip(status): string {
        const messages = [];

        if (status.disabled) {
            messages.push('disabled');
        }

        if (status.disabledByParent) {
            messages.push('disabled by parent');
        }

        if (status.expired) {
            messages.push('expired');
        }
        return messages.length ? 'Link is ' + messages.join(', ') : '';
    }
}
