import {ParamMap} from '@angular/router';
import Utils from './utils';
import {Observable, of} from 'rxjs';
import {Permissions} from '../jira.service';

export default class PermissionUtils {

    public static getPermissions(query: ParamMap, isPermissionSchemeEnabled: boolean): Observable<Permissions> {
        return of(PermissionUtils.getPermissionsObj(query, isPermissionSchemeEnabled));
    }

    public static getPermissionsObj(query: ParamMap, isPermissionSchemeEnabled: boolean): Permissions {
        const usePermissionScheme = isPermissionSchemeEnabled &&
            !PermissionUtils.isAdminVal(query);
        return {
            canCreate: usePermissionScheme ? Utils.isTrue(query.get('hasCreatePermission')) : true,
            canEdit: usePermissionScheme ? Utils.isTrue(query.get('hasEditPermission')) : true,
            canDelete: usePermissionScheme ? Utils.isTrue(query.get('hasDeletePermission')) : true,
            canSendEmail: usePermissionScheme ? Utils.isTrue(query.get('hasSendEmailPermission')) : true,
            canViewSharesInProject: usePermissionScheme ? Utils.isTrue(query.get('hasViewPermission')) : true
        };
    }

    public static getPermissionsFromCustomData(data: any, permissions?: Permissions): Permissions {
        if (data.readOnly) {
            return {
                canCreate: false,
                canEdit: false,
                canDelete: data.permissions ? data.permissions.canDelete : false,
                canSendEmail: data.permissions ? data.permissions.canSendEmail : false
            };
        } else if (data.permissions) {
            return data.permissions;
        } else if (permissions) {
            return permissions;
        } else {
            return {
                canCreate: true,
                canEdit: true,
                canDelete: true,
                canSendEmail: true
            };
        }
    }

    public static isAdmin(params: ParamMap): Observable<boolean> {
        return of(PermissionUtils.isAdminVal(params));
    }

    private static isAdminVal(params: ParamMap): boolean {
        return Utils.isTrue(params.get('user_is_admin'))
            || Utils.isTrue(params.get('user_is_project_admin'));
    }
}
