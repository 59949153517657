import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

const SHOW_PASSWORD_SVG =
    `<svg width="16" height="16" viewBox="0 0 24 24" role="presentation">
        <g fill="currentColor" fill-rule="evenodd">` +
            // tslint:disable-next-line:max-line-length
            `<path d="M12 18c-4.536 0-7.999-4.26-7.999-6 0-2.001 3.459-6 8-6 4.376 0 7.998 3.973 7.998 6 0 1.74-3.462 6-7.998 6m0-14C6.48 4 2 8.841 2 12c0 3.086 4.576 8 10 8 5.423 0 10-4.914 10-8 0-3.159-4.48-8-10-8"></path>` +
            // tslint:disable-next-line:max-line-length
            `<path d="M11.978 13.984c-1.104 0-2-.897-2-2s.896-2 2-2c1.103 0 2 .897 2 2s-.897 2-2 2m0-6c-2.206 0-4 1.794-4 4s1.793 4 4 4 4-1.794 4-4-1.794-4-4-4"></path>
        </g>
    </svg>`;
const HIDE_PASSWORD_SVG =
    `<svg width="16" height="16" viewBox="0 0 24 24" role="presentation">
        <g fill="currentColor" fill-rule="evenodd">` +
            // tslint:disable-next-line:max-line-length
            `<path d="M12 18c-4.536 0-7.999-4.26-7.999-6 0-2.001 3.459-6 8-6 4.376 0 7.998 3.973 7.998 6 0 1.74-3.462 6-7.998 6m0-14C6.48 4 2 8.841 2 12c0 3.086 4.576 8 10 8 5.423 0 10-4.914 10-8 0-3.159-4.48-8-10-8"></path>` +
            // tslint:disable-next-line:max-line-length
            `<path fill="currentColor" d="M12 10.586 6.707 5.293a1 1 0 0 0-1.414 1.414L10.586 12l-5.293 5.293a1 1 0 0 0 1.414 1.414L12 13.414l5.293 5.293a1 1 0 0 0 1.414-1.414L13.414 12l5.293-5.293a1 1 0 1 0-1.414-1.414z"></path>
        </g>
    </svg>`;

@Directive({
    selector: '[appToggleVisibility]'
})
export class ToggleVisibilityDirective implements OnInit {

    private isVisible = false;
    private button: HTMLElement;
    private input: HTMLInputElement;

    constructor(private el: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        this.input = this.el.nativeElement.querySelector('input');
        if (!this.input) {
            console.error('appToggleVisibility directive requires an input element inside the host element.');
            return;
        }

        this.button = this.renderer.createElement('span');

        this.renderer.addClass(this.button, 'button-icon');
        this.renderer.addClass(this.button, 'button--secondary');

        this.renderer.setProperty(this.button, 'innerHTML', SHOW_PASSWORD_SVG);

        const container = this.el.nativeElement.querySelector('.inside-input-icons-container');
        this.renderer.appendChild(container, this.button);

        this.setInvisible();

        this.renderer.listen(this.button, 'click', () => this.toggleVisibility());
    }

    private setInvisible() {
        this.isVisible = false;
        this.input.type = 'password';
        this.updateIcon();
    }

    private toggleVisibility() {
        this.isVisible = !this.isVisible;
        this.input.type = this.isVisible ? 'text' : 'password';
        this.updateIcon();
    }

    private updateIcon() {
        if (this.isVisible) {
            this.renderer.setProperty(this.button, 'innerHTML', HIDE_PASSWORD_SVG);
            this.renderer.setAttribute(this.button, 'title', 'Hide contents');
        } else {
            this.renderer.setProperty(this.button, 'innerHTML', SHOW_PASSWORD_SVG);
            this.renderer.setAttribute(this.button, 'title', 'Show contents');
        }
    }
}
