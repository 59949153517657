import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-global-permissions',
    templateUrl: './global-permissions.component.html',
    styleUrls: ['./global-permissions.component.css']
})
export class GlobalPermissionsComponent {

    @Input() form: FormGroup;
}
