import {Component, Input} from '@angular/core';
import {AtlassianUser} from '../atlassian-user';
import {JiraService} from '../jira.service';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-link-to-user',
    template: '<a [href]="href" target="_parent">{{user ? user.displayName : \'Loading\'}}</a>'
})
export class LinkToUserComponent {

    public user: AtlassianUser;
    public href = '';

    constructor(private jira: JiraService) {}

    @Input()
    public set userId(userId: string) {
        if (environment.server) {
            this.href = environment.host + '/secure/ViewProfile.jspa?name=' + userId;
        } else {
            this.href = environment.host + '/secure/ViewProfile.jspa?accountId=' + userId;
        }

        this.jira.requestUser(userId).subscribe((user: AtlassianUser) => {
            this.user = user;
        });
    }
}
