import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24" role="presentation">' +
    // tslint:disable-next-line:max-line-length
    '   <g fill="currentColor"><path d="M21 17H5c-.55 0-1-.45-1-1.01V6a1 1 0 1 0-2 0v9.99A3 3 0 0 0 5 19h16a1 1 0 0 0 0-2m-3-8v3a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1h-4a1 1 0 0 0 0 2z"></path><path d="M13.293 13.707a1 1 0 0 0 1.414 0l4-4a1 1 0 1 0-1.414-1.414L14 11.586l-2.293-2.293a1 1 0 0 0-1.414 0l-4 4a1 1 0 0 0 1.414 1.414L11 11.414z"></path></g>' +
    '   </path>' +
    '</svg>';

@Directive({
    selector: '[appGraphLineIcon]'
})
export class GraphLineIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
