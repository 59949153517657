import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SearchFunction} from '../../components/better-select/better-select.component';
import {JiraService} from '../../jira.service';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-associated-projects',
    templateUrl: './associated-projects.component.html',
    styleUrls: ['./associated-projects.component.css']
})
export class AssociatedProjectsComponent {
    editAssociatedProjects = false;
    @Input() form: FormGroup;
    @Input() projectPickForm: FormGroup = new FormGroup({
        projectPick: new FormControl('')
    });

    projectPick: SearchFunction;

    constructor(private jira: JiraService) {
        this.projectPick = this.projectPickFoo.bind(this);
    }

    projectPickFoo: SearchFunction = (term) => {
        return this.jira.searchForProject(term).pipe(
            map(it => {
                return {
                    results: it.values.map(p => ({id: p.id, text: p.name})),
                    pagination: {more: !it.isLast}
                };
            })
        );
    }

    add() {
        const project = this.projectPickForm.get('projectPick');
        const value = project.value;
        if (Array.isArray(value)) {
            const existing = this.associatedProjectsValue;
            const cache = {};
            for (const el of existing) {
                cache[el.id] = el;
            }

            const newValues = existing;
            for (const val of value) {
                if (!cache[val.id]) {
                    newValues.push({id: val.id, name: val.text});
                }
            }

            this.associatedProjectsValue = newValues;
            project.setValue([]);
        }
    }

    hasElements(value: any) {
        return Array.isArray(value) && value.length > 0;
    }

    delete(toDelete: any) {
        const existing = this.associatedProjectsValue;
        const newValues = [];
        for (const el of existing) {
            if (el.id !== toDelete.id) {
                newValues.push(el);
            }
        }

        this.associatedProjectsValue = newValues;
    }

    get associatedProjectsControl() {
        return this.form.get('associatedProjects');
    }

    get associatedProjectsValue() {
        return this.associatedProjectsControl.value;
    }

    set associatedProjectsValue(val: any) {
        this.associatedProjectsControl.setValue(val);
    }
}
