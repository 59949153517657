import { Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import {AbstractControl, ControlContainer, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import Utils from '../../../utils/utils';

@Component({
    selector: 'app-share-editor-text-field',
    templateUrl: './share-editor-text-field.component.html',
    styleUrls: ['./share-editor-text-field.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ShareEditorTextFieldComponent),
            multi: true
        }
    ]
})
export class ShareEditorTextFieldComponent implements ControlValueAccessor, OnInit {
    @Input() label: string;
    @Input() info: string;
    @Input() warningMessages: { [key: string]: string } | Array<{ [key: string]: string }>;
    @Input() formControlName: string;
    @Input() required = false;
    @Input() disabled = false;

    value = '';
    warningMessage = '';
    copied = false;

    onChange = (value: string) => {};
    onTouched = () => {};

    constructor(@Optional() private controlContainer: ControlContainer) {}

    ngOnInit(): void {
        const control = this.getControl();
        if (control) {
            control.statusChanges.subscribe(() => {
                this.updateWarningMessage(control);
            });
            this.updateWarningMessage(control);
        }
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    handleInput(event: Event): void {
        const input = event.target as HTMLInputElement;
        this.value = input.value;
        this.onChange(this.value);
    }

    copyToClipboard(value): void {
        const textarea = document.createElement('textarea');
        textarea.value = value;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);

        this.copied = true;
        setTimeout(() => {
            this.copied = false;
        }, 2000);
    }

    shouldDisplayWarning(): boolean {
        return !!this.warningMessage;
    }

    private getControl(): AbstractControl {
        const formGroup = this.controlContainer.control as FormGroup;
        return formGroup.get(this.formControlName);
    }

    private updateWarningMessage(control: AbstractControl): void {
        this.warningMessage = Utils.updateWarnMessage(control, this.warningMessages);
    }
}
