import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ConfigEnabled} from '../config-enabled';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-selected-users',
    templateUrl: './selected-users.component.html',
    styleUrls: ['./selected-users.component.css']
})
export class SelectedUsersComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() enabled: ConfigEnabled;

    selectedUsers = [];
    private allowedSelectedUserEmails: string[];
    private allowedSelectedUserEmailDomains: string[];

    constructor() {
    }

    ngOnInit(): void {
        const opt = this.enabled.security;
        this.selectedUsers = [
            ['OPTIONAL', 'Optional', null, opt.selectedUsers.optional],
            ['REQUIRED', 'Required', null, opt.selectedUsers.required]
        ];

        this.getAllowedSelectedUsersEmailDomains().valueChanges.subscribe(v => {
            if (v) {
                let value = v.toLowerCase();
                if (v.includes(' ')) {
                    value = value.replace(/\s/g, '');
                }
                this.getAllowedSelectedUsersEmailDomains().setValue(value, {emitEvent: false});
            }
        });
    }

    shouldDisplayAllowedSelectedUsersEmailDomainsError(): boolean {
        return Utils.shouldDisplayError(this.getAllowedSelectedUsersEmailDomains());
    }

    shouldDisplayError(): boolean {
        return this.getAllowedEmails().invalid && this.isTouchedOrDirty();
    }

    isTouchedOrDirty(): boolean {
        const control: AbstractControl = this.getAllowedEmails();
        return control.dirty || control.touched;
    }

    getAllowedEmails(): AbstractControl {
        return this.form.get('allowedSelectedUsers');
    }

    getAllowedUserEmails() {
        return this.allowedSelectedUserEmails ? this.allowedSelectedUserEmails : [];
    }

    getAllowedEmailDomains() {
        return this.allowedSelectedUserEmailDomains ? this.allowedSelectedUserEmailDomains : [];
    }

    private getAllowedSelectedUsersEmailDomains() {
        return this.form.get('allowedSelectedUsersEmailDomains');
    }
}
