import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {IconsModule} from '../icons/icons.module';
import { ShareTableComponent } from './share-table/share-table.component';
import {IssueViewComponent} from './issue-view/issue-view.component';

@NgModule({
    declarations: [
        IssueViewComponent,
        ShareTableComponent
    ],
    imports: [
        CommonModule,
        BaseModule,
        BrowserModule,
        FormsModule,
        IconsModule,
        ReactiveFormsModule
    ],
    exports: [
        IssueViewComponent
    ]
})
export class IssueViewModule {
}
