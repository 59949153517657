import {Component, OnInit, Renderer2} from '@angular/core';
import {JiraService} from '../../jira.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CdkDragDrop, CdkDragEnd, CdkDragStart, transferArrayItem} from '@angular/cdk/drag-drop';
import {AppService} from '../../app.service';
import {IssueTypeField} from './types/issueType';
import {IssueLayout, LayoutFieldSchema, ViewItemWithType} from './types/issueLayout';
import {IssueField} from './types/issueField';
import {Observable, of, zip} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {IssueLayoutConfigData} from './issueLayout';
import {FormControl, FormGroup} from '@angular/forms';

@Component({
    selector: 'app-issue-layout-customization',
    templateUrl: './issue-layout-customization.component.html',
    styleUrls: ['./issue-layout-customization.component.css']
})
export class IssueLayoutCustomizationComponent implements OnInit {
    searchForm = new FormGroup({
        search: new FormControl('')
    });
    config$: Observable<IssueLayoutConfigData>;
    issueTypes: IssueTypeField[];
    selectedIssueType: IssueTypeField;
    issueTypesWithFields: IssueField[];
    fieldsToSelect: ViewItemWithType[];
    allFieldsToSelectForCurrentIssueType: ViewItemWithType[];
    defaultIssueLayoutSchema: LayoutFieldSchema;
    searchInput = '';
    issueLayoutData: IssueLayout = {
        id: '0',
        layout: {},
        name: ''
    };
    isDragging: boolean;
    inEditMode = false;
    editDefault = false;
    isConfigNameEmpty = false;
    currentItem: any;
    isLoaded = false;
    constructor(private jira: JiraService,
                private app: AppService,
                private router: Router,
                private route: ActivatedRoute,
                private renderer: Renderer2
                ) {
    }

    ngOnInit(): void {
        this.config$ = zip(
            this.jira.getIssueTypesForGlobalProjects(),
            this.jira.getAllFields(),
            this.app.getDefaultIssueLayoutSchema(),
            Utils.combineParams(this.route)
        ).pipe(
            switchMap(([issueTypes, allFields, defaultIssueLayoutSchema, params]) => {
                this.issueTypes = issueTypes;
                this.defaultIssueLayoutSchema = defaultIssueLayoutSchema;
                const layoutData: { [key: string]: any } = {};
                issueTypes.forEach(it => {
                    layoutData[it.id] = {
                        descriptionFields: [],
                        contextFields: [],
                        contextFieldsHiddenWhenEmpty: []
                    };
                });
                this.issueLayoutData.layout = layoutData;
                // workaround to make parent field selectable
                allFields.forEach(field => {
                    if (field.id === 'parent' && !field.schema) {
                        field.schema = { type: 'issue' };
                    }
                });
                // we filter out these fields because there is no reasonable way to display them,
                // status field is an exception and because it has
                // a specific place in layout it can be only enabled or disabled on project/global level configuration
                this.issueTypesWithFields = allFields
                    .filter(it => ![
                        'comment', 'issuetype',
                        'issuerestriction', 'worklog', 'status', 'attachment',
                        'security', 'description', 'summary'
                    ].includes(it.id));
                this.issueTypesWithFields.push({
                    id: 'restOfCustomFields',
                    name: 'Rest of custom fields',
                    schema: { type: 'user-custom-fields' }
                });

                if (params.get('type') === 'edit-layout') {
                    this.inEditMode = true;
                    this.editDefault = false;
                    this.editConfig(params.get('id'));
                } else if (params.get('type') === 'edit-default') {
                    this.editDefault = true;
                    this.inEditMode = false;
                    this.editDefaultConfig();
                } else if (params.get('type') === 'create') {
                    const createNewLayoutData: { [key: string]: any } = {};
                    issueTypes.forEach(it => {
                        createNewLayoutData[it.id] = {
                            descriptionFields: [],
                            contextFields: [],
                            contextFieldsHiddenWhenEmpty: defaultIssueLayoutSchema.contextFieldsHiddenWhenEmpty
                        };
                    });
                    this.issueLayoutData.layout = createNewLayoutData;
                    this.editDefault = false;
                    this.inEditMode = false;
                    this.createNewConfig();
                }

                this.searchForm.get('search').valueChanges.subscribe(searchInput => {
                    this.search(searchInput, this.getAllFieldsToSelect(this.allFieldsToSelectForCurrentIssueType, this.issueLayoutData));
                });

                return of({ issueTypes, allFields, params });
            })
        );

        this.config$.subscribe(
            data => {
                this.isLoaded = true;
            }
        );
    }

    editConfig(id: string) {
        this.app.getIssueLayoutById(id).subscribe(item => {
            this.issueLayoutData = {
                id: item.id,
                name: item.name,
                layout: item.issueLayout,
            };
            this.selectIssueType(this.issueTypes[0]);
        });
    }

    editDefaultConfig() {
        this.app.getDefaultIssueLayout().subscribe(item => {
            const layout = item[0];
            this.issueLayoutData = {
                id: layout.id,
                name: layout.name,
                layout: layout.issueLayout,
            };
            this.selectIssueType(this.issueTypes[0]);
        });
    }

    createNewConfig() {
        this.selectIssueType(this.issueTypes[0]);
    }

    createGlobalConfig(): void {
        if (this.issueLayoutData.name.length > 0) {
            this.isLoaded = false;
            const issueConfig = {
                name: this.issueLayoutData.name,
                layout: this.issueLayoutData.layout,
            };

            this.app.createIssueLayout(issueConfig).subscribe(item => {
                this.router.navigate(['/browse/global-view/issue-layout-list'], {skipLocationChange: true, queryParamsHandling: 'merge'});
            });
            this.isConfigNameEmpty = false;
            this.currentItem = {};
            this.issueLayoutData = {
                id: '0',
                layout: {},
                name: ''
            };
        } else {
            this.isConfigNameEmpty = true;
        }
    }

    updateConfig(): void {
        if (this.issueLayoutData.name.length > 0) {
            this.isLoaded = false;
            const issueConfig: IssueLayout = {
                name: this.issueLayoutData.name,
                id: this.issueLayoutData.id,
                layout: this.issueLayoutData.layout,
            };
            this.app.editIssueLayout(issueConfig).subscribe(item => {
                this.router.navigate(['/browse/global-view/issue-layout-list'], {skipLocationChange: true, queryParamsHandling: 'merge'});
            });
            this.isConfigNameEmpty = false;
        } else {
            this.isConfigNameEmpty = true;
        }
    }

    addField(field: ViewItemWithType) {
        const selectedIssueType = this.selectedIssueType.id;
        this.issueLayoutData.layout[selectedIssueType].descriptionFields.push(field);
        this.issueLayoutData.layout[selectedIssueType].descriptionFields = this.issueLayoutData.layout[selectedIssueType].descriptionFields
            .map(it => {
                return {
                    id: it.id,
                    text: it.text,
                    type: it.type
                };
            });
        this.fieldsToSelect = this.fieldsToSelect.filter(item => item.text !== field.text);
    }

    removeField(field: ViewItemWithType) {
        const selectedIssueType = this.selectedIssueType.id;
        this.issueLayoutData.layout[selectedIssueType].descriptionFields = this.issueLayoutData
            .layout[selectedIssueType].descriptionFields
            .filter(item => item.text !== field.text);
        this.issueLayoutData.layout[selectedIssueType].contextFields = this.issueLayoutData
            .layout[selectedIssueType].contextFields
            .filter(item => item.text !== field.text);
        this.issueLayoutData.layout[selectedIssueType].contextFieldsHiddenWhenEmpty = this.issueLayoutData
            .layout[selectedIssueType].contextFieldsHiddenWhenEmpty
            .filter(item => item.text !== field.text);
        this.fieldsToSelect.push(field);
        this.fieldsToSelect.sort((a, b) => a.text.localeCompare(b.text));
    }

    selectIssueType(type: IssueTypeField) {
        this.selectedIssueType = type;
        this.setFieldsToSelect(type);
    }

    search(searchInput: string, allFields) {
        this.searchInput = searchInput;
        if (searchInput !== '') {
            this.fieldsToSelect = allFields
                .filter(item => item.text.toLowerCase().includes(searchInput.toLowerCase())).sort((a, b) => a.text.localeCompare(b.text));
        } else {
            this.fieldsToSelect = allFields;
        }

    }

    setFieldsToSelect(type: IssueTypeField) {
        const allFieldsSelected = [];
        if (this.issueLayoutData.layout[type.id] === undefined) {
            this.issueLayoutData.layout[type.id] = this.defaultIssueLayoutSchema;
        } else {
            allFieldsSelected.push(this.issueLayoutData.layout[type.id].descriptionFields);
            allFieldsSelected.push(this.issueLayoutData.layout[type.id].contextFields);
            allFieldsSelected.push(this.issueLayoutData.layout[type.id].contextFieldsHiddenWhenEmpty);
        }
        const allValuesToRemoveFromToSelect = [];
        allFieldsSelected.forEach(field => {
            field.forEach(item => {
                allValuesToRemoveFromToSelect.push(item);
            });
        });
        const idsToRemove = allValuesToRemoveFromToSelect.map(it => it.id);
        const currentFields = this.issueTypesWithFields;
        let fieldsFiltered = currentFields;
        if (allValuesToRemoveFromToSelect.length !== 0) {
            fieldsFiltered = currentFields.filter(it => !idsToRemove.includes(it.id));
        }

        this.fieldsToSelect = fieldsFiltered
            .filter(it => it.schema)
            .filter(it => it.id !== 'summary' && it.id !== 'description')
            .map(it => {
            return {
                id: it.id,
                text: it.name,
                type: it.schema.type
            };
        }).sort((a, b) => a.text.localeCompare(b.text));
        this.allFieldsToSelectForCurrentIssueType = this.fieldsToSelect;
    }

    getAllFieldsToSelect(allFields: ViewItemWithType[], issueLayoutData: IssueLayout) {
        const descriptionFields = issueLayoutData.layout[this.selectedIssueType.id].descriptionFields;
        const contextFields = issueLayoutData.layout[this.selectedIssueType.id].contextFields;
        const contextFieldsHiddenWhenEmpty = issueLayoutData.layout[this.selectedIssueType.id].contextFieldsHiddenWhenEmpty;
        const combined: string[] = [].concat(descriptionFields, contextFields, contextFieldsHiddenWhenEmpty).map(it => it.id);
        return allFields.filter(it => !combined.includes(it.id));
    }

    drop(event: CdkDragDrop<any>) {
        transferArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex
        );
    }

    onDragStarted(event: CdkDragStart): void {
        this.renderer.addClass(event.source.element.nativeElement, 'dragging');
        this.isDragging = true;
    }

    onDragEnded(event: CdkDragEnd): void {
        this.renderer.removeClass(event.source.element.nativeElement, 'dragging');
        this.isDragging = false;
    }

    manageNameInput(e) {
        this.issueLayoutData.name = e.target.value;
    }
}
