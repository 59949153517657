import {Component} from '@angular/core';

@Component({
    selector: 'app-dragging-instruction-svg',
    template: '<svg style="width: 262px; height: 100px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 262 99">' +
        '<path d="M139 36h119c1.7 0 3 1.3 3 3v21c0 1.7-1.3 3-3 ' +
        '3H139c-1.7 0-3-1.3-3-3V39c0-1.7 1.3-3 3-3zM4 1h119c1.7 ' +
        '0 3 1.3 3 3v91c0 1.7-1.3 3-3 3H4c-1.7 0-3-1.3-3-3V4c0-1.7 ' +
        '1.3-3 3-3z" fill="var(--ds-background-input, white)"' +
        ' stroke="var(--ds-border-accent-gray, #97a0af)" stroke-linecap="round" ' +
        'stroke-dasharray="2,4"></path><path d="M139 1h119c1.7 0 3 1.3 3 3v21c0 ' +
        '1.7-1.3 3-3 3H139c-1.7 0-3-1.3-3-3V4c0-1.7 1.3-3 3-3zm0 70h119c1.7 0 ' +
        '3 1.3 3 3v21c0 1.7-1.3 3-3 3H139c-1.7 0-3-1.3-3-3V74c0-1.7 1.3-3 3-3z" ' +
        'fill="var(--ds-background-accent-gray-subtlest, #EBECF0)">' +
        '</path>' +
        '<path d="M59,18c-1.7,0-3,1.3-3,3v21c0,1.7,1.3,3,3,3h119c1.7,0,3-1.3,3-3V21c0-1.7-1.3-3-3-3H59z"' +
        ' fill-rule="evenodd" fill="var(--ds-background-input, white)" ' +
        'stroke="var(--ds-border-accent-gray, #c1c7d0)" stroke-width=".5">' +
        '</path>' +
        '<path d="M171.8 36.5c-.2.1-.3.4-.2.7l2.2 3.9c.1.2.4.3.7.2.2-.1.3-.4.2-.7l-2-3.5 3.5-2c.2-.1.3-' +
        '.4.2-.7-.1-.2-.4-.3-.7-.2l-3.9 2.3zm15.7 15.4c-.4-4.1-1.3-7.2-3.6-9.7-2.3-2.4-5.9-4.2-11.8-5.' +
        '8l-.3 1c5.8 1.6 9.2 3.2 11.3 5.5 2.1 2.2 2.9 5 3.3 9.1l1.1-.1z" ' +
        'stroke="var(--ds-icon, #000000)" stroke-width=".5">' +
        '</path>' +
        '</svg>'
}) export class DraggingInstructionSvgComponent {
}
