import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import {Observable, zip} from 'rxjs';
import {AppState, ItemService} from '../../base/item.service';
import {JiraService, Permissions} from '../../jira.service';
import Utils from '../../utils/utils';
import PermissionUtils from '../../utils/permission-utils';
import {ActivityParameters} from '../../components/activity/activity.component';

export interface ListAppState extends AppState {
    isAdmin: boolean;
    issueId: string;
    projectKey: string;
    projectId: string;
}

@Component({
    selector: 'app-project-view',
    templateUrl: './issue-view.component.html',
    styleUrls: ['./issue-view.component.css']
})
export class IssueViewComponent implements OnInit {
    config$: Observable<ListAppState>;

    tab: string;
    createDisabled = false;

    constructor(private route: ActivatedRoute,
                private item: ItemService,
                private jira: JiraService) {
    }

    ngOnInit() {
        this.jira.resize('es-expand');

        this.config$ = zip(
            Utils.require(Utils.combineParams(this.route),
                'IssueViewComponent', 'ngOnInit', 'Utils.combineParams(this.route)'),
            Utils.require(this.jira.getAddonProperty('permissions', '{"enabled": false}'),
                'IssueViewComponent', 'ngOnInit', 'this.jira.getAddonProperty("permissions", \'{"enabled": false}\')')
        ).pipe(
            switchMap(([params, permissionSchemeObj]) => zip(
                Utils.require(Utils.getAppState(params, permissionSchemeObj.enabled),
                    'IssueViewComponent', 'ngOnInit', `Utils.getAppState(${JSON.stringify(params)}, ${permissionSchemeObj.enabled})`),
                Utils.require(PermissionUtils.isAdmin(params),
                    'IssueViewComponent', 'ngOnInit', `PermissionUtils.isAdmin(${JSON.stringify(params)})`),
                Utils.require(PermissionUtils.getPermissions(params, permissionSchemeObj.enabled),
                    'IssueViewComponent', 'ngOnInit',
                    `PermissionUtils.getPermissions(${JSON.stringify(params)}, ${permissionSchemeObj.enabled})`),
                Utils.require(this.jira.getIssueCached(params.get('issueId')),
                    'IssueViewComponent', 'ngOnInit', `this.jira.getIssueCached(${params.get('issueId')})`)
            )),
            map(([state, isAdmin, permissions, issue]) => {
                this.tab = 'links';
                return Object.assign(state, {
                    isAdmin,
                    permissions,
                    issueId: issue.id,
                    projectKey: issue.fields.project.key,
                    projectId: issue.fields.project.id
                });
            })
        );
    }

    createNew(permissions: Permissions) {
        this.createDisabled = true;
        let customData = {
            isNew: true
        };
        if (permissions) {
            customData = Object.assign(customData, {permissions});
        }

        this.route.paramMap.pipe(
            switchMap(it => Utils.require(
                this.item.create(it.get('issueId')),
                'IssueViewComponent', 'createNew', `this.item.create(${it.get('issueId')})`
            ))
        ).subscribe(link => {
            const uuid = link.uuid;
            customData = Object.assign(customData, {uuid});
            this.jira.emitEvent('share-update', JSON.stringify(link));
            this.jira.showDialog({
                    key: 'link-edition-popup',
                    customData
                }
            );
            this.createDisabled = false;
        });
    }

    hasCreatePermission(config: ListAppState) {
        return config.isAdmin || config.permissions.canCreate;
    }

    changeTab(tab: string) {
        this.tab = tab;
    }

    getActivityParameters(config: ListAppState): ActivityParameters {
        return {
            level: 'ISSUE',
            projectKey: config.projectKey,
            projectId: config.projectId,
            issueId: config.issueId
        };
    }
}
