import {Directive, ElementRef, HostBinding, HostListener, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FilterCriteria, ServerDataSource} from './app-table.component';

interface SortInfo {
    key: string;
    sortOrder: string;
}

@Directive({
    selector: '[appTableSortHeader]'
})
export class TableSortHeaderDirective implements OnChanges {

    @Input('appTableSortHeader') public dataSource: ServerDataSource<any, FilterCriteria>;

    @Input() sortKey: string;

    @HostBinding('class.sort') sortClass = true;

    private sortAscVal = false;
    @HostBinding('class.sort-asc') get sortAsc() {
        return this.sortAscVal;
    }

    private sortDescVal = false;
    @HostBinding('class.sort-desc') get sortDesc() {
        return this.sortDescVal;
    }

    constructor(el: ElementRef) {
        const parent = (el.nativeElement as Element).parentElement;
        parent.addEventListener('click', (e) => this.onClick(e));
    }

    @HostListener('click', ['$event'])
    onClick(event: Event) {

        if (this.dataSource.sort.key === this.sortKey) {
            if (this.sortAscVal === false && this.sortDescVal === false) {
                this.sortAscVal = true;
            } else if (this.sortAscVal === true) {
                this.sortAscVal = false;
                this.sortDescVal = true;
            } else {
                this.sortAscVal = true;
                this.sortDescVal = false;
            }
        }

        this.dataSource.sortChange.next({
            key: this.sortKey,
            sortOrder: this.sortAscVal ? 'asc' : 'desc'
        });

        event.preventDefault();
        event.stopPropagation();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.dataSource.currentValue) {
            const dataSource = changes.dataSource.currentValue as ServerDataSource<any, FilterCriteria>;
            const update = sortInfo => {
                if (sortInfo.key === this.sortKey) {
                    this.sortAscVal = sortInfo.sortOrder === 'asc';
                    this.sortDescVal = !this.sortAscVal;
                } else {
                    this.sortAscVal = this.sortDescVal = false;
                }
            };

            dataSource.sortChange.subscribe(update);

            update(dataSource.sort);
        }
    }

}
