import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALUE_ACCESSOR,
    Validators
} from '@angular/forms';
import CustomValidators from '../../../../../utils/custom-validators';

export interface HeaderLink {
    link: string;
    linkText: string;
    buttonStyle: string;
    editing?: boolean;
}

@Component({
    selector: 'app-custom-header-link',
    templateUrl: './custom-header-link.component.html',
    styleUrls: ['./custom-header-link.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomHeaderLinkComponent),
            multi: true,
        },
    ],
})
export class CustomHeaderLinkComponent implements ControlValueAccessor, OnInit {
    headerLinks: HeaderLink[] = [];
    linkNameError = false;
    linkUrlError = false;
    headerLinkForm = new FormGroup({
        linkHeader: new FormControl('',
            {validators: [Validators.required, Validators.pattern(CustomValidators.URL_REGEXP)]}),
        linkTextHeader: new FormControl('',
            {validators: [Validators.required, Validators.maxLength(80), Validators.pattern(/^[0-9a-zA-Z.,!? \-]*$/)]}),
        buttonStyle: new FormControl('primary', {validators: [Validators.required]})
    });
    isAddLinkButtonEnabled = true;
    @Input() link: HeaderLink = {
        link: '',
        linkText: '',
        buttonStyle: 'primary',
        editing: false
    };
    constructor() {
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return control.invalid && control.touched;
    }

    ngOnInit() {
        this.headerLinkForm.valueChanges.subscribe(() => {
            this.updateAddLinkButton();
        });
        this.updateAddLinkButton();
        if (this.link) {
            this.headerLinkForm.setValue({
                linkHeader: this.link.link,
                linkTextHeader: this.link.linkText,
                buttonStyle: this.link.buttonStyle.toLowerCase()
            });
        }
    }

    private onChange: (value: any) => void = () => {
    }

    private onTouched: () => void = () => {
    }

    writeValue(value: any) {
        if (value && Array.isArray(value)) {
            this.headerLinks = value;
        } else {
            this.headerLinks = [];
        }
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.headerLinkForm.disable();
        } else {
            this.headerLinkForm.enable();
        }
    }

    addHeaderLink() {
        if (this.headerLinkForm.valid && !this.linkNameError && !this.linkUrlError) {
            const newHeaderLink: HeaderLink = {
                link: this.headerLinkForm.get('linkHeader').value,
                linkText: this.headerLinkForm.get('linkTextHeader').value,
                buttonStyle: this.headerLinkForm.get('buttonStyle').value
            };

            this.headerLinks.push(newHeaderLink);
            this.updateCustomHeaderLink();
            this.headerLinkForm.reset({buttonStyle: 'primary'});
            this.updateAddLinkButton();
        }
    }

    editLink(link: HeaderLink) {
        const text = this.headerLinkForm.get('linkTextHeader');
        const url = this.headerLinkForm.get('linkHeader');
        const style = this.headerLinkForm.get('buttonStyle');
        if (text?.value && !text.errors) {
            link.linkText = text.value;
        }
        if (url?.value && !url.errors) {
            link.link = url.value;
        }
        if (style?.value && !style.errors) {
            link.buttonStyle = style.value;
        }
        link.editing = false;
    }

    cancelEdit(link: HeaderLink) {
        link.editing = false;
    }

    updateCustomHeaderLink() {
        this.onChange(this.headerLinks);
        this.onTouched();
    }

    updateAddLinkButton() {
        this.isAddLinkButtonEnabled = !this.headerLinkForm.valid || this.linkNameError || this.linkUrlError;
    }
}
