import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConfigEnabled} from '../config-enabled';
import {capitalize, Period} from '../../base/period';

@Component({
    selector: 'app-security-options',
    templateUrl: './security-options.component.html',
    styleUrls: ['./security-options.component.css']
})
export class SecurityOptionsComponent implements OnInit {
    features = window.getToken().features;
    @Input() form: FormGroup;
    @Input() enabled: ConfigEnabled;
    expirationType = [];
    password = [];
    samlLogin = [];
    commentsWithRestrictions = [];

    constructor() {
    }

    ngOnInit(): void {
        const opt = this.enabled.security;
        this.expirationType = [
            ['OPTIONAL', 'Optional', null, opt.expiration.optional],
            ['REQUIRED', 'Required', null, opt.expiration.required],
            ['CUSTOM', 'Custom', null, opt.expiration.custom]
        ];
        this.password = [
            ['OPTIONAL', 'Optional', null, opt.password.optional],
            ['REQUIRED', 'Required', null, opt.password.required],
        ];
        this.samlLogin = [
            ['OPTIONAL', 'Optional', null, opt.samlLogin.optional],
            ['REQUIRED', 'Required', null, opt.samlLogin.required],
            ['DISABLED', 'Disabled', null, opt.samlLogin.disabled],
        ];
        this.commentsWithRestrictions = [
            ['off', 'Disable', null, opt.commentsWithRestrictions.off],
            ['on', 'Enable', null, opt.commentsWithRestrictions.on],
        ];
    }

    format(period: Period) {
        return period.count + ' ' + capitalize(period.unit);
    }
}
