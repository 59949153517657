import {AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
    selector: 'app-shared-page-preview',
    templateUrl: './shared-page-preview.component.html',
    styleUrls: ['./shared-page-preview.component.css']
})
export class SharedPagePreviewComponent implements OnInit {
    @Input() url: string;
    safeUrl: SafeResourceUrl;
    showLoader = false;

    constructor(private sanitizer: DomSanitizer) {}

    ngOnInit() {
        this.showLoader = true;
        this.updateSafeUrl();
    }

    updateSafeUrl() {
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
    }

    stopPreviewLoader() {
        this.showLoader = false;
    }
}

