import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Activity, ActivityFilterCriteria} from '../../base/activity';
import {Constants} from '../../utils/constants';
import {FormControl, FormGroup} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {ServerDataSource} from '../app-table.component';
import {ShareActionService} from '../../share-action.service';
import {Link, ShareType, ViewLink} from '../../base/link';
import {Observable, of, Subscription, zip} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import Utils from '../../utils/utils';
import {JiraService, Permissions} from '../../jira.service';
import {CsvData, ExportCsv} from '../exportCsv/exportCsv';
import {AppService} from '../../app.service';
import {LoaderService} from '../../loader.service';
import {DatePipe} from '@angular/common';
import PermissionUtils from '../../utils/permission-utils';
import {ActivatedRoute} from '@angular/router';
import {ActivityConfig, IssuesListFromJQL, JqlIssue} from './activityConfig';

export class ActivityParameters {
    level: 'SHARE' | 'ISSUE' | 'PROJECT' | 'GLOBAL';
    projectKey?: string;
    projectId?: string;
    issueId?: string;
    uuid?: string;
    name?: string;
    type?: ShareType;
}

@Component({
    selector: 'app-activity',
    templateUrl: './activity.component.html',
    styleUrls: ['./activity.component.css']
})
export class ActivityComponent implements OnInit, OnDestroy {
    private subscription: Subscription;
    config$: Observable<ActivityConfig>;
    @Input() parameters: ActivityParameters;
    @Input() viewType: string;
    @Input() link: ViewLink | Link;
    permissions: Permissions;

    issueKeys = new Map();

    filterVisible = false;
    showFilterInformation = false;

    sources = Constants.ACTIVITY_SOURCES;
    actions = Constants.ACTIVITY_ACTIONS;

    filterForm = new FormGroup({
        textSearch: new FormControl(''),
        source: new FormControl(''),
        action: new FormControl(''),
        userName: new FormControl(''),
        userEmail: new FormControl('')
    });
    filterValue;
    userFilterValue;
    emailFilterValue;
    queryFilterValue = '';
    private baseInstanceUrl: string;
    displayErrorCsvMessage = false;

    constructor(public source: ServerDataSource<Activity, ActivityFilterCriteria>,
                private app: AppService,
                private shareActionService: ShareActionService,
                private jira: JiraService,
                private loader: LoaderService,
                private datePipe: DatePipe,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.setupDatasource();

        this.config$ = zip(
            this.source.get(),
            Utils.combineParams(this.route),
            this.jira.getAddonProperty('permissions', '{"enabled": false}')
        ).pipe(
            switchMap(([list, params, permissionSchemeObj]) => {
                const issueIds = [...new Set(list.filter(el => !!el.issueId).map(el => el.issueId))].join(', ');
                if (!Utils.isEmpty(issueIds)) {
                    return zip(
                        Utils.require(this.fetchIssueKeys(issueIds),
                            'ActivityComponent', 'ngOnInit', `this.fetchIssueKeys(${issueIds})`),
                        Utils.require(this.jira.getBaseUrl(),
                            'ActivityComponent', 'ngOnInit', 'this.jira.getBaseUrl()'),
                        Utils.require(PermissionUtils.getPermissions(params, permissionSchemeObj.enabled),
                            'ActivityComponent', 'ngOnInit',
                            `PermissionUtils.getPermissions(${JSON.stringify(params)}, ${permissionSchemeObj.enabled})`)
                    );
                }
                return zip(
                    of({expand: '', issues: [], maxResults: 0, startAt: 0, total: 0}),
                    Utils.require(this.jira.getBaseUrl(),
                        'ActivityComponent', 'ngOnInit', 'this.jira.getBaseUrl()'),
                    Utils.require(PermissionUtils.getPermissions(params, permissionSchemeObj.enabled),
                        'ActivityComponent', 'ngOnInit',
                        `PermissionUtils.getPermissions(${JSON.stringify(params)}, ${permissionSchemeObj.enabled})`)
                );
            }),
            map(([issuesList, baseUrl, permissions]) => {
                this.baseInstanceUrl = baseUrl;
                return {
                    issuesList,
                    permissions,
                };
            })
        );


        this.subscription = this.config$.subscribe((result) => {
            this.permissions = result.permissions;
            if (result.issuesList.issues) {
                result.issuesList.issues.forEach((el => {
                    this.issueKeys.set('' + el.id, el.key);
                }));
            }
        });

        this.filterForm.valueChanges.subscribe(it => {
            if (!this.filterValue || this.filterFormSelectValuesChanged(it)) {
                this.source.updateFilter({
                    projectKey: this.getProjectKey(),
                    projectId: this.getProjectId(),
                    issueId: this.getIssueId(),
                    uuid: this.getUuid(),
                    source: it.source,
                    action: it.action
                });
            }
            this.filterValue = it;
        });
        this.filterVisible = this.isGlobalOrProject();

        if (this.isShareLevel()) {
            this.source.updateFilter( {
                projectKey: this.getProjectKey(),
                projectId: this.getProjectId(),
                issueId: this.getIssueId(),
                uuid: this.getUuid(),
            });
        }

        if (this.parameters.type !== 'ISSUE') {
            const value = this.getSource(this.parameters.type);
            if (this.sources.filter(i => i.value === value).length === 0) {
                this.sources.push({
                    text: value,
                    value
                });
            }
        }
    }

    displayExport(): boolean {
        return this.viewType === 'global-view' || this.viewType === 'project-view';
    }

    exportCSV(isShareLevel: boolean): void {
        this.displayErrorCsvMessage = false;
        const baseUrl = this.baseInstanceUrl;
        let searchQuery = this.filterForm.get('textSearch').value;
        searchQuery = searchQuery.split(' ').join('%20');
        const projectKey: string = this.getProjectKey();
        const shareName: string = this.getShareName();
        const uuid: string = this.getUuid();
        const header: string[] = [
            ...projectKey === '' ? ['Project Key'] : [],
            'Project ID',
            'Link',
            'Source',
            'Issue ID',
            'Action',
            'Share UUID',
            'User',
            'Email',
            'Ip address',
            'Date'
        ];
        this.loader.fullscreen(true);
        getAllActivitiesArray(this.app).then(sharesArray => {
            if (sharesArray.length === 0) {
                this.loader.fullscreen(false);
                this.displayErrorCsvMessage = true;
                return false;
            }
            const rows = getRows(sharesArray);
            const csvData: CsvData = {rows, header};
            downloadFile(csvData);
            this.loader.fullscreen(false);
        });

        function getAllActivitiesArray(app: AppService): Promise<Array<Activity>> {
            return new Promise(resolve => {
                const query = {projectKey, uuid, userQuery: searchQuery};
                app.getAllActivity(query, isShareLevel).then(elements => {
                    let activitiesArray: Array<Activity> = [];
                    for (const array of elements) {
                        activitiesArray = activitiesArray.concat(array);
                    }
                    resolve(activitiesArray);
                });
            });
        }

        const datePipe = this.datePipe;

        function getRows(activitiesArray: Activity[]): string[][] {
            const rows: string[][] = [];
            for (const activity of activitiesArray) {
                const row: string[] = [
                    ...projectKey === '' ? [activity.projectKey] : [],
                    activity.projectId,
                    activity.issueKey ? baseUrl + '/browse/' + activity.issueKey : '',
                    activity.source,
                    activity.issueId,
                    activity.action.toUpperCase(),
                    activity.uuid,
                    activity.user ? activity.user.firstName + ' ' + activity.user.lastName : activity.anonymousUserName
                        ? activity.anonymousUserName : '',
                    activity.user ? activity.user.email : activity.anonymousUserEmail ? activity.anonymousUserEmail : '',
                    activity.ip,
                    Utils.toIsoDate(activity.created, datePipe)
                ];
                rows.push(row);
            }
            return rows;
        }

        function downloadFile(csvData: CsvData): void {
            if (uuid) {
                ExportCsv.exportToCsv('project_' + projectKey + '_share_' + shareName + '_activity-list', csvData);
            } else if (projectKey) {
                ExportCsv.exportToCsv('project_' + projectKey + '_activity-list', csvData);
            } else {
                ExportCsv.exportToCsv('global_activity_list', csvData);
            }
        }
    }

    ngOnDestroy() {
        if (!!this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    hasSourceLink(activity: Activity): boolean {
        return !!activity.attachmentId
            || !!activity.commentId;
    }

    isLinkable(activity: Activity): boolean {
        return activity.action !== 'Deleted';
    }

    getSourceLink(activity: Activity): string {
        let href = environment.host;
        if (activity.attachmentId) {
            href += `/rest/api/3/attachment/content/${activity.attachmentId}`;
        } else if (activity.commentId) {
            href += `/browse/${this.getIssueKey(activity.issueId)}?focusedCommentId=${activity.commentId}`;
        }
        return href;
    }

    filterByUserName(user) {
        if (user !== this.userFilterValue) {
            this.userFilterValue = user;
            this.filterTable();
        }
    }

    filterByUserEmail(email) {
        if (email !== this.emailFilterValue) {
            this.emailFilterValue = email;
            this.filterTable();
        }
    }

    filter(queryValue, projectKey) {
        if (queryValue !== this.queryFilterValue) {
            this.queryFilterValue = queryValue;
            const query: string = this.filterForm.value.textSearch;
            projectKey = !!projectKey ? projectKey : '';
            this.source.updateFilter({projectKey, query});
        }
    }

    filterTable() {
        const it = this.filterForm.value;

        this.source.updateFilter({
            projectKey: this.getProjectKey(),
            issueId: this.getIssueId(),
            uuid: this.getUuid(),
            source: it.source,
            action: it.action,
            userName: it.userName,
            userEmail: it.userEmail
        });
    }

    showLink() {
        return this.isGlobalOrProject();
    }

    showShareEdition(): boolean {
        return this.isNotShare();
    }

    edit(activity: Activity) {
        const customData = {
            uuid: activity.uuid,
            readOnly: false,
            permissions: {
                canCreate: true,
                canEdit: true,
                canDelete: true,
                canSendEmail: true
            }
        };
        this.shareActionService.editShare(customData);
    }

    getIssueKey(issueId) {
        return this.issueKeys.has('' + issueId) ? this.issueKeys.get('' + issueId) : ' ';
    }

    public isShare() {
        return this.parameters.level === 'SHARE';
    }

    public isGlobal() {
        return this.parameters.level === 'GLOBAL';
    }

    public isGlobalOrProject() {
        return this.isGlobal()
            || this.parameters.level === 'PROJECT';
    }

    public isShareLevel() {
        return this.parameters.level === 'SHARE';
    }

    public displayParentSource(el: Activity) {
        return el.issueId && el.type !== 'ISSUE';
    }

    private getSource(type: ShareType) {
        switch (type) {
            case 'BOARD':
                return 'Board';
            case 'JQL':
                return 'Filter';
            case 'ROADMAP':
                return 'Roadmap';
            default:
                return '';
        }
    }

    private fetchIssueKeys(issueIds): Observable<IssuesListFromJQL> {
        const jql = `id%20in%20(${issueIds})`;
        return this.jira.searchForIssues(jql);
    }

    private filterFormSelectValuesChanged(value): boolean {
        return !this.filterValue ||
            (this.filterValue.source !== value.source) ||
            (this.filterValue.action !== value.action);
    }

    private setupDatasource() {
        this.source.reload({
            endPoint: '/api/share/activity',
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: this.getLimit(),
            filter: {
                projectId: this.getProjectId(),
                projectKey: this.getProjectKey(),
                issueId: this.getIssueId(),
                uuid: this.getUuid()
            }
        });
    }

    private getLimit() {
        return this.isGlobalOrProject() ? 20 : 10;
    }

    private isNotShare() {
        return this.parameters.level !== 'SHARE';
    }

    private getProjectKey() {
        return !!this.parameters.projectKey ? this.parameters.projectKey : '';
    }

    private getProjectId() {
        return !!this.parameters.projectId ? this.parameters.projectId : '';
    }

    private getIssueId() {
        return !!this.parameters.issueId ? this.parameters.issueId : '';
    }

    private getUuid() {
        return !!this.parameters.uuid ? this.parameters.uuid : '';
    }
    private getShareName() {
        return !!this.parameters.name ? this.parameters.name : '';
    }
}
