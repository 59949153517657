import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ShareFormComponent} from './share-form/share-form.component';
import {DeleteConfirmationComponent} from './delete-confirmation/delete-confirmation.component';
import {EmailFormComponent} from './share-form/email-form/email-form.component';
import {ProjectViewComponent} from './project-view/project-view.component';
import {ErrorComponent} from './error/error.component';
import {ShareListComponent} from './project-view/share-list/share-list.component';
import {GlobalViewComponent} from './global-view/global-view.component';
import {GlobalViewListComponent} from './global-view/global-view-list/global-view-list.component';
import {GlobalViewSettingsComponent} from './global-view/global-view-settings/global-view-settings.component';
import {ShareFormParentComponent} from './share-form/share-form-parent.component';
import {ProjectSchemaSettingsComponent} from './global-view/project-schema-settings/project-schema-settings.component';
import {ProjectSchemaFormComponent} from './project-schema-form/project-schema-form.component';
import {ProjectSchemaFormParentComponent} from './project-schema-form/project-schema-form-parent.component';
import {AccessCheckGuard} from './access-check.guard';
import {NoAccessIssueViewComponent} from './no-access-issue-view/no-access-issue-view.component';
import {PersonalViewComponent} from './personal-view/personal-view.component';
import {ShareFormAccessGuard} from './share-form-access.guard';
import {GlobalViewApiKeysComponent} from './global-view/global-view-api-keys/global-view-api-keys.component';
import {ApiKeyFormParentComponent} from './api-key-form/api-key-form-parent.component';
import {ApiKeyFormComponent} from './api-key-form/api-key-form.component';
import {GlobalViewAccessGuard} from './global-view-access-guard';
import {GlobalActivityComponent} from './global-view/global-activity/global-activity.component';
import {ProjectActivityComponent} from './project-view/project-activity/project-activity.component';
import {IssueViewComponent} from './issue-view/issue-view/issue-view.component';
import {ShareBulkEditPopupComponent} from './share-bulk-edit/share-bulk-edit-popup.component';
import {BoardCardLayoutComponent} from './global-view/board-card-layout/board-card-layout.component';
import {
    BoardCardLayoutListComponent,
} from './global-view/board-card-layout/board-card-layout-list/board-card-layout-list.component';
import {
    IssueLayoutCustomizationComponent
} from './global-view/issue-layout-customization/issue-layout-customization.component';
import {
    IssueLayoutListComponent
} from './global-view/issue-layout-customization/issue-layouts-list/issue-layout-list.component';
import {
    IssueLayoutTypesMappingComponent
} from './global-view/issue-layout-customization/issue-layout-types-mapping/issue-layout-types-mapping.component';
import {CreateShareComponent} from './create-share/create-share.component';


const routes: Routes = [
    {
        // Personal View
        // https://extshare.ngrok.io:38443/web/browse/list/user
        path: 'browse/list/user',
        component: PersonalViewComponent
    },

    {
        // Issue View
        // https://extshare.ngrok.io:38443/web/browse/list/issue/KEY-1
        path: 'browse/list/issue/:issueId',
        component: IssueViewComponent,
        canActivate: [AccessCheckGuard]
    },

    {
        // Project View
        // https://extshare.ngrok.io:38443/web/browse/list/project/10001
        path: 'browse/list/project/:projectId',
        component: ProjectViewComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list'
            },
            {
                path: 'list',
                component: ShareListComponent
            },
            {
                path: 'activity',
                component: ProjectActivityComponent
            }
        ]
    },
    {
        // Global View
        // https://extshare.ngrok.io:38443/web/browse/global/
        path: 'browse/global-view',
        component: GlobalViewComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'list'
            },
            {
                path: 'list',
                component: GlobalViewListComponent,
                canActivate: [GlobalViewAccessGuard]
            },
            {
                path: 'activity',
                component: GlobalActivityComponent,
                canActivate: [GlobalViewAccessGuard]
            },
            {
                path: 'settings',
                component: GlobalViewSettingsComponent
            },
            {
                path: 'project-schema-settings',
                component: ProjectSchemaSettingsComponent
            },
            {
                path: 'api-keys',
                component: GlobalViewApiKeysComponent
            },
            {
                path: 'board-card-layout-list',
                component: BoardCardLayoutListComponent
            },
            {
                path: 'board-card-layout-list/board-card-layout-editor/:id/:type',
                component: BoardCardLayoutComponent
            },
            {
                path: 'issue-layout-list/issue-layout-editor/:id/:type',
                component: IssueLayoutCustomizationComponent
            },
            {
                path: 'issue-layout-list',
                component: IssueLayoutListComponent
            },
            {
                path: 'issue-layout-list/issue-layout-types-mapping',
                component: IssueLayoutTypesMappingComponent
            },
        ]
    },
    {
        path: 'share',
        component: ShareFormParentComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'item'
            },
            {
                path: 'item',
                component: ShareFormComponent,
                canActivate: [ShareFormAccessGuard]
            },
            {
                path: 'mail',
                component: EmailFormComponent
            },
            {
                path: 'bulk-edit',
                component: ShareBulkEditPopupComponent,
            },
        ]
    },
    {
        path: 'share-type',
        component: CreateShareComponent
    },
    {
        path: 'schema',
        component: ProjectSchemaFormParentComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'item'
            },
            {
                path: 'item',
                component: ProjectSchemaFormComponent,
            }
        ]
    },
    {
        path: 'api-key',
        component: ApiKeyFormParentComponent,
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'item'
            },
            {
                path: 'item',
                component: ApiKeyFormComponent,
            }
        ]
    },
    {
        path: 'confirm-delete',
        component: DeleteConfirmationComponent
    },
    {
        path: 'no-access-issue-view',
        component: NoAccessIssueViewComponent
    },
    {
        path: 'error',
        component: ErrorComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {enableTracing: false})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
