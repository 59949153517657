import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-radio',
    templateUrl: 'app-radio.component.html',
    styleUrls: ['./app-radio.component.css']
})
export class AppRadioComponent {

    @Input() formControlName: any;
    @Input() value: any;
    @Input() label: string;
    @Input() disabled = false;

    isChecked = false;
    focused = false;

    update(value) {
        this.isChecked = this.value === value;
    }

    onChange() {
        if (!this.disabled && !this.isChecked) {
            this.setValue(this.value);
        }
    }

    setValue(value: any) {}

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onFocus() {
        this.focused = true;
    }

    onBlur() {
        this.focused = false;
    }
}
