import {Component} from '@angular/core';

@Component({
    selector: 'app-system-field-icon',
    template: '<svg role="presentation" width="24" height="24" viewBox="0 0 24 24" ' +
        'focusable="false"><path d="M5.928 6.917l4.81-2.846c.727-.429 1.8-.427 ' +
        '2.523 0l4.811 2.846c.726.43 1.261 1.382 1.261 2.238v5.69c0 .859-.537 ' +
        '1.81-1.26 2.238L13.26 19.93c-.726.429-1.799.427-2.522 0l-4.811-2.846c' +
        '-.726-.43-1.261-1.382-1.261-2.238v-5.69c0-.859.537-1.81 1.26-2.238zm6' +
        '.408-1.204c-.152-.09-.52-.09-.672 0L6.853 8.558c-.152.09-.336.416-.3' +
        '36.597v5.69c0 .18.183.507.336.597l4.81 2.845c.153.09.52.09.673 0l4.81' +
        '1-2.845c.152-.09.336-.416.336-.597v-5.69c0-.18-.183-.507-.336-.597l-4' +
        '.81-2.845zM12 15.667a3.667 3.667 0 1 1 0-7.334 3.667 3.667 0 0 1 0 7' +
        '.334zm0-1.834a1.833 1.833 0 1 0 0-3.666 1.833 1.833 0 0 0 0 3.666z" ' +
        'fill="currentColor" fill-rule="evenodd">' +
        '</path>' +
        '</svg>'
})
export class SystemFieldIconComponent {
}
