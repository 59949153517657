import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({name: 'safeJson'})
export class SafeJsonPipe implements PipeTransform {

    private ESCAPES: { [key: string]: string } = {
        '<': '&lt;',
        '>': '&gt;',
        ' ': '&nbsp;',
        '\n': '<br/>'
    };

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: any): SafeHtml {
        try {
            value = JSON.parse(value);
        } catch (e) {
            return 'Invalid JSON';
        }
        const formattedJson = JSON.stringify(value, null, 2);
        const withBreaks = formattedJson.replace(/[<>\n ]/g, match => this.ESCAPES[match] || match);
        return this.sanitizer.bypassSecurityTrustHtml(withBreaks);
    }
}
