import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-save-indicator',
  templateUrl: './save-indicator.component.html',
  styleUrls: ['./save-indicator.component.css']
})
export class SaveIndicatorComponent {
    @Input() saveIndicator: boolean;
    @Input() errorSaveIndicator: boolean;
    @Input() saveMark: boolean;
}
