import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {IconsModule} from '../icons/icons.module';
import {PersonalViewComponent} from './personal-view.component';

@NgModule({
    declarations: [
        PersonalViewComponent
    ],
    imports: [
        CommonModule,
        BaseModule,
        BrowserModule,
        FormsModule,
        IconsModule,
        ReactiveFormsModule
    ],
    exports: [
        PersonalViewComponent
    ]
})
export class PersonalViewModule {
}
