import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EmailContent, EmailSendResponse} from '../email-form/email-content';
import Utils from '../utils/utils';

@Injectable({providedIn: 'root'})
export class ShareService {

    public static getOptions(operationName: string) {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'X-Operation-Name': operationName,
            })
        };
    }

    constructor(private http: HttpClient) {
    }

    sendMail(email: EmailContent): Observable<EmailSendResponse> {
        const url = `/api/mail`;
        const body = email;
        const options = ShareService.getOptions('Send Mail');
        return this.http.post<any>(url, body, options);
    }

    getMail(uuid: string,
            title: string,
            sender: string) {
        let url = `/api/mail?uuid=${uuid}`;
        if (!Utils.isEmpty(title)) {
            url += `&title=${encodeURIComponent(title)}`;
        }
        if (!Utils.isEmpty(sender)) {
            url += `&sender=${encodeURIComponent(sender)}`;
        }
        const options = ShareService.getOptions('Get mail data');
        return this.http.get<any>(url, options);
    }
}
