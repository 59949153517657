export class Recipient {
    type: RecipientType;
    email: string;
}

export class RecipientChangeEvent {
    item: Recipient;
    editedRecord?: Recipient;
}

export enum RecipientType {
    TO = 'TO',
    CC = 'CC',
    BCC = 'BCC'
}
