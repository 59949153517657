import {Component} from '@angular/core';
import {ActivityParameters} from '../../components/activity/activity.component';

@Component({
    selector: 'app-global-activity',
    templateUrl: './global-activity.component.html'
})
export class GlobalActivityComponent {

    getActivityParameters(): ActivityParameters {
        return {
            level: 'GLOBAL'
        };
    }
}
