import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24" role="presentation">' +
    // tslint:disable-next-line:max-line-length
    '   <path fill="currentColor" d="M6.735 12.322a1 1 0 0 0-1.47 1.356l3.612 3.919c.537.526 1.337.526 1.834.03l.364-.359a2336 2336 0 0 0 3.939-3.883l.04-.04a493 493 0 0 0 3.658-3.643 1 1 0 0 0-1.424-1.404 518 518 0 0 1-3.64 3.625l-.04.04a2049 2049 0 0 1-3.775 3.722z"></path>' +
    '   </path>' +
    '</svg>';

@Directive({
    selector: '[appCheckIcon]'
})
export class CheckIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
