import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-to-board-card-element',
    templateUrl: './to-board-card-element.component.html',
    styleUrls: ['./to-board-card-element.component.css']
})
export class ToBoardCardElementComponent {
    @Input() element;
}
