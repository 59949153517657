import {Component, OnInit} from '@angular/core';
import {AppService} from '../../../../app.service';
import {User} from '../../../../base/user';

@Component({
    selector: 'app-saml-manage-workspace',
    templateUrl: './saml-manage-workspace.component.html',
    styleUrls: ['./saml-manage-workspace.component.css']
})
export class SamlManageWorkspaceComponent implements OnInit {
    users: ListedUser[];
    displayedUsers: ListedUser[];
    filteredUsers: ListedUser[];
    selectAll = false;
    currentPage = 1;
    itemsPerPage = 10;
    private textToSearch = '';

    constructor(private app: AppService) { }

    ngOnInit() {
        this.app.getUsersInWorkspace().subscribe(listedUsers => {
            this.users = listedUsers.map(user => ({ user, selected: false }));
            this.updateDisplayedUsers();
        });
    }

    toggleSelectAll() {
        if (this.selectAll) {
            this.displayedUsers.forEach(user => user.selected = false);
            this.selectAll = false;
        } else {
            this.displayedUsers.forEach(user => user.selected = true);
            this.selectAll = true;
        }
    }

    checkIfAllSelected() {
        this.selectAll = this.users.every(user => user.selected);
    }

    hasSelectedUsers() {
        return this.users != null && this.users.some(user => user.selected);
    }

    removeSelectedUsers() {
        const userIds = this.users.filter(listedUser => listedUser.selected).map(listedUser => listedUser.user.id);
        this.app.deleteWorkspaceForUsers(userIds).subscribe(listedUsers => {
            this.users = listedUsers.map(user => ({ user, selected: false }));
            this.updateDisplayedUsers();
        });
    }

    updateDisplayedUsers() {
        const start = (this.currentPage - 1) * this.itemsPerPage;
        const end = start + this.itemsPerPage;
        this.filteredUsers = this.applyFilter();
        this.displayedUsers = this.applyFilter().slice(start, end);
    }

    private applyFilter() {
        return this.users.filter(listedUser => {
            const nameMatch = `${listedUser.user.firstName} ${listedUser.user.lastName}`
                .toLowerCase().includes(this.searchText.toLowerCase());
            const emailMatch = listedUser.user.email.toLowerCase().includes(this.searchText.toLowerCase());
            return nameMatch || emailMatch;
        });
    }

    totalPages() {
        const displayedCount = this.filteredUsers ? this.filteredUsers.length : 0;
        return Math.ceil(displayedCount / this.itemsPerPage);
    }

    previousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            if (this.filteredUsers.length > 0) {
                this.updateDisplayedUsers();
            }
        }
    }

    nextPage() {
        if (this.currentPage < this.totalPages()) {
            this.currentPage++;
            if (this.filteredUsers.length > 0) {
                this.updateDisplayedUsers();
            }
        }
    }

    get searchText() {
        return this.textToSearch;
    }

    set searchText(value: string) {
        this.textToSearch = value;
        this.updateDisplayedUsers();
    }

}

class ListedUser {
    user: User;
    selected: boolean;
}
