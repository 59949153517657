import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-share-form-preview-footer-tab',
  templateUrl: './share-form-preview-footer-tab.component.html',
  styleUrls: ['./share-form-preview-footer-tab.component.css']
})
export class ShareFormPreviewFooterTabComponent {
    @Input() icon: string;
    @Input() title: string;
    @Input() isSelected: boolean;
}
