import {AfterContentInit, Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import tippy from 'tippy.js';

@Directive({
    selector: '[appTooltip]',
})
export class TooltipComponentDirective implements AfterContentInit, OnDestroy, OnChanges {
    @Input() appTooltip: string;
    @Input() appTooltipOn = true;
    @Input() clickable = false;

    el: any;

    constructor(private ref: ElementRef) { }

    ngAfterContentInit(): void {
        this.initializeTooltip();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.appTooltip && !changes.appTooltip.firstChange && this.el) {
            this.el.setContent(changes.appTooltip.currentValue);
        }
    }

    initializeTooltip(): void {
        if (this.appTooltipOn && !!this.appTooltip) {
            this.ref.nativeElement.addEventListener('mouseenter', this.mouseEnter(this.ref.nativeElement, () => {
                if (!this.el) {
                    this.createTooltip();
                }
                this.el.show();
            }), true);

            this.ref.nativeElement.addEventListener('mouseout', this.mouseEnter(this.ref.nativeElement, () => {
                if (this.el) {
                    this.el.hide();
                }
            }), true);
        }

        if (this.appTooltipOn && this.clickable) {
            this.ref.nativeElement.addEventListener('click', () => this.toggleTooltip(), true);
        }
    }

    createTooltip() {
        this.el = tippy(this.ref.nativeElement, {
            animation: 'fade',
            arrow: true,
            content: this.appTooltip,
            trigger: 'manual'
        });
    }

    ngOnDestroy(): void {
        if (this.el) {
            this.el.destroy();
            this.el = null;
        }
    }

    mouseEnter(element, fn) {
        return (evt) => {
            const relTarget = evt.relatedTarget;
            if (element === relTarget || this.isAChildOf(element, relTarget)) {
                return;
            }
            fn.call(evt);
        };
    }

    isAChildOf(parent, child): boolean {
        if (parent === child) {
            return false;
        }
        while (child && child !== parent) {
            child = child.parentNode;
        }
        return child === parent;
    }

    toggleTooltip() {
        if (!this.el) {
            this.createTooltip();
        }
        if (this.el.state.isVisible) {
            this.el.hide();
        } else {
            this.el.show();
        }
    }
}
