import {AfterViewInit, Directive, ElementRef, Optional} from '@angular/core';
import {NgControl} from '@angular/forms';

@Directive({
    selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterViewInit {

    constructor(private el: ElementRef,
                @Optional() private ng: NgControl) {
    }

    ngAfterViewInit() {
        window.setTimeout(() => {
            const focusable = (this?.ng?.valueAccessor as any)?.isFocusable;
            (focusable || this.el).nativeElement.focus();
        });
    }
}
