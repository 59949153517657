import {Component, forwardRef, Input, OnInit, Optional} from '@angular/core';
import {
    AbstractControl,
    ControlContainer,
    ControlValueAccessor,
    FormGroup,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import Utils from '../../../utils/utils';

@Component({
    selector: 'app-share-editor-text-area',
    templateUrl: './share-editor-text-area.component.html',
    styleUrls: ['./share-editor-text-area.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ShareEditorTextAreaComponent),
            multi: true
        }
    ]
})
export class ShareEditorTextAreaComponent implements ControlValueAccessor, OnInit {

    constructor(@Optional() private controlContainer: ControlContainer) {
    }

    @Input() label: string;
    @Input() info: string;
    @Input() formControlName: string;
    @Input() required = false;
    @Input() warningMessages: { [key: string]: string };

    value = '';
    disabled = false;
    warningMessage = '';

    onChange = (value: string) => {
    }
    onTouched = () => {
    }

    ngOnInit() {
        const control = this.getControl();
        if (control) {
            control.statusChanges.subscribe(() => {
                this.updateWarningMessage(control);
            });
            this.updateWarningMessage(control);
        }
    }

    writeValue(value: string): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    handleInput(event: Event) {
        const textarea = event.target as HTMLTextAreaElement;
        this.value = textarea.value;
        this.onChange(this.value);
        this.onTouched();
    }

    shouldDisplayWarning(): boolean {
        return !!this.warningMessage;
    }

    private getControl(): AbstractControl {
        const formGroup = this.controlContainer.control as FormGroup;
        return formGroup.get(this.formControlName);
    }

    private updateWarningMessage(control: AbstractControl): void {
        this.warningMessage = Utils.updateWarnMessage(control, this.warningMessages);
    }
}
