import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {from, Observable} from 'rxjs';
import {environment} from '../../environments/environment';


@Injectable()
export class ServerInterceptor implements HttpInterceptor {

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (environment.server) {

            return from(new Promise<HttpEvent<any>>((resolve, reject) => {

                AP.request({
                    url: request.urlWithParams,
                    type: request.method,
                    data: request.body,

                    success: (response: string) => {
                        resolve(new HttpResponse<any>({
                            body: response,
                            status: 200,
                            statusText: 'OK'
                        }));
                    },
                    error: (resp: any, statusText: string, errorThrown: any) => {
                        // reject({resp, statusText, errorThrown});
                        const headers = resp.headers || {};

                        reject(new HttpResponse<any>({
                            body: resp.body,
                            status: resp.status,
                            headers: new HttpHeaders({
                                ...headers
                            }),
                            statusText
                        }));

                    },
                });
            }));
        }

        return next.handle(request.clone({
            url: request.url
        }));
    }
}
