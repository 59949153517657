import {Component, Input, OnInit} from '@angular/core';
import {ViewItem} from '../../view-item';
import {SearchFunction} from '../better-select/better-select.component';
import {of} from 'rxjs';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-card-layout-configuration',
    templateUrl: './card-layout-configuration.component.html',
    styleUrls: ['./card-layout-configuration.component.css']
})
export class CardLayoutConfigurationComponent {
    @Input() form: FormGroup;
    @Input() layouts: ViewItem[];
    cellValuePick: SearchFunction;

    constructor() {
        this.cellValuePick = this.cellValuePickFoo.bind(this);
    }

    resetLayout() {
        this.form.get('cardLayoutId').setValue({id: '', text: ''});
    }

    cellValuePickFoo: SearchFunction = (term) => {
        const filtered = this.layouts.filter(item => item.text.toLowerCase().includes(term.toLowerCase()));
        const data = {
            results: filtered,
            pagination: {more: false}
        };

        return of(data);
    }

}
