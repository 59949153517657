import {Component, Input} from '@angular/core';
import {Audit} from '../../base/config';

@Component({
    selector: 'app-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.css']
})
export class AuditComponent {

    @Input() audit: Audit;

    constructor() {
    }
}
