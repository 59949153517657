import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {ProjectViewComponent} from './project-view.component';
import {ShareListComponent} from './share-list/share-list.component';
import {RouterModule} from '@angular/router';
import {IconsModule} from '../icons/icons.module';
import {ProjectActivityComponent} from './project-activity/project-activity.component';
import {CreateShareComponent} from '../create-share/create-share.component';
import {CreateShareModule} from '../create-share/create-share.module';

@NgModule({
    declarations: [
        ProjectViewComponent,
        ShareListComponent,
        ProjectActivityComponent,
        CreateShareComponent
    ],
    imports: [
        CommonModule,
        BaseModule,
        BrowserModule,
        FormsModule,
        IconsModule,
        ReactiveFormsModule,
        RouterModule,
        CreateShareModule
    ],
    exports: [
        ProjectViewComponent,
        CreateShareComponent
    ]
})
export class ProjectViewModule {
}
