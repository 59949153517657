import {Claims} from './claims';

export async function initJwt(production: boolean = true) {
    if (window.getToken) {
        return;
    }

    let jwtToken = await fetchJwt();
    if (!jwtToken.token) {
        try {
            const sessionToken = sessionStorage.getItem('jwt-token');
            atlas.log('[App Data] data from session storage', sessionStorage);
            jwtToken = sessionToken != null ? JSON.parse(sessionToken) : null;
        } catch (e) {
            console.log(e);
        }
    } else {
        try {
            sessionStorage.setItem('jwt-token', JSON.stringify(jwtToken));
        } catch (e) {
            console.log(e);
        }
    }

    if (!jwtToken.token) {
        window.location.replace('/static-web/assets/token-error.html?v=latest');
        return;
    }

    const claims = parseClaims(jwtToken.token);

    window.getToken = () => {
        return {token: jwtToken.token, features: jwtToken.features, claims};
    };

    async function fetchJwt() {
        const customData = await getCustomData();
        if (customData && customData.jwtToken) {
            atlas.log('[App Data] Found custom data in Dialog', customData);
            return {token: customData.jwtToken, features: customData.features};
        }

        const startUrl = window.location.href;
        const body = JSON.stringify({url: startUrl});

        const response = await fetch('/api/atlassian-connect-app', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body,
        });

        const json = await response.json();
        atlas.log('[App Data] data from server', json);

        return json;
    }

    async function getCustomData(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (AP.dialog) {
                AP.dialog.getCustomData(data => {
                    resolve(data);
                });
            } else {
                resolve();
            }
        });
    }

    function parseClaims(token): Claims {
        return parseJwt(token);

        function parseJwt(jwt): any {
            try {
                return JSON.parse(atob(jwt.split('.')[1]));
            } catch (e) {
                return null;
            }
        }
    }
}

