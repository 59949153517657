import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24" role="presentation">' +
    // tslint:disable-next-line:max-line-length
    '   <g fill="currentColor" fill-rule="evenodd"><path d="M3 7h3v2H3zm0 8h11v2H3zm7-8h11v2H10zm8 8h3v2h-3z"></path><path d="M11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0M9 8a1 1 0 1 0-2 0 1 1 0 0 0 2 0m10 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-2 0a1 1 0 1 0-2 0 1 1 0 0 0 2 0"></path></g>' +
    '   </path>' +
    '</svg>';

@Directive({
    selector: '[appPreferencesIcon]'
})
export class PreferencesIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
