import { Component, OnInit } from '@angular/core';
import { forkJoin, merge, Observable, of, zip } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { ServerDataSource } from '../../components/app-table.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { ShareActionService } from '../../share-action.service';
import { JiraService } from '../../jira.service';
import { map, switchMap } from 'rxjs/operators';
import Utils from '../../utils/utils';
import { Link, LinkFilterCriteria } from '../../base/link';
import { AppState, ItemService } from '../../base/item.service';
import { Column } from 'src/app/components/customize-columns/customize-columns.component';
import { CsvData, ExportCsv } from '../../components/exportCsv/exportCsv';
import { AppService } from '../../app.service';
import { AtlassianUser } from '../../atlassian-user';
import { LoaderService } from '../../loader.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-global-view-list',
    templateUrl: './global-view-list.component.html',
    styleUrls: ['./global-view-list.component.css']
})
export class GlobalViewListComponent implements OnInit {

    constructor(private http: HttpClient,
                private route: ActivatedRoute,
                private title: Title,
                private app: AppService,
                private shareActions: ShareActionService,
                private item: ItemService,
                private jira: JiraService,
                private loader: LoaderService,
                private datePipe: DatePipe) {
    }

    features = window.getToken().features;
    config$: Observable<AppState>;

    filterForm = new FormGroup({
        textSearch: new FormControl('status: active ')
    });

    additionalFilterForm = new FormGroup({
        createdByMe: new FormControl(false)
    });

    columnForm = new FormGroup({
        columns: new FormControl('')
    });

    columns = [
        new Column('share-type', 'Share type', true, 1),
        new Column('project-key', 'Project key', true, 2),
        new Column('issue-key', 'Issue key', true, 3),
        new Column('link-name', 'Link name', true, 4),
        new Column('created-by', 'Created by', true, 5),
        new Column('created', 'Created', true, 6),
        new Column('last-updated-by', 'Last updated by', true, 7),
        new Column('last-updated', 'Last updated', true, 8),
        new Column('opened', 'Opened', true, 9),
        new Column('password', 'Password', true, 10),
        new Column('expiration', 'Expiration', true, 11),
        new Column('link-status', 'Link status', true, 12),
        new Column('selected-users-emails', 'Emails', false, 13),
        new Column('selected-users-domains', 'Domains', false, 14),
        new Column('options', 'Options', true, 15),
        new Column('actions', 'Actions', true, 16)
    ];

    deleteAll = false;

    source = new ServerDataSource<Link, LinkFilterCriteria>(this.http);
    moreThanOneItemIsSelected = false;
    showFilterInformation = false;

    private textSearch: string;

    private baseInstanceUrl: string;

    displayErrorCsvMessage = false;
    header: string[] = [
        'Share type', 'Project key', 'Project ID', 'Issue key',
        'Issue ID', 'Issue', 'Share UUID', 'Link name', 'Share link url',
        'Created by', 'Created', 'Last updated by',
        'Last updated', 'Opened', 'Password', 'Expiration',
        'Link status', 'Emails', 'Domains'
    ];

    ngOnInit() {
        const refresh = this.jira.observeEvent('share-update');
        refresh.subscribe((data) => {
            this.source.refresh();
        });

        this.jira.getBaseUrl().subscribe(value => {
            this.baseInstanceUrl = value;
        });

        this.config$ = merge(refresh, of(undefined)).pipe(
            switchMap(() => {
                return zip(
                    Utils.require(Utils.combineParams(this.route.parent),
                        'GlobalViewListComponent', 'ngOnInit', `Utils.combineParams(this.route.parent)`),
                    Utils.require(this.jira.getAddonProperty('permissions', '{"enabled": false}'),
                        'GlobalViewListComponent', 'ngOnInit', 'this.jira.getAddonProperty("permissions")')
                );
            }),
            switchMap(([params, permissionSchemeObj]) => {
                return Utils.require(Utils.getAppState(params, permissionSchemeObj.enabled),
                    'GlobalViewListComponent', 'ngOnInit', `Utils.getAppState(${JSON.stringify(params)}, ${permissionSchemeObj.enabled})`);
            })
        );

        this.source.reload({
            endPoint: `/api/share`,
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: 20,
            filter: {query: this.filterForm.get('textSearch').value},
            headers: new HttpHeaders({
                'X-Operation-Name': 'Fetching shares',
            })
        });

        this.filterForm.get('textSearch').valueChanges.subscribe(value => {
            if (value !== 'created_by: me' && this.additionalFilterForm.get('createdByMe').value) {
                this.textSearch = value;
                this.additionalFilterForm.get('createdByMe').setValue(false);
            }
        });

        this.additionalFilterForm.get('createdByMe').valueChanges.subscribe(value => {
            let textSearchToSet = 'status: active';
            if (this.textSearch) {
                textSearchToSet = this.textSearch;
                this.textSearch = undefined;
            } else if (value) {
                textSearchToSet = 'created_by: me';
            }
            this.filterForm.get('textSearch').setValue(textSearchToSet);
            this.doFilter();
        });
    }

    updateMoreThanOneItemSelected(value: boolean) {
        this.moreThanOneItemIsSelected = value;
    }

    exportCSV(): void {
        this.displayErrorCsvMessage = false;
        const header: string[] = this.header;
        const baseUrl: string = this.baseInstanceUrl;

        let searchQuery = this.filterForm.get('textSearch').value;
        searchQuery = searchQuery.split(' ').join('%20');

        this.loader.fullscreen(true);

        getAllSharesArray(this.app).then(sharesArray => {
            if (sharesArray.length === 0) {
                this.loader.fullscreen(false);
                this.displayErrorCsvMessage = true;
                return false;
            }
            of(sharesArray).pipe(
                switchMap((array) => {
                    const users$: Observable<AtlassianUser>[] = [];
                    const usersIds = new Set(array.map(el => el.createdBy)
                        .concat(array.filter(id => id.updatedBy !== null)
                            .map(el => el.updatedBy)));

                    usersIds.forEach(userId => {
                        const user$: Observable<AtlassianUser> = Utils.require(this.jira.requestUser(userId),
                            'GlobalViewListComponent', 'exportCSV', `this.jira.requestUser(${userId})`);
                        users$.push(user$);
                    });

                    return zip(of(array), forkJoin(users$));
                }),
                map(([array, user]) => {
                    const users: Map<string, string> = new Map(user.map(i => [i.accountId, i.displayName]));
                    const modifiedArray: Link[] = array.map(el => {
                        el.createdBy = users.get(el.createdBy);
                        el.updatedBy = users.get(el.updatedBy);
                        return el;
                    });
                    return modifiedArray;
                })
            ).subscribe((a) => {
                const rows = getRows(a);
                const csvData: CsvData = {rows, header};
                ExportCsv.exportToCsv('global_share_list', csvData);
                this.loader.fullscreen(false);
            });
        });

        function getAllSharesArray(app: AppService): Promise<Array<Link>> {
            return new Promise(resolve => {
                const query = {projectId: '', userQuery: searchQuery};
                app.getAllShares(query).then(elements => {
                    let sharesArray: Array<Link> = [];
                    for (const array of elements) {
                        sharesArray = sharesArray.concat(array);
                    }
                    resolve(sharesArray);
                });
            });
        }
        const datePipe = this.datePipe;
        function getRows(sharesArray: Link[]): string[][] {
            const rows: string[][] = [];
            for (const data of sharesArray) {
                const row: string[] = [
                    data.type,
                    data.projectKey,
                    data.projectId,
                    data.issueKey,
                    data.issueId,
                    data.issueKey ? baseUrl + '/browse/' + data.issueKey : '',
                    data.uuid,
                    data.name,
                    data.url,
                    data.createdBy,
                    Utils.toIsoDate(data.created, datePipe),
                    data.updatedBy,
                    Utils.toIsoDate(data.updated, datePipe),
                    data.opened.toString(),
                    data.password,
                    Utils.toIsoDate(data.expiration, datePipe),
                    data.status.statusType,
                    data.selectedUsersConfig ? data.selectedUsersConfig.emails : '',
                    data.selectedUsersConfig ? data.selectedUsersConfig.domains : ''
                ];
                rows.push(row);
            }
            return rows;
        }
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    doDeleteAll(elements: any[]) {
        const toDelete = elements.filter(it => it._delete) as Link[];
        this.shareActions.deleteMultiple(toDelete);
    }

    doEditAll(elements: any[], query: string) {
        const toEdit = elements.filter(it => it._delete) as Link[];
        this.shareActions.bulkEdit(toEdit, { query });
    }

    filter(event: KeyboardEvent | MouseEvent) {
        if ('code' in event) {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                this.doFilter();
            }
        } else {
            const query: string = this.filterForm.value.textSearch;
            this.source.updateFilter({query});
        }
    }

    private doFilter() {
        const query: string = this.filterForm.value.textSearch;
        this.source.updateFilter({query});
    }
}
