import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24" role="presentation">' +
    // tslint:disable-next-line:max-line-length
    '   <path fill="currentColor" fill-rule="evenodd" d="M14.16 4.06a1 1 0 0 0-1.27.62L8.06 18.73a1 1 0 0 0 1.89.65l4.83-14.04a1 1 0 0 0-.62-1.27m-6.14 8.2-2.58-2.5 2.8-2.72a1 1 0 1 0-1.39-1.44L3.31 9.04a1 1 0 0 0 0 1.44l3.32 3.22a1 1 0 1 0 1.39-1.44m12.22 1.57-3.32-3.22a1 1 0 1 0-1.39 1.44l2.58 2.5-2.8 2.72a1 1 0 1 0 1.39 1.44l3.54-3.43a1 1 0 0 0 0-1.44"></path>' +
    '   </path>' +
    '</svg>';

@Directive({
    selector: '[appCodeIcon]'
})
export class CodeIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
