import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {SearchFunction} from '../../../components/better-select/better-select.component';

@Component({
    selector: 'app-custom-board-config',
    templateUrl: './custom-board-config.component.html',
    styleUrls: ['./custom-board-config.component.css']
})
export class CustomBoardConfigComponent implements OnInit {

    @Input() form: FormGroup;
    @Input() statusPicker: SearchFunction;

    constructor(private fb: FormBuilder) {}

    ngOnInit(): void {
    }

    get customBoardConfig(): FormGroup {
        return this.form.get('customBoardConfig') as FormGroup;
    }

    get columnsConfig(): FormArray {
        return this.customBoardConfig.get('columnsConfig') as FormArray;
    }

    addColumn() {
        const columnGroup = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(30)]],
            statuses: [[], Validators.required]
        });

        this.columnsConfig.push(columnGroup);
    }

    removeColumn(index: number) {
        this.columnsConfig.removeAt(index);
    }

    shouldDisplayError(control: AbstractControl): boolean {
        return control.invalid;
    }

    clearConfig() {
        this.customBoardConfig.reset();
        while (this.columnsConfig.length) {
            this.columnsConfig.removeAt(0);
        }
    }
}
