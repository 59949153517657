import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {JiraService} from '../../jira.service';
import {ParsedJqlFromApi} from '../../ParsedJqlFromApi';

export function jqlValidator(jira: JiraService) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        const term = control.value;
        if (!term || term.trim().length === 0) {
            return of(null);
        }

        return jira.verifySingleJql(term).pipe(
            map((jqlVerification: ParsedJqlFromApi) => {
                return jqlVerification.errors ? { jqlError: jqlVerification.errors[0] } : null;
            }),
            catchError(() => of({ jqlError: 'JQL verification failed' }))
        );
    };
}
