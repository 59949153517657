import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Claims} from '../environments/claims';
import {Observable} from 'rxjs';
import {PeriodEnum} from './base/period';
import {DefaultConfigView, GlobalConfigView, ProjectConfigView} from './base/config';
import {ApiKeyConfigView, ApiKeyUsage} from './base/apikey.config';
import {Activity} from './base/activity';
import {Link} from './base/link';
import {User} from './base/user';
import {BoardCardConfigApi, BoardCardLayoutConfig, BoardCardLayoutRow} from './global-view/board-card-layout/boardCard';
import {IssueLayoutConfig} from './global-view/issue-layout-customization/issueLayout';
import {IssueLayout, LayoutFieldSchema} from './global-view/issue-layout-customization/types/issueLayout';
import {
    IssueTypesMapping,
    IssueTypesMappingResponse
} from './global-view/issue-layout-customization/issue-layout-types-mapping/issueTypesMapping';
import {ViewItem} from './view-item';

export interface MigrationResult {
    message: string;
    success: boolean;
}

export interface GlobalSettings {
    allowedIPs: string;
    expirationUnit?: PeriodEnum;
    expirationCount?: number;
    emailSettings: EmailSettings;
    customDomain: CustomDomain;
    customCsp: string;
    sameSiteCookieRestriction: string;
    emailTemplate: EmailTemplate;
    pageSettings: PageSettings;
    samlConfig: SamlConfig;
    integrations: Integrations;
}

export interface PageHeader {
    customHeader: string;
    color: string;
    colorDarkMode: string;
    backgroundColor: string;
    backgroundColorDarkMode: string;
    locationSelect: string;
    customHeaderLinks: any;
    hideHeaderLinks: boolean;
    showHeader: boolean;
}

export interface CustomBody {
    showBackgroundImage: boolean;
    backgroundColor: string;
    backgroundColorDarkMode: string;
    backgroundImage: string;
    backgroundImageDarkMode: string;
}

export interface PageFooter {
    customFooter: string;
    color: string;
    colorDarkMode: string;
    backgroundColor: string;
    backgroundColorDarkMode: string;
    customFooterLinks: any;
    showFooter: boolean;
}

export interface PageSettings {
    darkModeEnabled: boolean;
    pageHeader: PageHeader;
    customBody: CustomBody;
    pageFooter: PageFooter;
    showSubscribe: boolean;
    analyticsID: string;
    logo: Image;
    logoDark: Image;
    favicon: Image;
}

export interface Image {
    base64: string;
    mime: string;
}

export interface CustomDomain {
    enabled: boolean;
    domain: string;
    status?: UpdateCustomDomainResult;
}

export class UpdateCustomDomainResult {
    status?: 'ERROR' | 'INVALID' | 'MODIFIED' | 'NOT_MODIFIED';
}

export interface EmailSettings {
    custom: boolean;
    host: string;
    port: string;
    username: string;
    from?: string;
    personalName?: string;
    password: string;
    carrier: string;
    senderEmail: string;
}

export interface EmailTemplate {
    title: string;
    content: string;
    template: string;
}

export interface SamlConfig {
    samlEnabled: boolean;
    loginUrl: string;
    identifier: string;
    logoutUrl: string;
    certificate: string;
    workspace: string;
    identityProvider: string;
    defaultRelayState?: string;
    assertionConsumerUrl?: string;
    issuerId?: string;
}

export interface ActivityList {
    items: Activity[];
    totalCount: number;
}

export interface SharesList {
    items: Link[];
    totalCount: number;
}

export interface Integrations {
    smartCheckList: boolean;
    issueChecklist: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AppService {

    private static getOptions(operationName: string) {
        return {
            headers: new HttpHeaders({
                'X-Operation-Name': operationName,
                'Content-Type': 'application/json'
            })
        };
    }

    constructor(private http: HttpClient) {
    }

    getAllActivity(query: { projectKey, uuid, userQuery: string }, isShareLevel: boolean): Promise<Activity[]> {
        const activityLimit = 20000;
        return new Promise<Activity[]>((resolve) => {
            this.getAllActivityAggregated([], 0, activityLimit, isShareLevel, query, ((val: Activity[]) => {
                    resolve(val);
                }));
        });
    }

    getAllActivityAggregated(aggregated: Activity[][], page: number, activityLimit: number, isShareLevel: boolean,
                             query: { projectKey: string, uuid: string, userQuery: string }, cb): void {
        const offset: number = activityLimit * page;
        let url = '/api/share/activity?query=' + query.userQuery + '&limit=' + activityLimit + '&offset=' + offset;
        if (isShareLevel && query.uuid !== '') {
            url += '&uuid=' + query.uuid;
        } else if (!isShareLevel && query.projectKey !== '') {
            url += '&projectKey=' + query.projectKey;
        }
        this.http.get<ActivityList>(url)
            .subscribe(value => {
                aggregated.push(value.items);
                if (value.items.length === activityLimit) {
                    this.getAllActivityAggregated(aggregated, page + 1, activityLimit, isShareLevel, query, cb);
                } else {
                    cb(aggregated);
                }
            });
    }

    getAllShares(query: { projectId: string, userQuery: string }): Promise<Link[]> {
        const sharesLimit = 100;
        return new Promise<Link[]>((resolve) => {
            this.getAllSharesAggregated([], 0, sharesLimit, query, ((val: Link[]) => {
                    resolve(val);
                })
            );
        });
    }

    getAllSharesAggregated(aggregated: Link[][], page: number, sharesLimit: number,
                           query: { projectId: string, userQuery: string }, cb): void {
        const offset: number = sharesLimit * page;
        let url = '/api/share?query=' + query.userQuery + '&limit=' + sharesLimit + '&offset=' + offset;
        if (query.projectId !== '') {
            url += '&projectId=' + query.projectId;
        }
        this.http.get<SharesList>(url).subscribe(value => {
            aggregated.push(value.items);
            if (value.items.length === sharesLimit) {
                this.getAllSharesAggregated(aggregated, page + 1, sharesLimit, query, cb);
            } else {
                cb(aggregated);
            }
        });
    }

    migrate(): Observable<MigrationResult> {
        return this.http.post<MigrationResult>('/api/migrate', undefined, AppService.getOptions('Migrate'));
    }

    settings(): Observable<GlobalSettings> {
        return this.http.get<GlobalSettings>('/api/global-settings', AppService.getOptions('Get global settings'));
    }

    updateSettings(settings: GlobalSettings): Observable<GlobalSettings> {
        return this.http.put<GlobalSettings>('/api/global-settings', settings, AppService.getOptions('Update global settings'));
    }

    restoreDefaultEmailTemplate(): Observable<GlobalSettings> {
        return this.http.get<GlobalSettings>('/api/global-settings/template/default', AppService.getOptions('Get global settings'));
    }

    restoreDefaultPageSettings(): Observable<GlobalSettings> {
        return this.http.get<GlobalSettings>('/api/global-settings/page/default', AppService.getOptions('Get global settings'));
    }

    checkWorkspace(workspace: string): Observable<boolean> {
        return this.http.post<boolean>(
            '/api/workspace/validity?workspace=' + workspace, AppService.getOptions('Check workspace'));
    }

    deleteWorkspaceForUsers(userIds: number[]) {
        return this.http.post<User[]>(
            '/api/workspace/remove', userIds, AppService.getOptions('Remove workspace for users'));
    }

    getUsersInWorkspace(): Observable<User[]> {
        return this.http.get<User[]>('/api/workspace/users', AppService.getOptions('Get all users in a workspace'));
    }

    get token() {
        return window.getToken().token;
    }

    claims(): Claims {
        return window.getToken().claims;
    }

    getDefaultConfig(): Observable<DefaultConfigView> {
        return this.http.get<DefaultConfigView>('/api/config/default',
            AppService.getOptions('Get default project config')
        );
    }

    updateDefaultConfig(settings: DefaultConfigView): Observable<DefaultConfigView> {
        return this.http.put<DefaultConfigView>('/api/config/default', settings,
            AppService.getOptions('Get default project config')
        );
    }

    createGlobalConfig(): Observable<GlobalConfigView> {
        return this.http.post<GlobalConfigView>('/api/config/global', {item: {config: {}}},
            AppService.getOptions('Create project config'));
    }

    getGlobalConfig(id: string): Observable<GlobalConfigView> {
        return this.http.get<GlobalConfigView>('/api/config/global/' + id,
            AppService.getOptions('Get project config'));
    }

    updateGlobalConfig(settings: GlobalConfigView) {
        return this.http.put('/api/config/global', settings,
            AppService.getOptions('Update project config'));
    }

    deleteGlobalConfig(settings: string[]) {
        return this.http.post('/api/config/global-delete', settings,
            AppService.getOptions('Delete project config'));
    }

    getProjectConfig(projectId: string): Observable<ProjectConfigView> {
        return this.http.get<ProjectConfigView>('/api/config/project?projectId=' + projectId,
            AppService.getOptions('Get project config'));
    }

    updateProjectConfig(settings: ProjectConfigView): Observable<ProjectConfigView> {
        return this.http.put<ProjectConfigView>('/api/config/project', settings,
            AppService.getOptions('Update project config'));
    }

    hasIssueAccessView(): Observable<Access> {
        return this.http.get<Access>('/api/access',
            AppService.getOptions('Fetch access config'));
    }

    getApiKeyConfig(id: string): Observable<ApiKeyConfigView> {
        return this.http.get<ApiKeyConfigView>('/api-key/config/' + id,
            AppService.getOptions('Get Api key config'));
    }

    getApiKeyConfigHistory(id: string): Observable<ApiKeyConfigView[]> {
        return this.http.get<ApiKeyConfigView[]>('/api-key/config/history/' + id,
            AppService.getOptions('Get Api key config'));
    }

    getApiKeyUsage(id: string): Observable<ApiKeyUsage[]> {
        return this.http.get<ApiKeyUsage[]>('/api-key/config/usage/' + id,
            AppService.getOptions('Get Api key usage'));
    }

    createApiKeyConfig(): Observable<ApiKeyConfigView> {
        return this.http.post<ApiKeyConfigView>('/api-key/config', {item: {config: {}}},
            AppService.getOptions('Create api key config'));
    }

    updateApiKeyConfig(settings: ApiKeyConfigView) {
        return this.http.put<ApiKeyConfigView>('/api-key/config', settings,
            AppService.getOptions('Update api key config'));
    }

    deleteApiKeyConfig(id: string) {
        return this.http.delete('/api-key/config/' + id,
            AppService.getOptions('Delete api key config'));
    }

    getBoardsDataForProject(projectId: string, term: string): Observable<ViewItem[]> {
        return this.http.post<ViewItem[]>('/api/share/boards/' + projectId, term,
            AppService.getOptions('Search boards for project and term'));
    }

    editBoardCardLayout(config: BoardCardConfigApi): Observable<BoardCardConfigApi> {
        return this.http.put<BoardCardConfigApi>('/api/board-card-layout', config,
            AppService.getOptions('Edit board card layout'));
    }

    addBoardCardLayout(config: BoardCardConfigApi): Observable<BoardCardConfigApi> {
        return this.http.post<BoardCardConfigApi>('/api/board-card-layout', config,
            AppService.getOptions('Add board card layout'));
    }

    getBoardCardLayoutById(id: bigint): Observable<BoardCardConfigApi> {
        return this.http.get<BoardCardConfigApi>('/api/board-card-layout/' + id,
            AppService.getOptions('Get board card layout'));
    }

    getDefaultBoardCardLayoutSchema(): Observable<BoardCardLayoutRow[]> {
        return this.http.get<BoardCardLayoutRow[]>('/api/board-card-layout/default-schema',
            AppService.getOptions('Get board card layout default schema'));
    }

    deleteBoardCardLayout(id: string) {
        return this.http.delete('/api/board-card-layout/' + id,
            AppService.getOptions('Delete board card layout'));
    }

    getBoardCardLayoutsList(): Observable<BoardCardLayoutConfig[]> {
        return this.http.get<BoardCardLayoutConfig[]>('/api/board-card-layout/list',
            AppService.getOptions('Get board card layout list'));
    }

    getDefaultBoardCardLayout(): Observable<BoardCardConfigApi> {
        return this.http.get<BoardCardConfigApi>('/api/board-card-layout/list?isDefault=true',
            AppService.getOptions('Get default board card layout'));
    }

    getIssueLayoutsList(): Observable<IssueLayoutConfig[]> {
        return this.http.get<IssueLayoutConfig[]>('/api/issue-layout/list',
            AppService.getOptions('Get issue layouts list'));
    }

    getIssueLayoutById(id: string): Observable<IssueLayoutConfig> {
        return this.http.get<IssueLayoutConfig>('/api/issue-layout/' + id,
            AppService.getOptions('Get issue layout'));
    }

    getDefaultIssueLayout(): Observable<IssueLayoutConfig> {
        return this.http.get<IssueLayoutConfig>('/api/issue-layout/list?isDefault=true',
            AppService.getOptions('Get default issue layout'));
    }

    createIssueLayout(config: IssueLayout) {
        return this.http.post('/api/issue-layout', config,
            AppService.getOptions('Create issue layout'));
    }

    editIssueLayout(config: IssueLayout) {
        return this.http.put('/api/issue-layout', config,
            AppService.getOptions('Edit issue layout'));
    }

    deleteIssueLayout(id: string) {
        return this.http.delete('/api/issue-layout/' + id,
            AppService.getOptions('Delete issue layout'));
    }

    getDefaultIssueLayoutSchema(): Observable<LayoutFieldSchema> {
        return this.http.get<LayoutFieldSchema>('/api/issue-layout/default-schema',
            AppService.getOptions('Get issue layout default schema'));
    }

    saveIssueLayoutMapping(body: IssueTypesMapping): any {
        return this.http.post('/api/issue-layout-mapping', body,
            AppService.getOptions('Set issue layout mapping schema'));
    }

    getIssueLayoutMappingList(): Observable<IssueTypesMappingResponse[]> {
        return this.http.get<IssueTypesMappingResponse[]>('/api/issue-layout-mapping/list',
            AppService.getOptions('Get issue layout mapping list'));
    }

}
