import {Component, Input, OnInit} from '@angular/core';
import {ServerLicenseStatus} from '../../base/item.service';

@Component({
    selector: 'app-server-license-status-info',
    templateUrl: './server-license-status-info.component.html',
    styleUrls: ['./server-license-status-info.component.css']
})
export class ServerLicenseStatusInfoComponent {

    @Input() serverLicenseStatus: ServerLicenseStatus;
    serverLicenseStatusEnum: typeof ServerLicenseStatus = ServerLicenseStatus;

    constructor() {
    }
}
