import {Component, OnInit} from '@angular/core';
import {JiraService, Permissions, Project} from '../jira.service';
import {Observable, zip} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {map, switchMap} from 'rxjs/operators';
import Utils from '../utils/utils';
import PermissionUtils from '../utils/permission-utils';

interface ProjectViewComponentConfig {
    userIsAdmin: boolean;
    userIsProjectAdmin: boolean;
    isLite: boolean;
    title: string;
    projectId: string;
    project: Project;
    permissions: Permissions;
}

@Component({
    selector: 'app-project-view',
    templateUrl: './project-view.component.html',
    styleUrls: ['./project-view.component.css']
})
export class ProjectViewComponent implements OnInit {

    config$: Observable<ProjectViewComponentConfig>;

    createButtonsDisabled = false;

    constructor(private jira: JiraService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
        this.config$ = zip(
            this.route.queryParamMap,
            this.route.paramMap,
            this.project(),
            this.jira.getAddonProperty('permissions', '{"enabled": false}')
        ).pipe(map(([query, params, project, permissionSchemeObj]) => {
            const isLite = Utils.isLiteApp(query);
            const userIsAdmin = Utils.isTrue(query.get('user_is_admin'));
            const userIsProjectAdmin = Utils.isTrue(query.get('user_is_project_admin')) || userIsAdmin;
            const title = isLite ? 'External Share for Jira - Lite' : 'External Share for Jira';
            const projectId = params.get('projectId');
            const permissions = PermissionUtils.getPermissionsObj(query, permissionSchemeObj.enabled);
            return {isLite, title, userIsAdmin, userIsProjectAdmin, projectId, project, permissions};
        }));

        this.jira.resize('es-expand');
    }

    createShare(config: ProjectViewComponentConfig) {
        this.jira.showDialog({
                key: 'share-type-selection-popup',
                customData: {
                    projectId: config.projectId,
                    permissions: config.permissions,
                    source: 'PROJECT'
                }
            }
        );
    }

    project(): Observable<Project> {
        return this.route.paramMap.pipe(
            switchMap(it => {
                const projectId = it.get('projectId');
                return Utils.require(
                    this.jira.getProject(projectId),
                    'ProjectView',
                    'project',
                    `this.jira.getProject(${projectId})`
                );
            })
        );
    }

    navigateToProject(project: Project) {
        AP.navigator.go('site', {
            relativeUrl: '/browse/' + project.key.toUpperCase()
        });
    }

    navigateToProjects() {
        AP.navigator.go('site', {
            relativeUrl: '/jira/projects'
        });
    }

    isAdmin(config: ProjectViewComponentConfig) {
        return config.userIsAdmin || config.userIsProjectAdmin;
    }

    hasCreatePermission(config: ProjectViewComponentConfig) {
        return this.isAdmin(config) || config.permissions.canCreate;
    }
}
