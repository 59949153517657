import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {BaseModule} from './base/base.module';
import {ShareFormModule} from './share-form/share-form.module';
import {DummyComponent} from './utils/dummy.component';
import {ProjectViewModule} from './project-view/project-view.module';
import {ErrorComponent} from './error/error.component';
import {IssueViewModule} from './issue-view/issue-view.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconsModule} from './icons/icons.module';
import {GlobalErrorHandlerProvider} from '../global-error-handler';
import {GlobalViewModule} from './global-view/global-view.module';
import {ServerInterceptor} from './base/server-interceptor.service';
import {TokenInterceptor} from './base/token.interceptor';
import {ErrorInterceptor} from './base/error.interceptor';
import {ProjectSchemaFormModule} from './project-schema-form/project-schema-form.module';
import {NoAccessIssueViewComponent} from './no-access-issue-view/no-access-issue-view.component';
import {PersonalViewModule} from './personal-view/personal-view.module';
import {EmailFormModule} from './email-form/email-form.module';
import {ApiKeyFormModule} from './api-key-form/api-key-form.module';
import {DatePipe} from '@angular/common';
import {ShareBulkEditModule} from './share-bulk-edit/share-bulk-edit.module';

import {DragDropModule} from '@angular/cdk/drag-drop';
@NgModule({
    declarations: [
        AppComponent,
        DummyComponent,
        ErrorComponent,
        NoAccessIssueViewComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BaseModule,
        HttpClientModule,
        ShareFormModule,
        ProjectSchemaFormModule,
        ApiKeyFormModule,
        ProjectViewModule,
        GlobalViewModule,
        IssueViewModule,
        PersonalViewModule,
        ReactiveFormsModule,
        FormsModule,
        EmailFormModule,
        ShareBulkEditModule,
        IconsModule,
        DragDropModule
    ],
    exports: [],
    providers: [
        DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ServerInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        ...GlobalErrorHandlerProvider],
    bootstrap: [AppComponent]
})

export class AppModule {
}
