import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LoaderService} from '../loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private loader: LoaderService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.has('X-Operation-Name')) {
            const operationName = request.headers.get('X-Operation-Name');
            const headers = request.headers.delete('X-Operation-Name');
            const directRequest = request.clone({headers});
            return next.handle(directRequest).pipe(
                catchError((it: any) => {
                    let errorMessage = 'An unexpected error occurred';
                    if (isJsonResponse(headers) || it.error) {
                        const body = it.body || it.error;
                        errorMessage = message(body, errorMessage);
                        const detailsInfo = details(body, '');
                        console.error(`[JES] ${errorMessage} \n [Details] ${detailsInfo}\n`);
                    }

                    AP.flag.create({
                        title: 'External Share | ' + operationName,
                        body: errorMessage,
                        type: 'error',
                        close: 'auto'
                    });

                    this.loader.fullscreen(false);

                    throw it;
                }));
        }
        return next.handle(request);
    }
}

function isJsonResponse(head: HttpHeaders) {
    if (head.has('Content-Type')) {
        const contentType = head.get('Content-Type').toLowerCase();
        if (contentType.startsWith('application/json')) {
            return true;
        }
    }
    return false;
}

function message(body: any, defaultMessage: string): string {
    if (body && body.message) {
        return body.message;
    }
    return defaultMessage;
}

function details(body: any, defaultMessage: string): string {
    if (body && body.details) {
        return body.details;
    }
    return defaultMessage;
}
