import {Component} from '@angular/core';

@Component({
    selector: 'app-option-icon',
    template: '<svg role="presentation" width="24" height="24" viewBox="0 0 24 24" ' +
        'focusable="false"><path fill-rule="evenodd" clip-rule="evenodd" ' +
        'd="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 ' +
        '7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM15.708 11.723L12.779 ' +
        '14.678C12.566 14.893 12.288 15 12.009 15C11.73 15 11.449 14.893 11.231 ' +
        '14.678L8.291 11.713C7.903 11.321 7.903 10.686 8.291 10.294C8.679 9.902 ' +
        '9.309 9.902 9.698 10.294L12.005 12.621L14.303 10.304C14.69 9.912 15.32 ' +
        '9.912 15.708 10.304C16.097 10.696 16.097 11.331 15.708 11.723Z" ' +
        'fill="currentColor">' +
        '</path>' +
        '</svg>'
})
export class OptionIconComponent {
}
