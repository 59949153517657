import { Component, OnInit } from '@angular/core';
import {JiraService, Project} from '../../../jira.service';
import {Observable, zip} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ViewItem} from '../../../view-item';
import {AppService} from '../../../app.service';
import {Router} from '@angular/router';

interface IssueTypesMappingConfig {
    allTeamManagedProjects: Project[];
    globalTypesToSelect: ViewItem[];
}

@Component({
  selector: 'app-issue-layout-types-mapping',
  templateUrl: './issue-layout-types-mapping.component.html',
  styleUrls: ['./issue-layout-types-mapping.component.css']
})
export class IssueLayoutTypesMappingComponent implements OnInit {
    config$: Observable<IssueTypesMappingConfig>;
    form: FormGroup;
    search = '';
    searchedTeamManagedProjects: Observable<Project[]>;
  constructor(private jira: JiraService,
              private fb: FormBuilder,
              private app: AppService,
              private router: Router) { }
  ngOnInit(): void {
      this.form = this.fb.group({});
      this.config$ = zip(
          this.jira.getAllTeamManagedProjects(),
          this.jira.getIssueTypesForGlobalProjects(),
          this.app.getIssueLayoutMappingList()
      ).pipe(
          map(([allTeamManagedProjects, globalIssueTypes, issueLayoutMappingList]) => {
              allTeamManagedProjects.forEach(teamManagedProject => {
                  const group: FormGroup = this.fb.group({});
                  teamManagedProject.issueTypes.forEach(teamManagedIssueType => {
                      const projectMapping = issueLayoutMappingList.filter(value => value.teamManagedProjectId === teamManagedProject.id);
                      const thisIssueTypeMapping = projectMapping
                          .filter(value => value.teamManagedProjectIssueTypeId === teamManagedIssueType.id)[0];
                      let correspondingGlobalIssueType = null;
                      if (thisIssueTypeMapping) {
                          correspondingGlobalIssueType = globalIssueTypes
                              .filter(globalIssueType => globalIssueType.id === thisIssueTypeMapping.globalIssueTypeId)[0];
                      }
                      // here we set saved value to mapping
                      if (correspondingGlobalIssueType) {
                          group.addControl(
                              teamManagedIssueType.id,
                              new FormControl({id: correspondingGlobalIssueType.id, text: correspondingGlobalIssueType.name})
                          );
                      } else {
                          const teamManagedIssueTypeNameFormatted = teamManagedIssueType.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
                          const globalIssueTypeForAutoMapping = globalIssueTypes
                              .filter(it => it.name.replace(/[^a-zA-Z0-9]/g, '').toLowerCase() === teamManagedIssueTypeNameFormatted);
                          // this part of code runs when we first use issue mapping and automatically maps
                          // global issue types with team managed issue types that have the same or similar name
                          if (globalIssueTypeForAutoMapping.length !== 0) {
                              group.addControl(
                                  teamManagedIssueType.id,
                                  new FormControl({id: globalIssueTypeForAutoMapping[0].id, text: globalIssueTypeForAutoMapping[0].name})
                              );
                          } else {
                              group.addControl(
                                  teamManagedIssueType.id,
                                  new FormControl({id: 'DEFAULT', text: 'DEFAULT'})
                              );
                          }
                      }
                  });
                  this.form.addControl(teamManagedProject.id, group);
              });

              const globalTypesToSelect = globalIssueTypes.map(it => {
                  return {
                      id: it.id,
                      text: it.name
                  };
              });
              globalTypesToSelect.push({id: 'DEFAULT', text: 'DEFAULT'});

              return {
                  allTeamManagedProjects,
                  globalTypesToSelect,
              };
          })
      );
  }

    resetMultipleSelectsToDefault(elements: Project[]) {
      elements.forEach(it => {
          it.issueTypes.forEach(issueType => {
              this.form.get(it.id).get(issueType.id).setValue({id: 'DEFAULT', text: 'DEFAULT'});
          });
      });
    }

    resetSingleSelect(teamManagedProjectId: string, teamManagedIssueTypeId: string) {
      this.form.get(teamManagedProjectId).get(teamManagedIssueTypeId).setValue({id: 'DEFAULT', text: 'DEFAULT'});
    }

    save(form: FormGroup): void {
      this.app.saveIssueLayoutMapping(form.value).subscribe(it => {
          this.router.navigate(['/browse/global-view/issue-layout-list'], {skipLocationChange: true, queryParamsHandling: 'merge'});
      });
    }

    onSearch(e): void {
        this.search = e.target.value;
        this.searchedTeamManagedProjects = this.jira.getSearchedProjectsWithIssueTypes(e.target.value).pipe(
          map((searchedProject) => {
              return searchedProject.values.filter(it => it.style === 'next-gen');
          })
        );
    }
}

