import {ViewItem} from '../view-item';

export const CUSTOM_BOARD_ID = 'CUSTOM';

export interface CustomBoardConfig {
    boardName: string;
    columnsConfig: BoardColumn[];
}

export interface BoardColumn {
    name: string;
    statuses: ViewItem[];
}
