import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DragAndDropService {
    private draggingIndex: number;
    private dragging = false;

    get isDragging(): boolean {
        return this.dragging;
    }

    get draggedIndex(): number {
        return this.draggingIndex;
    }

    startDrag(index: number): void {
        this.draggingIndex = index;
        this.dragging = true;
    }

    enterDrag(index: number, array: any[]): void {
        if (this.draggingIndex !== index) {
            const fromIndex = this.draggingIndex;
            const toIndex = index;
            const itemToBeReordered = array.splice(fromIndex, 1)[0];
            array.splice(toIndex, 0, itemToBeReordered);
            this.draggingIndex = toIndex;
            array = array.map((item, i) => {
                item.index = i;
                return item;
            });
        }
    }

    endDrag(): void {
        this.dragging = false;
        this.draggingIndex = undefined;
    }
}
