import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ViewItem} from '../../view-item';
import {ConfigEnabled} from '../config-enabled';

@Component({
    selector: 'app-filter-custom-fields',
    templateUrl: './filter-custom-fields.component.html',
    styleUrls: ['./filter-custom-fields.component.css']
})
export class FilterCustomFieldsComponent {
    @Input() form: FormGroup;
    @Input() availableCustomFields: ViewItem[];
    @Input() enabled: ConfigEnabled;
    type: string;

    constructor() {
        this.type = 'filterCustomFields';
    }

}
