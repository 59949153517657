import {Component, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {Config} from '../share-form/share-form-config';
import {JiraService} from '../jira.service';
import PermissionUtils from '../utils/permission-utils';
import {map, tap} from 'rxjs/operators';
import {
    ShareBulkUpdateFilterType,
    ShareBulkUpdateFilterTypeDescription,
    ShareBulkUpdateOperationType,
    ShareBulkUpdateRequest,
} from '../base/bulk-edit';
import CustomValidators from '../utils/custom-validators';
import {ItemService} from '../base/item.service';
import {AtlassianUser} from '../atlassian-user';

@Component({
    selector: 'app-share-bulk-edit-popup',
    templateUrl: './share-bulk-edit-popup.component.html',
    styleUrls: ['./share-bulk-edit-popup.component.css'],
})
export class ShareBulkEditPopupComponent implements OnInit {

    readonly ShareBulkUpdateFilterType = ShareBulkUpdateFilterType;
    readonly ShareBulkUpdateFilterTypeDescription = ShareBulkUpdateFilterTypeDescription;

    config$: Observable<Config>;
    request$: Observable<{ updatedCount: number }>;

    currentUser: AtlassianUser;

    form: FormGroup = this.fb.group({
        filter: this.fb.group({
            type: this.fb.control(''),
            uuids: this.fb.array([]),
            criteria: this.fb.group({}),
        }),
        update: this.fb.group({
            domains: this.fb.array([]),
            users: this.fb.array([]),
            lastModifiedBy: this.fb.control(false),
        }, {validators: [ShareBulkEditPopupComponent.updateOperationsValidator]}),
    });

    static updateOperationsValidator: ValidatorFn = control => {
        const domainsControl = control.get('domains') as FormArray;
        const usersControl = control.get('users') as FormArray;
        const lastModifiedByControl = control.get('lastModifiedBy') as FormControl;
        return domainsControl.length + usersControl.length + (lastModifiedByControl.value && 1) > 0 ? null : {noOperations: true};
    }

    constructor(private fb: FormBuilder,
                private itemService: ItemService,
                public jira: JiraService) {
    }

    ngOnInit(): void {
        this.config$ = this.jira.getCustomData<ShareBulkUpdateRequest & { permissions: Permissions }>().pipe(
            tap(data => this.initForm(data)),
            map(data => ({permissions: PermissionUtils.getPermissionsFromCustomData(data)}) as Config),
        );
        this.jira.getUser().subscribe({
            next: user => this.currentUser = user
        });
    }

    initForm(data: ShareBulkUpdateRequest): void {
        if (!this.type.value) {
            const filter = this.form.get('filter') as FormGroup;
            this.setIfExists(data.filter.type, filter, 'type');
            this.setIfExists(data.filter.uuids, filter, 'uuids');
            const criteria = filter.get('criteria') as FormGroup;
            this.setIfExists(data.filter.criteria.projectId, criteria, 'projectId');
            this.setIfExists(data.filter.criteria.userId, criteria, 'userId');
            this.setIfExists(data.filter.criteria.query, criteria, 'query');
        }
    }

    private setIfExists(value: string | string[], group: FormGroup, name: string) {
        if (value) {
            if (value instanceof Array) {
                const formArray = this.fb.array([]);
                value.forEach((v: string) => formArray.push(this.fb.control(v)));
                group.setControl(name, formArray);
            } else {
                group.setControl(name, this.fb.control(value));
            }
        }
    }

    get type() {
        return this.form.get('filter').get('type') as FormControl;
    }

    get uuids() {
        return this.form.get('filter').get('uuids') as FormArray;
    }

    get projectId() {
        return this.form.get('filter').get('criteria').get('projectId') as FormControl;
    }

    get query() {
        return this.form.get('filter').get('criteria').get('query') as FormControl;
    }

    get lastModifiedBy() {
        return this.form.get('update').get('lastModifiedBy') as FormControl;
    }

    get users() {
        return this.form.get('update').get('users') as FormArray;
    }

    addUser() {
        const newOperationType = this.calculateNewOperationType(this.users);
        const operationForm = this.fb.group({
            operationType: [newOperationType, Validators.required],
            value: [''],
        });
        this.users.push(operationForm);
        this.handleLastModifiedBy();
    }

    deleteUser(index: number) {
        this.users.removeAt(index);
        this.handleLastModifiedBy();
    }

    get domains() {
        return this.form.get('update').get('domains') as FormArray;
    }

    addDomain() {
        const newOperationType = this.calculateNewOperationType(this.domains);
        const operationForm = this.fb.group({
            operationType: [newOperationType, Validators.required],
            value: ['', CustomValidators.emailDomains()],
        });
        this.domains.push(operationForm);
        this.handleLastModifiedBy();
    }

    deleteDomain(index: number) {
        this.domains.removeAt(index);
        this.handleLastModifiedBy();
    }

    availableOperations(formArray: FormArray, selectedOption: ShareBulkUpdateOperationType) {
        return formArray.length < 2 ? Object.values(ShareBulkUpdateOperationType) : [selectedOption];
    }

    availableFilterTypes() {
        return Object.values(ShareBulkUpdateFilterType);
    }

    doUpdate() {
        this.request$ = this.itemService.bulkUpdate(this.form.value)
            .pipe(map(updatedCount => ({updatedCount})));
    }

    backToEdit() {
        delete this.request$;
    }

    calculateNewOperationType(formArray: FormArray) {
        let newOperationType: ShareBulkUpdateOperationType;
        if (formArray.controls.find(c => c.value.operationType === ShareBulkUpdateOperationType.ADD)) {
            newOperationType = ShareBulkUpdateOperationType.REMOVE;
        } else if (formArray.controls.find(c => c.value.operationType === ShareBulkUpdateOperationType.REMOVE)) {
            newOperationType = ShareBulkUpdateOperationType.ADD;
        } else {
            newOperationType = ShareBulkUpdateOperationType.SET;
        }
        return formArray.controls.find(c => c.value.operationType === newOperationType) ? undefined : newOperationType;
    }

    private handleLastModifiedBy() {
        const hasOperations = this.users.length + this.domains.length > 0;
        this.lastModifiedBy.patchValue(hasOperations);
        if (hasOperations) {
            this.lastModifiedBy.disable();
        } else {
            this.lastModifiedBy.enable();
        }
        this.lastModifiedBy.updateValueAndValidity();
    }
}
