import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BoardCardLayoutRow} from '../boardCard';

const Mapping = {
    FLEXSTART: 'flex-start',
    CENTER: 'center',
    SPACEBETWEEN: 'space-between',
    SPACEEVENLY: 'space-evenly',
    FLEXEND: 'flex-end',
};

@Component({
    selector: 'app-card-layout-row',
    templateUrl: './card-layout-row.component.html',
    styleUrls: ['./card-layout-row.component.css']
})
export class CardLayoutRowComponent {
    @Input() row: BoardCardLayoutRow;
    @Input() currentRow: BoardCardLayoutRow;
    @Output() emitRowToDelete = new EventEmitter<BoardCardLayoutRow>();
    private options = false;

    deleteRow(row: BoardCardLayoutRow) {
        this.emitRowToDelete.emit(row);
    }

    changeEnumToString(value: string) {
        return Mapping[value] || 'space-between';
    }

    showOptions() {
        this.options = !this.options;
    }

}
