import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {Link} from '../../base/link';
import Utils from '../../utils/utils';

@Component({
    selector: 'app-share-link',
    templateUrl: './share-link.component.html'
})
export class ShareLinkComponent {
    features = window.getToken().features;
    href: string;
    name: string;
    showDescription = false;
    description: string;
    @Input() showName = true;
    @Input() showDescriptionIcon = false;
    data: Link;
    @Input() shareLinkStyle = '';

    @ViewChild('shareLink') shareLink: ElementRef;

    @Input() set share(share: Link) {
        this.data = share;
        this.href = share.url;
        this.name = share.name;
        if (share.description &&
            share.description.trim().length > 0) {
            this.showDescription = true;
            this.description = share.description;
            this.description = this.description.substring(0, Math.min(this.description.length, 800));
        }
    }

    get share() {
        return this.data;
    }

    @Input() checkbox = false;
    @Input() namedLink = '';
    @Input() inputDirty = false;
    @Input() checkboxDirty = false;

    constructor() {
    }

    copyHrefToClipboard() {
        Utils.copyToClipboard(this.shareLink.nativeElement.href);
    }
}
