import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" focusable="false" role="presentation">' +
    '    <g fill="currentColor" fill-rule="evenodd">' +
    '        <rect x="17" y="5" width="4" height="11" rx="2"></rect>' +
    '        <rect x="11" y="8" width="4" height="8" rx="2"></rect>' +
    '        <rect x="5" y="11" width="4" height="5" rx="2"></rect>' +
    // tslint:disable-next-line:max-line-length
    '        <path d="M21 17H4.995C4.448 17 4 16.548 4 15.991V6a1 1 0 10-2 0v9.991A3.004 3.004 0 004.995 19H21a1 1 0 000-2z" fill-rule="nonzero"></path>' +
    '    </g>' +
    '</svg>';
@Directive({
    selector: '[appCustomizeColumnsIcon]'
})
export class CustomizeColumnsIconDirective implements OnInit {
    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }
    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
