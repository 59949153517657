import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from './app.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AccessCheckGuard implements CanActivate {
    constructor(private app: AppService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.app.hasIssueAccessView().pipe(
            map(it => {
                if (it.allowed) {
                    return true;
                }
                return this.router.parseUrl('/no-access-issue-view?' +
                    'source=list' +
                    '&xdm_e=' + next.queryParams.xdm_e +
                    '&reason=' + it.reason);
            })
        );
    }
}
