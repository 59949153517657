import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-error',
  template: ''
})
export class ErrorComponent implements OnInit {

  ngOnInit() {
    window.location.replace('./assets/error.html');
  }
}
