import {Component, OnInit} from '@angular/core';
import {ProjectSchemaViewService} from './project-schema-view.service';

@Component({
    selector: 'app-project-schema-form-parent',
    templateUrl: './project-schema-form-parent.component.html',
    styleUrls: ['./project-schema-form-parent.component.css']
})
export class ProjectSchemaFormParentComponent implements OnInit {
    fullHeight = true;

    constructor(private psv: ProjectSchemaViewService) {
    }

    ngOnInit(): void {
        this.psv.toggleFullHeight.subscribe(it => {
            this.fullHeight = it;
        });
    }
}
