import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ViewItem} from '../../view-item';
import {ConfigEnabled} from '../config-enabled';

@Component({
    selector: 'app-issue-default-custom-fields',
    templateUrl: './issue-default-custom-fields.component.html',
    styleUrls: ['./issue-default-custom-fields.component.css']
})
export class IssueDefaultCustomFieldsComponent {
    @Input() form: FormGroup;
    @Input() availableCustomFields: ViewItem[];
    @Input() enabled: ConfigEnabled;
    type: string;

    constructor() {
        this.type = 'issueCustomFields';
    }
}
