export class ExportCsv {

    static exportToCsv(filename: string, csvData: CsvData): void {
        const csvRows: string[] = removeCommasFromCsvColumns(csvData);
        const csvHeaderData: string = csvData.header.join(', ') + '\n';
        const csvRowsData: string = csvRows.join('');
        const csvText: string = csvHeaderData + csvRowsData;
        saveCsv(filename, csvText);
    }
}

function removeCommasFromCsvColumns(csvData: CsvData): string[] {
    const csvRows: string[] = [];
    for (const row of csvData.rows) {
        const rowWithoutCommas: string[] = row.map(el => {
            if (!el) {
                return '';
            } else {
                return el.toString().trim().replace(/, /g, ' ').replace(/,/g, '');
            }
        });
        csvRows.push(rowWithoutCommas.join(', ') + '\n');
    }
    return csvRows;
}

function saveCsv(filename: string, csvText: string): void {
    const blob = new Blob([csvText], {type: 'text/csv'});
    if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = filename;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }
}

export interface CsvData {
    rows: string[][];
    header: string[];
}
