import {Component, Input} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {SearchFunction} from '../../components/better-select/better-select.component';
import {ViewItem} from '../../view-item';
import {of} from 'rxjs';

@Component({
    selector: 'app-custom-fields-picker',
    templateUrl: './custom-fields-picker.component.html',
    styleUrls: ['./custom-fields-picker.component.css']
})
export class CustomFieldsPickerComponent {
    @Input() form: FormGroup;
    @Input() customFieldPickForm: FormGroup = new FormGroup({
        customFieldPick: new FormControl('')
    });
    @Input() customFields: ViewItem[];
    @Input() type: string;

    customFieldPick: SearchFunction;

    constructor() {
        this.customFieldPick = this.customFieldPickFoo.bind(this);
    }

    customFieldPickFoo: SearchFunction = (term) => {
        const filtered = this.customFields.filter(item => item.text.toLowerCase().includes(term.toLowerCase()));
        const data = {
            results: filtered,
            pagination: {more: false}
        };
        return of(data);
    }

    add() {
        const customFields = this.customFieldPickForm.get('customFieldPick');
        const value = customFields.value;
        if (Array.isArray(value)) {
            const existing = this.customFieldsValue;
            const cache = {};
            for (const el of existing) {
                cache[el.id] = el;
            }

            const newValues = existing;
            for (const val of value) {
                if (!cache[val.id]) {
                    newValues.push({id: val.id, text: val.text});
                }
            }

            this.customFieldsValue = newValues;
            customFields.setValue([]);
        }
    }

    delete(toDelete: any) {
        const existing = this.customFieldsValue;
        const newValues = [];
        for (const el of existing) {
            if (el.id !== toDelete.id) {
                newValues.push(el);
            }
        }

        this.customFieldsValue = newValues;
    }

    get customFieldsControl() {
        return this.form.get(this.type);
    }

    get customFieldsValue() {
        return this.customFieldsControl.value;
    }

    set customFieldsValue(val: any) {
        this.customFieldsControl.setValue(val);
    }
}
