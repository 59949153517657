import {NgModule} from '@angular/core';
import {BaseModule} from '../base/base.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {IconsModule} from '../icons/icons.module';
import {RouterModule} from '@angular/router';
import {ProjectSchemaFormComponent} from './project-schema-form.component';
import {ProjectSchemaFormParentComponent} from './project-schema-form-parent.component';
import {DataOptionsComponent} from './data-options/data-options.component';
import {SecurityOptionsComponent} from './security-options/security-options.component';
import {VisibilityOptionsComponent} from './visibility-options/visibility-options.component';
import {AssociatedProjectsComponent} from './associated-projects/associated-projects.component';
import {FilterCustomFieldsComponent} from './filter-custom-fields/filter-custom-fields.component';
import {IssueDefaultCustomFieldsComponent} from './issue-default-custom-fields/issue-default-custom-fields.component';
import {RadioButtonComponent} from './radio-button/radio-button.component';
import {VisibilityCheckboxComponent} from './visibility-options/visibility-checkbox/visibility-checkbox.component';
import {SelectedUsersComponent} from './selected-users/selected-users.component';
import {EmailFormModule} from '../email-form/email-form.module';
import {CustomFieldsPickerComponent} from './custom-fields-picker/custom-fields-picker.component';
import {DataDefaultsComponent} from './data-defaults/data-defaults.component';
import {AuditComponent} from './audit/audit.component';

@NgModule({
    declarations: [
        ProjectSchemaFormComponent,
        ProjectSchemaFormParentComponent,
        DataOptionsComponent,
        SecurityOptionsComponent,
        VisibilityOptionsComponent,
        AssociatedProjectsComponent,
        FilterCustomFieldsComponent,
        IssueDefaultCustomFieldsComponent,
        RadioButtonComponent,
        VisibilityCheckboxComponent,
        SelectedUsersComponent,
        CustomFieldsPickerComponent,
        DataDefaultsComponent,
        AuditComponent
    ],
    imports: [
        BaseModule,
        BrowserModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        IconsModule,
        EmailFormModule
    ],
    exports: [
        ProjectSchemaFormComponent,
        ProjectSchemaFormParentComponent
    ]
})
export class ProjectSchemaFormModule {
}
