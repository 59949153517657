import {
    AfterViewInit,
    Component,
    ElementRef,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import Utils from '../utils/utils';

@Component({
    selector: 'app-multi-select',
    template: '<select style="width: 100%" multiple #selectElement></select>',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AppMultiSelectComponent),
            multi: true
        }
    ]
})
export class AppMultiSelectComponent implements ControlValueAccessor, OnChanges, AfterViewInit, OnDestroy {

    @Input() items: any;
    @Input() valueFn: any;
    @Input() textFn: any;
    @Input() remote: (val: string) => Observable<any>;
    @Input() remoteTemplateResult: (val: any) => string;
    @Input() remoteTemplateSelection: (val: any, container: any) => string;
    @Input() quietMillis: number;
    @Input() minimumInputLength: number;
    @Input() disabled = false;

    @ViewChild('selectElement', {static: true}) selectElement: ElementRef;

    private selectElement$: JQuery<HTMLSelectElement>;

    private onChangeFn: any;
    private value: any;

    get isFocusable() {
        return this.selectElement;
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
        if (this.selectElement$) {
            this.selectElement$.prop('disabled', isDisabled);
        }
    }

    writeValue(obj: any): void {
        this.value = obj;

        if (this.selectElement$) {
            if (this.remote && Array.isArray(obj)) {
                for (const data of obj) {
                    this.selectElement$
                        .append(new Option(sanitize(data.text), data.id, true, true));
                }
                this.selectElement$.trigger('change');
            } else {
                this.selectElement$
                    .val(this.value)
                    .trigger('change');
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.disabled) {
            this.setDisabledState(changes.disabled.currentValue);
        } else if (this.selectElement$) {
            let items: Array<any>;
            if (changes.items) {
                items = changes.items.currentValue as Array<any>;
            }

            if (Utils.isNotEmptyArray(items)) {
                this.updateItems(items);

                this.selectElement$
                    .val(this.value)
                    .trigger('change');
            } else {
                this.selectElement$.empty()
                    .val(null)
                    .trigger('change');
            }
        }
    }

    ngAfterViewInit(): void {
        this.selectElement$ = $(this.selectElement.nativeElement);

        const options: any = {
            placeholder: 'Select ...',
            allowClear: true
        };

        if (this.remote) {
            options.ajax = {
                transport: (params, sucess, failure) => {
                    this.remote(params.data.term)
                        .subscribe({
                            next: sucess,
                            error: failure
                        });
                }
            };
        }

        if (this.remoteTemplateResult) {
            options.templateResult = this.remoteTemplateResult;
        }
        if (this.remoteTemplateSelection) {
            options.templateSelection = this.remoteTemplateSelection;
        }
        if (this.quietMillis) {
            options.quietMillis = this.quietMillis;
        }
        if (this.minimumInputLength) {
            options.minimumInputLength = this.minimumInputLength;
        }
        options.disabled = this.disabled;

        this.selectElement$.select2(options);

        this.selectElement$.on('change', (e: any) => {
            if (this.remote) {
                const result = this.selectElement$
                    .select2('data')
                    .map(it => this.valueFn(it));

                this.onChangeFn(result);
            } else {
                const result = this.selectElement$
                    .find('option')
                    .filter((idx, that: any) => (that as HTMLOptionElement).selected)
                    .map((idx, that) => {
                        const value = that['__app-select-value'];
                        return !!value ? value : that.value;
                    })
                    .get();

                this.onChangeFn(result);
            }
        });

        if (Utils.isNotEmptyArray(this.items)) {
            this.updateItems(this.items);
        }
    }

    ngOnDestroy(): void {
        if (this.selectElement$) {
            this.selectElement$.select2('destroy');
            this.selectElement$ = null;
        }
    }

    updateItems(items: any) {
        items.forEach(item => {
            const itemValue = this.valueFn(item);
            const option = this.newOption(item, itemValue, item.selected);
            option['__app-select-value'] = itemValue;
            this.selectElement$.append(option);
        });
    }

    newOption(item: any, val: any, selected: boolean): HTMLOptionElement {
        return new Option(sanitize(this.textFn(item)), val, selected, selected);
    }
}

function sanitize(input: string): string {
    if (input) {
        const map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            '\'': '&#x27;',
            '/': '&#x2F;',
        };
        const reg = /[&<>"'/]/ig;
        return input.replace(reg, (match) => {
            return map[match];
        });
    }
    return input;
}
