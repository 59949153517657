import {Directive, ElementRef, OnInit, Renderer2} from '@angular/core';

const CONTENT = '' +
    '<svg width="24" height="24" viewBox="0 0 24 24" role="presentation">' +
    // tslint:disable-next-line:max-line-length
    '   <path d="M11.293 5.293l-5.5 5.499a1.002 1.002 0 000 1.415.999.999 0 001.414 0L11 8.414V18a1 1 0 002 0V8.414l3.793 3.793a1 1 0 101.414-1.415l-5.5-5.499A.993.993 0 0012 5a.993.993 0 00-.707.293z" fill="currentColor" fill-rule="evenodd"></path>' +
    '</svg>';

@Directive({
    selector: '[appArrowUpIcon]'
})
export class ArrowUpIconDirective implements OnInit {

    constructor(private el: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnInit() {
        this.renderer.setProperty(this.el.nativeElement, 'innerHTML', CONTENT);
    }
}
