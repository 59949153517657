import {Component, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
    selector: 'app-share-form-parent',
    templateUrl: './share-form-parent.component.html',
    styleUrls: ['./share-form-parent.component.css']
})
export class ShareFormParentComponent implements OnDestroy {

    private event$;
    isMailForm: boolean;

    constructor(private router: Router) {
        this.event$ = this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.isMailForm = event.url.startsWith('/share/mail');
                }
            });
    }

    ngOnDestroy() {
        this.event$.unsubscribe();
    }
}
