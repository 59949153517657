import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {JiraService} from '../jira.service';

@Component({
    selector: 'app-no-access-issue-view',
    templateUrl: './no-access-issue-view.component.html',
    styleUrls: ['./no-access-issue-view.component.css']
})
export class NoAccessIssueViewComponent implements OnInit {

    config$: Observable<{ reason: string, source: string }>;

    constructor(private route: ActivatedRoute,
                private jira: JiraService) {
    }

    ngOnInit(): void {
        this.jira.resize('es-expand');
        this.config$ = this.route.queryParamMap.pipe(
            map(it => ({
                reason: it.get('reason'),
                source: it.get('source'),
            }))
        );
    }

    close() {
        this.jira.closeDialog();
    }
}
