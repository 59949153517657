import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-share-form-preview-footer',
    templateUrl: './share-form-preview-footer.component.html',
    styleUrls: ['./share-form-preview-footer.component.css']
})
export class ShareFormPreviewFooterComponent {
    @Input() views = 0;
    @Input() subscribers = 0;
    @Input() passwordSecured = false;
    @Input() ssoSecured = false;

    constructor() {
    }
}
