import {Component, OnInit} from '@angular/core';
import {AppService} from '../../../app.service';
import {JiraService} from '../../../jira.service';
import {IssueLayoutConfig} from '../issueLayout';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-issue-layout-list',
    templateUrl: './issue-layout-list.component.html',
    styleUrls: ['./issue-layout-list.component.css'],
})
export class IssueLayoutListComponent implements OnInit {
    private refreshEventName = 'config-update';
    deleteSelected: boolean;
    moreThanOneItemIsSelected: boolean;
    $layoutsList: Observable<IssueLayoutConfig[]>;
    constructor(private app: AppService,
                private jira: JiraService) {
    }

    ngOnInit() {
        this.$layoutsList = this.app.getIssueLayoutsList();
    }

    selectAll(on: boolean, elements: any[]) {
        elements.forEach(it => it._delete = on);
        this.updateDeleteAll(elements);
    }

    updateDeleteAll(layouts: IssueLayoutConfig[]) {
        this.moreThanOneItemIsSelected = layouts.contains(el => el._delete);
        this.deleteSelected = layouts.every(el => el._delete);
    }

    deleteSelectedLayouts(list: IssueLayoutConfig[]) {
        const toDelete = list.filter(item => item._delete);
        toDelete.forEach(layout => {
            this.deleteLayout(layout.id);
        });
        this.$layoutsList = this.app.getIssueLayoutsList();
        this.moreThanOneItemIsSelected = false;
    }

    deleteLayout(id: string) {
        this.app.deleteIssueLayout(id).subscribe(item => {
            this.jira.emitEvent(this.refreshEventName);
            this.$layoutsList = this.app.getIssueLayoutsList();
            this.moreThanOneItemIsSelected = false;
        });
    }
}
