import {Directive, Input, OnInit, Optional} from '@angular/core';
import {ControlContainer} from '@angular/forms';
import {merge, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {JiraService} from '../../jira.service';

@Directive({
    selector: '[appJqlVerification]'
})
export class JqlVerificationDirective implements OnInit {
    @Input('appJqlVerification') formControlName: string;

    constructor(
        @Optional() private controlContainer: ControlContainer,
        private jira: JiraService
    ) {}

    ngOnInit(): void {
        const jqlControl = this.controlContainer.control.get(this.formControlName);
        const issuesCountKey = 'issuesCount';

        merge(of(jqlControl.status), jqlControl.statusChanges)
            .pipe(
                switchMap(() => {
                    if (jqlControl.errors) {
                        jqlControl[issuesCountKey] = 0;
                        return of(0);
                    }
                    return this.checkJqlIssuesCount(jqlControl.value);
                })
            )
            .subscribe(count => {
                jqlControl[issuesCountKey] = count;
            });
    }

    private checkJqlIssuesCount(term: string) {
        return term && term.trim().length > 0
            ? this.jira.checkIssueCountForProvidedJql(term)
            : of(0);
    }
}
