import {Component, OnInit} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {ServerDataSource} from '../components/app-table.component';
import {ActivatedRoute} from '@angular/router';
import Utils from '../utils/utils';
import {JiraService, Permissions} from '../jira.service';
import {FormControl, FormGroup} from '@angular/forms';
import {map, switchMap} from 'rxjs/operators';
import {merge, Observable, of, zip} from 'rxjs';
import {AppState} from '../base/item.service';
import {Link, LinkFilterCriteria} from '../base/link';
import {ShareActionService} from '../share-action.service';

@Component({
    selector: 'app-project-view',
    templateUrl: './personal-view.component.html',
    styleUrls: ['./personal-view.component.css']
})
export class PersonalViewComponent implements OnInit {
    showFilterInformation = false;
    moreThanOneItemIsSelected = false;
    deleteAll = false;

    features = window.getToken().features;
    config$: Observable<{ app: AppState, userId: string }>;

    filterForm = new FormGroup({
        textSearch: new FormControl('created_by: me ')
    });

    constructor(public source: ServerDataSource<Link, LinkFilterCriteria>,
                private route: ActivatedRoute,
                private shareActions: ShareActionService,
                private jira: JiraService) {
    }

    ngOnInit() {
        const shareUpdate = this.jira.observeEvent('share-update');
        shareUpdate.subscribe(() => {
            this.source.refresh();
        });

        // Refresh app-state on init and on update
        const inputEvents = merge(of([]), shareUpdate);

        this.config$ = inputEvents.pipe(
            switchMap(() => zip(
                Utils.require(Utils.combineParams(this.route),
                    'PersonalViewComponent', 'ngOnInit', 'Utils.combineParams(this.route)'),
                Utils.require(this.jira.getCurrentUser(),
                    'PersonalViewComponent', 'ngOnInit', 'this.jira.getCurrentUser()'),
                Utils.require(this.jira.getAddonProperty('permissions', '{"enabled": false}'),
                    'PersonalViewComponent', 'ngOnInit', 'this.jira.getAddonProperty("permissions", \'{"enabled": false}\')')
            )),
            map(([params, user, permissionSchemeObj]) => {
                this.setupDatasource(user.atlassianAccountId);
                return {
                    app: Utils.getAppStateNow(params, permissionSchemeObj.enabled),
                    userId: user.atlassianAccountId
                };
            })
        );
    }

    private setupDatasource(userId: string) {
        this.source.reload({
            endPoint: `/api/share`,
            defaultSort: 'created',
            defaultSortOrder: 'desc',
            defaultLimit: 10,
            filter: {
                userId,
                status: 'active'
            },
            headers: new HttpHeaders({
                'X-Operation-Name': 'Fetching shares'
            })
        });
    }

    copyToClipboard(value: any) {
        Utils.copyToClipboard(value);
    }

    filter(event: KeyboardEvent | MouseEvent, userId: string) {
        if ('code' in event) {
            if (event.code === 'Enter' || event.code === 'NumpadEnter') {
                const query: string = this.filterForm.value.textSearch;
                this.source.updateFilter({query, userId});
            }
        } else {
            const query: string = this.filterForm.value.textSearch;
            this.source.updateFilter({query, userId});
        }
    }

    getInactiveStatusTooltip(status): string {
        const messages = [];

        if (status.disabled) {
            messages.push('Disabled');
        }

        if (status.disabledByParent) {
            messages.push('Disabled by parent');
        }

        if (status.expired) {
            messages.push('Expired');
        }
        return messages.length ? messages.join(', ') : 'Project is inactive';
    }

    selectAll(on: boolean, elements: any[]) {
        elements.forEach(it => it._delete = on);
        this.updateDeleteAll(elements);
    }

    updateDeleteAll(elements: any[]) {
        this.moreThanOneItemIsSelected = elements.contains(el => el._delete);
        this.deleteAll = elements.every(el => el._delete);
    }

    doDeleteAll(elements: any[]) {
        const toDelete = elements.filter(it => it._delete) as Link[];
        this.shareActions.deleteMultiple(toDelete).then(() => this.moreThanOneItemIsSelected = false);
    }

    doEditAll(elements: any[], query: string, userId: string) {
        const toEdit = elements.filter(it => it._delete) as Link[];
        this.shareActions.bulkEdit(toEdit, { query, userId });
    }

    getPermissionsForShare(permissions: Permissions) {
        const canEdit = permissions && permissions.canEdit;
        const canDelete = permissions && permissions.canDelete;
        const canSendEmail = permissions && permissions.canSendEmail;
        const canCreate = permissions && permissions.canCreate;
        return {
            canCreate,
            canEdit,
            canDelete,
            canSendEmail
        };
    }
}
