import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {ConfigEnabled} from '../config-enabled';

@Component({
    selector: 'app-visibility-options',
    templateUrl: './visibility-options.component.html',
    styleUrls: ['./visibility-options.component.css']
})
export class VisibilityOptionsComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() enabled: ConfigEnabled;
    issue = [[]];
    filter = [[]];
    constructor() {
    }

    ngOnInit(): void {
        const i = this.enabled.issue;
        const f = this.enabled.filter;
        this.issue = [
            ['sfStatus', 'Status', i.status],
            ['sfPriority', 'Priority', i.priority],
            ['sfAssignee', 'Assignee', i.assignee],
            ['sfReporter', 'Reporter', i.reporter],
            ['sfLabels', 'Labels', i.labels],
            ['sfDueDate', 'Due date', i.dueDate],
            ['sfTimeTracking', 'Time tracking', i.timeTracking],
            ['sfOriginalEstimate', 'Original Estimate', i.originalEstimate],
            ['sfFixVersions', 'Fix versions', i.fixVersions],
            ['sfVersions', 'Versions', i.versions],
            ['sfComponents', 'Components', i.components],
            ['sfCustomFields', 'Custom fields', i.customFields],
        ];

        this.filter = [
            ['ffProject', 'Project', f.project],
            ['ffIssueKey', 'Issue Key', f.issueKey],
            ['ffSummary', 'Summary', f.summary],
            ['ffDescription', 'Description', f.description],
            ['ffAssignee', 'Assignee', f.assignee],
            ['ffPriority', 'Priority', f.priority],
            ['ffStatus', 'Status', f.status],
            ['ffCreatedDate', 'Created Date', f.createdDate],
            ['ffUpdatedDate', 'Updated Date', f.updatedDate],
            ['ffReporter', 'Reporter', f.reporter],
            ['ffLabels', 'Labels', f.labels],
            ['ffDueDate', 'Due Date', f.dueDate],
            ['ffTimeTracking', 'Time tracking', f.timeTracking],
            ['ffOriginalEstimate', 'Original Estimate', f.originalEstimate],
            ['ffFixVersions', 'Fix Versions', f.fixVersions],
            ['ffVersions', 'Versions', f.versions],
            ['ffComponents', 'Components', f.components],
            ['ffCustomFields', 'Custom Fields', f.customFields],
        ];
    }

}
