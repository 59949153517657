import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IssueType} from '../../../issueType';
@Component({
    selector: 'app-board-card-color-editor',
    templateUrl: './board-card-color-editor.component.html',
    styleUrls: ['./board-card-color-editor.component.css']
})
export class BoardCardColorEditorComponent implements OnInit {
    tab = 'cardLayout';
    option = 'None';
    currentSettings: IssueType[] | Priority[];

    @Input() allPriorityTypes: Priority[];
    @Input() colorSettings: CardColor;
    @Input() allIssueTypes: IssueType[];
    @Output() colorChange = new EventEmitter<any>();

    ngOnInit(): void {
        this.initializeColorSettings();
    }

    initializeColorSettings() {
        if (!this.colorSettings) {
            this.setDefaultColorSettings();
        } else {
            this.option = this.colorSettings.type;
            this.updateCurrentSettings();
        }
    }

    setDefaultColorSettings() {
        this.colorSettings = {
            type: 'None',
            value: { darkMode: {}, lightMode: {} }
        };
        this.option = 'None';
        this.currentSettings = [];
    }

    updateCurrentSettings() {
        if (!this.colorSettings.value.darkMode) {
            this.colorSettings.value.darkMode = {};
        }
        if (!this.colorSettings.value.lightMode) {
            this.colorSettings.value.lightMode = {};
        }
        if (this.option === 'Issue Types') {
            this.currentSettings = this.allIssueTypes;
        } else if (this.option === 'Priority') {
            this.currentSettings = this.allPriorityTypes;
        } else {
            this.currentSettings = [];
        }
        this.ensureColorSettingsExist();
    }

    ensureColorSettingsExist() {
        this.currentSettings.forEach(option => {
            const optionKey = option.name.toLowerCase();
            this.colorSettings.value.darkMode[optionKey] = this.colorSettings.value.darkMode[optionKey]
                || { color: '#161a1d', font: '#c9c9ca' };
            this.colorSettings.value.lightMode[optionKey] = this.colorSettings.value.lightMode[optionKey]
                || { color: '#ffffff', font: '#172b4d' };
        });
    }

    handleColorChange(event, target, mode) {
        this.colorSettings.value[mode][target][event.target.name] = event.target.value;
        this.emitColorChange();
    }

    emitColorChange() {
        this.colorChange.emit(this.colorSettings);
    }

    revertToDefault() {
        this.setDefaultColorSettings();
        this.emitColorChange();
    }

    handleBasedOnSelect(event) {
        this.option = event.target.value;
        this.colorSettings.type = this.option;
        this.updateCurrentSettings();
        this.emitColorChange();
    }
}
