import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FooterLink} from '../custom-footer-link/custom-footer-link.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
    selector: 'app-custom-footer-link-list',
    templateUrl: './custom-footer-link-list.component.html',
    styleUrls: ['./custom-footer-link-list.component.css']
})
export class CustomFooterLinkListComponent {
    @Input() footerLinks: FooterLink[];
    @Output() linkRemoved = new EventEmitter<number>();

    onRemoveLink(index: number) {
        this.linkRemoved.emit(index);
    }

    onEditLink(link: FooterLink) {
        link.editing = !link.editing;
    }

    drop(event: CdkDragDrop<FooterLink[]>): void {
        moveItemInArray(
            event.container.data,
            event.previousIndex,
            event.currentIndex
        );
    }
}
