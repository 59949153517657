import {Component} from '@angular/core';

@Component({
    selector: 'app-priority-icon',
    template: '<svg role="presentation" width="24" height="24" viewBox="0 0 24 24" ' +
        'focusable="false"><path d="M7.959 15.833V8.474l-2.868 2.868a.541.541 0 1 ' +
        '1-.766-.766l3.79-3.789.001-.004a.544.544 0 0 1 .77.003l3.791 3.792a.543.543 ' +
        '0 0 1 0 .766.543.543 0 0 1-.765 0l-2.87-2.87v7.36a.543.543 0 0 1-.541.541' +
        '.543.543 0 0 1-.542-.542zm7-6.666v7.359l-2.868-2.868a.541.541 0 1 0-.766.' +
        '766l3.79 3.789.001.004a.544.544 0 0 0 .77-.003l3.791-3.792a.543.543 0 0 ' +
        '0 0-.766.543.543 0 0 0-.765 0l-2.87 2.87v-7.36a.543.543 0 0 0-.541-.541' +
        '.543.543 0 0 0-.542.542z" fill="currentColor" stroke="currentColor" ' +
        'stroke-width=".5" fill-rule="evenodd">' +
        '</path>' +
        '</svg>'
})
export class PriorityIconComponent {
}
