import {Recipient} from './recipient';

export class EmailContent {
    uuid: string;
    recipients: Recipient[];
    subject: string;
    content: string;
}

export class EmailSendResponse {
    status: SendStatus;
}

export enum SendStatus {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    ISSUE_EXPIRED = 'ISSUE_EXPIRED',
    PROJECT_DISABLED = 'PROJECT_DISABLED'
}
