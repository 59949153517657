import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FilterFieldConfig} from '../../base/config';
import {ViewItem} from '../../view-item';
import {ShareFormFilterField} from './share-form-filter-field';
import {DragAndDropService} from './drag-and-drop.service';

@Component({
    selector: 'app-share-form-filter-fields',
    templateUrl: './share-form-filter-fields.component.html',
    styleUrls: ['./share-form-filter-fields.component.css']
})

export class ShareFormFilterFieldsComponent implements OnInit {
    @Input() filterFieldsProjectPolicy: FilterFieldConfig;
    @Input() form: FormGroup;
    @Input() allFilterFields: ViewItem[];
    @Input() searchedCustomFieldsForFilter: ShareFormFilterField[];
    selectedFields: ShareFormFilterField[] = [];
    fieldsToSelect: ShareFormFilterField[];
    draggingIndex: number;
    dragging: boolean;
    searchInput = '';
    searchedFields: ShareFormFilterField[] = [];

    constructor(private dragAndDropService: DragAndDropService) {
    }

    ngOnInit(): void {
        const allFields = this.allFilterFields;
        const allFieldsIndexed: ShareFormFilterField[] = allFields.map((item, index) => {
            return {
                id: item.id,
                text: item.text,
                index
            };
        }).sort((a, b) => a.text.localeCompare(b.text));
        if (this.form.get('filterShareFields').value.length !== 0) {
            const savedFields = this.form.get('filterShareFields').value;
            const selectedFields = savedFields.map((id) => allFields.find((el) => el.id === id)).filter(item => item !== undefined)
                .map((it, index) => {
                    return {
                        id: it.id,
                        text: it.text,
                        index
                    };
                });
            const selectedIds = selectedFields.map(it => it.id);
            let fieldsToSelect = allFields.filter(item => !selectedIds.includes(item.id)).map((it, index) => {
                return {
                    id: it.id,
                    text: it.text,
                    index
                };
            }).sort((a, b) => a.text.localeCompare(b.text));

            fieldsToSelect = this.filterOutDisabledFields(fieldsToSelect).map((it, index) => {
                return {id: it.id, text: it.text, index};
            });

            this.selectedFields = selectedFields;
            this.searchedCustomFieldsForFilter = fieldsToSelect;
            this.fieldsToSelect = fieldsToSelect;
        } else {
            const filteredFields = this.filterOutDisabledFields(allFieldsIndexed).map((it, index) => {
                return {id: it.id, text: it.text, index};
            });
            this.searchedCustomFieldsForFilter = filteredFields;
            this.fieldsToSelect = filteredFields;
        }
    }

    addItem(item: ShareFormFilterField) {
        this.selectedFields.push(item);
        this.selectedFields = this.selectedFields.map((it, index) => {
            return {
                id: it.id,
                text: it.text,
                index,
            };
        });
        const fieldsToSelect = this.fieldsToSelect.filter(it => it.id !== item.id)
            .sort((a, b) => a.text.localeCompare(b.text));
        this.fieldsToSelect = fieldsToSelect;
        this.searchedCustomFieldsForFilter = fieldsToSelect;
        this.searchedFields = this.searchedCustomFieldsForFilter
            .filter(it => it.text.toLowerCase().includes(this.searchInput));

        this.setFilterFormFieldsValue(this.selectedFields);
    }

    removeItem(item: ShareFormFilterField) {
        this.selectedFields = this.selectedFields.filter(it => it.id !== item.id);
        this.selectedFields = this.selectedFields.map((it, index) => {
            return {
                id: it.id,
                text: it.text,
                index,
            };
        });
        this.fieldsToSelect.push(item);
        this.fieldsToSelect = this.filterOutDisabledFields(this.fieldsToSelect).map((it, index) => {
            return {id: it.id, text: it.text, index};
        });

        this.searchedFields = this.searchedCustomFieldsForFilter
            .filter(it => it.text.toLowerCase().includes(this.searchInput)).sort((a, b) => a.text.localeCompare(b.text));

        this.setFilterFormFieldsValue(this.selectedFields);
    }

    search(e: any) {
        this.searchInput = e.target.value.toLowerCase();
        this.searchedFields = this.searchedCustomFieldsForFilter
            .filter(item => item.text.toLowerCase().includes(e.target.value.toLowerCase()));
    }

    setFilterFormFieldsValue(value: ShareFormFilterField[]) {
        const sortedValue = value.sort((a, b) => a.index - b.index).map(it => it.id);
        this.form.get('filterShareFields').setValue(sortedValue);
    }

    onDragStart(index: number): void {
        this.draggingIndex = index;
        this.dragging = true;
        this.dragAndDropService.startDrag(index);
    }

    onDragEnter(index: number): void {
        this.draggingIndex = index;
        this.dragAndDropService.enterDrag(index, this.selectedFields);
        this.setFilterFormFieldsValue(this.selectedFields);
    }

    onDragEnd(): void {
        this.dragging = false;
        this.draggingIndex = undefined;
        this.dragAndDropService.endDrag();
    }

    filterOutDisabledFields(list: ViewItem[]) {
        const f = this.filterFieldsProjectPolicy;
        const projectFilterFields = {
            project: f.projectShow,
            issuetype: f.issueKeyShow,
            summary: f.summaryShow,
            description: f.descriptionShow,
            attachments: f.descriptionShow,
            assignee: f.assigneeShow,
            priority: f.priorityShow,
            status: f.statusShow,
            created: f.createdDateShow,
            updated: f.updatedDateShow,
            reporter: f.reporterShow,
            labels: f.labelsShow,
            duedate: f.dueDateShow,
            timespent: f.timeTrackingShow,
            progress: f.timeTrackingShow,
            timeoriginalestimate: f.originalEstimateShow,
            fixVersions: f.fixVersionsShow,
            versions: f.versionsShow,
            components: f.componentsShow
        };
        let fieldsList = list
            .filter(it => {
                if (projectFilterFields[it.id] !== undefined) {
                    return projectFilterFields[it.id];
                } else {
                    return true;
                }
        });

        if (!f.customFieldsShow && f.customFieldsDisplay.length === 1 && f.customFieldsDisplay[0] === '') {
            fieldsList = fieldsList.filter(it => !it.id.startsWith('customfield_'));
        } else if (f.customFieldsDisplay.length <= 1 && f.customFieldsDisplay[0] !== '') {
            const allCustomFieldsAvailable = f.customFieldsDisplay.map(it => 'customfield_' + it);
            fieldsList = fieldsList.filter(it => {
                if (it.id.startsWith('customfield_')) {
                    return allCustomFieldsAvailable.includes(it.id);
                } else {
                    return true;
                }
            });
        }

        return fieldsList;
    }
}
