import {Component, Input} from '@angular/core';
import {JiraService} from '../jira.service';

@Component({
    selector: 'app-link-to-project-settings',
    template: '<a [href]="href" (click)="onClick($event)">{{issueIdVal}}</a>'
})
export class LinkToProjectSettingsComponent {

    public issueIdVal: string;
    private baseUrl: string;

    public href = '';

    constructor(private jira: JiraService) {
        jira.getBaseUrl().subscribe((location) => {
            this.baseUrl = location;

            // refresh base url
            if (this.issueIdVal) {
                this.issueId = this.issueIdVal;
            }
        });
    }

    @Input()
    public set issueId(issueId: string) {
        this.issueIdVal = issueId;
        this.href = this.baseUrl + '/browse/' + issueId;
    }

    onClick(e) {
        if (this.issueIdVal) {
            AP.navigator.go('projectAdminTabPanel', {
                addonKey: this.issueIdVal,
                adminPageKey: '',
                projectKey: ''
            });
        }
    }

}
