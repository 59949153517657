import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    public fullscreen(show: boolean) {
        if (show) {
            const loaderEl = document.createElement('div');
            loaderEl.id = 'loader-service';
            loaderEl.classList.add('loader');
            document.body.append(loaderEl);
        } else {
            const loaderEl = document.getElementById('loader-service');
            if (loaderEl) {
                document.body.removeChild(loaderEl);
            }
        }
    }

}
