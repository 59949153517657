import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-column-checkbox',
    templateUrl: './column-checkbox.component.html',
    styleUrls: ['./customize-columns.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ColumnCheckboxComponent),
            multi: true
        }
    ]
})
export class ColumnCheckboxComponent {
    @Input() items: any;
    @Input() valueFn: any;
    @Input() textFn: any;

    isChecked: boolean;
    focused: boolean;

    private onChangeFn: any;
    private onTouchedFn: any;

    writeValue(value: any) {
        this.isChecked = value;
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    onChangeColumn($event) {
        const id = $event.target.value;
        const isChecked = $event.target.checked;
        this.items = this.items.map(item => {
            if (item.value === id) {
                item.selected = isChecked;
                this.valueFn(item);
                return item;
            }
            return item;
        });
        this.onChangeFn(this.items.map(item => item.selected ? item.value : null ));
    }
}
