import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';

@Component({
    selector: 'app-visibility-checkbox',
    templateUrl: './visibility-checkbox.component.html',
    styleUrls: ['./visibility-checkbox.component.css']
})
export class VisibilityCheckboxComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() items: (string | boolean)[][];

    constructor() {
    }

    ngOnInit(): void {
    }

}
