import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-file-upload',
    templateUrl: 'file-upload.component.html',
    styleUrls: ['./file-upload.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FileUploadComponent),
        }
    ]
})
export class FileUploadComponent implements ControlValueAccessor {
    @Input() form: FormGroup;
    selectedFile: File;
    hide: boolean;

    private onChangeFn: any;
    private onTouchedFn: any;

    constructor() {
    }

    onFileChanged(event) {
        this.selectedFile = event.target.files[0];
        this.onChangeFn(this.selectedFile);
    }

    registerOnChange(fn: any): void {
        this.onChangeFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedFn = fn;
    }

    writeValue(value: any): void {
        this.selectedFile = value;
    }
}
