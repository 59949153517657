import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShareBulkEditPopupComponent} from './share-bulk-edit-popup.component';
import {EmailFormModule} from '../email-form/email-form.module';
import {ShareFormModule} from '../share-form/share-form.module';
import {IconsModule} from '../icons/icons.module';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

@NgModule({
    declarations: [
        ShareBulkEditPopupComponent
    ],
    imports: [
        CommonModule,
        BaseModule,
        BrowserModule,
        FormsModule,
        IconsModule,
        ReactiveFormsModule,
        RouterModule,
        EmailFormModule,
        ShareFormModule,
    ]
})
export class ShareBulkEditModule {
}
