import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {BoardCardLayoutRow} from '../boardCard';

@Component({
    selector: 'app-row-editor',
    templateUrl: './row-editor.component.html',
    styleUrls: ['row-editor.component.css']
})
export class RowEditorComponent {
    @Input() row: BoardCardLayoutRow;
    @ViewChild('elementRef', {static: true}) elementRef?: ElementRef;

    selectRowCss(e) {
        const currentRow = this.row;
        currentRow.justifyContent = e.target.value;
    }

    selectLayout(count: number) {
        const cells = [];
        const currentRow = this.row;
        currentRow.cells.forEach(cell => {
            cells.push(cell);
        });
        if (cells.length < count) {
            const totalCount = count - cells.length;
            for (let i = 0; i < totalCount; i++) {
                cells.push({
                    type: 'cell',
                    justifyContent: 'flex-start',
                    value: {
                        value1: {id: '', text: ''},
                        value2: {id: '', text: ''},
                        value3: {id: '', text: ''}
                    },
                });
            }
        }
        if (cells.length > count) {
            const howManyToDelete = cells.length - count;
            for (let i = 0; i < howManyToDelete; i++) {
                cells.pop();
            }
        }
        currentRow.cells = cells;
    }
}
