import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AppService} from '../../app.service';
import {ApiKeyConfigView} from '../../base/apikey.config';
import {CustomData} from '../data';
import {AtlassianUser} from '../../atlassian-user';
import {JiraService} from '../../jira.service';

@Component({
    selector: 'app-api-key-history',
    templateUrl: './api-key-history.component.html',
    styleUrls: ['./api-key-history.component.css']
})
export class ApiKeyHistoryComponent implements OnInit {
    @Output() viewConfig = new EventEmitter<ApiKeyConfigView>();
    apiKeyConfigViews: ApiKeyConfigView[] = [];
    users: Map<string, AtlassianUser>;

    constructor(private jira: JiraService, private app: AppService) {
    }
    ngOnInit() {
        this.jira.getCustomData<CustomData>()
            .pipe(
                switchMap(data => {
                    if (data.id) {
                        return this.app.getApiKeyConfigHistory(data.id);
                    } else {
                        return of([]);
                    }
                })
            )
            .subscribe(apiKeyConfigViews => {
                this.apiKeyConfigViews = apiKeyConfigViews;
            });
    }

    close() {
        this.jira.closeDialog();
    }

    view(apiKey: ApiKeyConfigView) {
        this.viewConfig.emit(apiKey);
    }
}
