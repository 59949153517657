import {Component, Input} from '@angular/core';
import {JiraService} from '../jira.service';

@Component({
    selector: 'app-link-to-issue',
    template: '<a target="_parent" [href]="href" (click)="onClick($event)" [appTooltip]="tooltip">{{issueIdVal}}</a>'
})
export class LinkToIssueComponent {

    public tooltip = 'Loading...';
    public issueIdVal: string;
    private baseUrl: string;
    public href = '';

    constructor(private jira: JiraService) {
        jira.getBaseUrl().subscribe((location) => {
            this.baseUrl = location;

            // refresh base url
            if (this.issueIdVal) {
                this.issueId = this.issueIdVal;
            }
        });
    }

    @Input()
    public set issueId(issueId: string) {
        this.issueIdVal = issueId;
        this.href = this.baseUrl + '/browse/' + issueId;
        this.jira.getIssueCached(issueId).subscribe(
            it => this.tooltip = it.fields.summary,
            error => {
                this.tooltip = 'Failed to load issue summary';
                if (error.errorThrown) {
                    try {
                        this.tooltip = JSON.parse(error.errorThrown).errorMessages[0];
                    } catch (e) {
                    }
                }
            }
        );
    }

    onClick(e) {
        if (this.issueIdVal) {
            AP.navigator.go('issue', {issueKey: this.issueIdVal});
        }
    }
}
