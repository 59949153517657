import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from './app.service';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ShareFormAccessGuard implements CanActivate {

    constructor(private app: AppService,
                private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        if (next.queryParams.source === 'operations-top-level') {
            return this.app.hasIssueAccessView().pipe(
                map(it => {
                    if (it.allowed) {
                        return true;
                    }
                    return this.router.parseUrl('/no-access-issue-view?' +
                        'source=operations-top-level' +
                        '&xdm_e=' + next.queryParams.xdm_e +
                        '&reason=' + it.reason);
                })
            );
        }
        return true;
    }
}
