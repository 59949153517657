import {Component, Input} from '@angular/core';
import {JiraService} from '../jira.service';
import {Board} from '../issue';

@Component({
    selector: 'app-link-to-board',
    template: `
        <ng-container *ngIf="boardExists; else elseBlock">
            <a target="_parent" [href]="href" (click)="onClick($event)" [appTooltip]="tooltip">{{boardName}}</a>
        </ng-container>
        <ng-template #elseBlock>
            <app-icon class="no-hover app-icon-link"
                      [appTooltip]="tooltip"
                      appMinusIcon>
            </app-icon>
        </ng-template>
    `
})
export class LinkToBoardComponent {

    public tooltip = 'Loading...';
    public boardName: string;
    public href = '';
    public boardExists = false;
    constructor(private jira: JiraService) {
    }

    @Input()
    public set boardId(boardId: string) {
        this.jira.getBoardCached(boardId).subscribe(
            it => {
                this.initialize(it);
            },
            error => {
                this.tooltip = 'Failed to load board details';
                if (error.errorThrown) {
                    try {
                        this.tooltip = JSON.parse(error.errorThrown).errorMessages[0];
                    } catch (e) {
                    }
                }
            }
        );
    }

    initialize(board: Board) {
        if (board.location) {
            this.boardExists = true;
            let href = board.self.split('rest/')[0];
            href += 'jira/software/';
            if (board.type !== 'simple') {
                href += 'c/';
            }
            href += 'projects/' + board.location.projectKey + '/boards/' + board.id;
            this.href = href;
            this.boardName = board.name;
            this.tooltip = board.name;
        } else {
            this.tooltip = 'This board has been deleted';
        }
    }

    onClick(e) {
        AP.navigator.go('site', {
            absoluteUrl: this.href
        });
    }
}
