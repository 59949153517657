import {Component, Input} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import Utils from '../../../../utils/utils';
import {v4 as uuidv4} from 'uuid';

@Component({
    selector: 'app-custom-email',
    templateUrl: './custom-email.component.html',
    styleUrls: ['./custom-email.component.css']
})
export class CustomEmailComponent {
    defaultEmail = 'External Share for Jira <no-reply@external-share.com>';

    @Input() form: FormGroup;
    emailOptionNames = {
        integrated: 'integrated (default)',
        smtp: 'SMTP with STARTTLS',
        gmail: 'Gmail API',
        outlook: 'Outlook API'
    };

    shouldDisplayError(control: AbstractControl): boolean {
        return Utils.shouldDisplayError(control);
    }


    loginWithGoogle(): void {
        const jwt = window.getToken().token;
        const requestId = uuidv4();
        const authUrl = `/connect/oauth2-start?service=gmail.send&request=${requestId}&jwt=${jwt}&n=jes`;
        window.open(authUrl, '_blank', 'width=500,height=600');
    }

    loginWithMicrosoft(): void {
        const jwt = window.getToken().token;
        const requestId = uuidv4();
        const authUrl = `/connect/oauth2-start?service=outlook.send&request=${requestId}&jwt=${jwt}`;
        window.open(authUrl, '_blank', 'width=500,height=600');
    }
}
