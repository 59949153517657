export interface AtlassianUser {
    displayName: string;
    accountId: string;
    username?: string;
    avatarUrls?: string[];
}

export const EMPTY_USER = {
    displayName: undefined,
    accountId: undefined
};
