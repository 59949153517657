import {Injectable} from '@angular/core';
import {LoaderService} from '../../loader.service';
import {AppService, GlobalSettings, UpdateCustomDomainResult} from '../../app.service';
import {finalize, map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class GlobalSettingsService {
    constructor(
        private app: AppService,
        private loader: LoaderService) {
    }

    update(oldSetting: GlobalSettings,
           newSettingsO: Observable<GlobalSettings>,
           onError?: (errorStatus: UpdateCustomDomainResult) => void) {
        this.loader.fullscreen(true);
        const project = (newSettings) => {
            return this.app.updateSettings(newSettings)
                .pipe(finalize(() => {
                    this.loader.fullscreen(false);
                }))
                .subscribe(
                    result => {
                        if (!!newSettings.customDomain.status) {
                            if (onError) {
                                onError(newSettings.customDomain.status);
                            }
                        } else {
                            AP.flag.create({
                                title: `External Share`,
                                body: `Settings were updated successfully`,
                                type: 'success',
                                close: 'auto'
                            });
                        }
                    },
                    error => {
                        if (onError) {
                            onError(error.errorStatus || 'ERROR');
                        }
                    });
        };
        return newSettingsO.pipe(map(project));
    }
}
