import {ApiKeyFormComponent} from './api-key-form.component';
import {ApiKeyFormParentComponent} from './api-key-form-parent.component';
import {DataOptionsComponent} from './data-options/data-options.component';
import {IconsModule} from '../icons/icons.module';
import {RadioButtonComponent} from './radio-button/radio-button.component';
import {BaseModule} from '../base/base.module';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgModule} from '@angular/core';
import {ApiKeyUsageComponent} from './key-usage/api-key-usage.component';
import {ApiKeyHistoryComponent} from './api-key-history/api-key-history.component';
import {ApiKeyConfigViewComponent} from './api-key-config-view/api-key-config-view.component';

@NgModule({
    declarations: [
        ApiKeyFormComponent,
        ApiKeyFormParentComponent,
        DataOptionsComponent,
        RadioButtonComponent,
        ApiKeyUsageComponent,
        ApiKeyHistoryComponent,
        ApiKeyConfigViewComponent
    ],
    imports: [
        BaseModule,
        BrowserModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        IconsModule
    ],
    exports: [
        ApiKeyFormComponent,
        ApiKeyFormParentComponent,
        ApiKeyUsageComponent,
        ApiKeyHistoryComponent,
        ApiKeyConfigViewComponent
    ]
})
export class ApiKeyFormModule {
}
