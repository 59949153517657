import {Component, OnInit} from '@angular/core';
import {ApiKeyUsage} from '../../base/apikey.config';
import {switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {AppService} from '../../app.service';
import {CustomData} from '../data';
import {JiraService} from '../../jira.service';

@Component({
    selector: 'app-api-key-usage',
    templateUrl: './api-key-usage.component.html',
    styleUrls: ['./api-key-usage.component.css']
})
export class ApiKeyUsageComponent implements OnInit {
    apiKeyUsages: ApiKeyUsage[] = [];

    constructor(private jira: JiraService, private app: AppService) {
    }

    ngOnInit() {
        this.jira.getCustomData<CustomData>()
            .pipe(
                switchMap(data => {
                    if (data.id) {
                        return this.app.getApiKeyUsage(data.id);
                    } else {
                        return of([]);
                    }
                })
            )
            .subscribe(apiKeyUsages => {
                this.apiKeyUsages = apiKeyUsages;
            });
    }

    close() {
        this.jira.closeDialog();
    }
}
